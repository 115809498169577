// DataContext.js
import React, { createContext } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [shareddata, setSharedData] = React.useState(null);

  const value = {
    shareddata,
    setSharedData,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
