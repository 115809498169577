import Geoportal from "./components/Geoportal";
import Home from "./components/home/Home";
import Header from "./components/Header";
import UnavailablePage from "./components/UnavailablePage";
import Reporting from "./components/reporting/Reporting";
import Exercises from "./components/Exercise/Exercises";
import Feedback from "./components/Feedback";

import { DataProvider } from "./components/DataContext";

import { questions,  AlterQuestionsAnswers} from "./components/Exercise/QuestionsFile";
import {getFilteredQuestions, getRandomItem} from "./components/Exercise/Randomize";

import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export const TRACKING_ID = "G-4L6WN78NLS";

ReactGA.initialize(TRACKING_ID);

function App() {
  const location = useLocation();
  const isTranslucent = location.pathname === "/home";

  const questionsIds = [0,1,2]
  const [administeredQuestions, setAdministeredQuestions] = useState(null);
  const [catgeoryText, setCategoryText] = useState(null);

  useEffect(() => {

    var randomId = getRandomItem(questionsIds);

      async  function updateQuestions() {
      const alteredQ = await AlterQuestionsAnswers(questions);
     
      if (alteredQ) {
       const administeredQ = getFilteredQuestions(alteredQ, randomId);
       setAdministeredQuestions(administeredQ?.questions);
       setCategoryText(administeredQ?.category);
       console.log()
      }else{
       const administeredQ  = getFilteredQuestions(questions, randomId);
        setAdministeredQuestions(administeredQ?.questions);
        setCategoryText(administeredQ?.category);
      }
    }
   
    updateQuestions()

}, []);


  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname + window.location.search,
    });
  }, [location]);
  return (
    <DataProvider>
      <div className="App">
        <Header isTranslucent={isTranslucent} />
        <Routes>
          <Route path="/" element={<Geoportal />} />
          {/* <Route path="/geoportal" element={<Geoportal />} /> */}
          <Route path="/home" element={<Home />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/exercises" element={<Exercises 
          questions={administeredQuestions}
          catgeory={catgeoryText}/>} />
          <Route path="/feedback" element={<Feedback />} />
          

          <Route path="*" element={<UnavailablePage />} />
        </Routes>

        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-7H13WGDPR1', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </div>
    </DataProvider>
  );
}

export default App;
