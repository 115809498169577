import React, { useRef, useEffect, useState, useCallback } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl-basemaps/lib/basemaps.css";

export default function Map({
  image_url,
  active,
  bounds,
  admin_zero_image_url,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [API_KEY] = useState("VclURJDhIR5FyUXegLsy");

  useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
      center: [12.7322, 0.4542],
      zoom: 1.8,
      minZoom: 3, // Minimum zoom level allowed (0-24). Adjust this value based on your needs
      maxZoom: 22, 
    });

    map.current.addControl(new maplibregl.NavigationControl(), "top-right");
    // map.current.addControl(
    //   new maplibregl.AttributionControl({ compact: true }),
    //   "bottom-right"
    // );
  }, [API_KEY]);

  const add_image_geoserver = useCallback((url, name) => {
    if (map.current && map.current.getSource(name)) {
      map.current.removeLayer(name);
      map.current.removeSource(name);
    }

    map.current.addSource(name, {
      type: "raster",
      tiles: [url],
      crs: "EPSG:3857",
    });

    map.current.addLayer({
      id: name,
      type: "raster",
      source: name,
      crs: "EPSG:3857",
      tileSize: 256,
      params: {
        LAYERS: name,
        CRS: "EPSG:3857",
        TILED: true,
      },
    });
  }, []);

  useEffect(() => {
    if (bounds) {
      const [minLng, minLat, maxLng, maxLat] = [
        bounds["minx"],
        bounds["miny"],
        bounds["maxx"],
        bounds["maxy"],
      ];

      map.current.fitBounds([
        [minLng, minLat],
        [maxLng, maxLat],
      ]);
    }
  }, [bounds]);

  useEffect(() => {
    if (!map.current) return;

    const onStyleLoad = () => {
      if (active) {
        add_image_geoserver(image_url, "image_cdi");
        add_image_geoserver(admin_zero_image_url, "vector");
      }
    };

    if (map.current.isStyleLoaded()) {
      onStyleLoad();
    } else {
      map.current.on("style.load", onStyleLoad);
    }

    return () => {
      if (map.current) {
        map.current.off("style.load", onStyleLoad);
      }
    };
  }, [active, image_url, admin_zero_image_url]);

  return (
    <div className="map-wrap-main">
      <div ref={mapContainer} className="map-main" />
    </div>
  );
}
