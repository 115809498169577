import React, { useRef, useEffect, useState, useCallback } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl-basemaps/lib/basemaps.css";

export default function Map({ latest_cdi, active }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [API_KEY] = useState("VclURJDhIR5FyUXegLsy");

  useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
      center: [12.7322, 0.4542],
      zoom: 1.8,
    });

    map.current.addControl(new maplibregl.NavigationControl(), "top-right");
    // map.current.addControl(
    //   new maplibregl.AttributionControl({ compact: true }),
    //   "bottom-right"
    // );
  }, [API_KEY]);

  const regional_admin_url =
    "https://ada.acmad.org/geoserver/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=africa_drought_datasets:data_api_adminlevelzeroregion";

  const add_image_geoserver = useCallback((url, name) => {
    if (map.current && map.current.getSource(name)) {
      map.current.removeLayer(name);
      map.current.removeSource(name);
    }

    map.current.addSource(name, {
      type: "raster",
      tiles: [url],
      crs: "EPSG:3857",
    });

    map.current.addLayer({
      id: name,
      type: "raster",
      source: name,
      crs: "EPSG:3857",
      tileSize: 256,
      params: {
        LAYERS: name,
        CRS: "EPSG:3857",
        TILED: true,
      },
    });
  }, []);

  useEffect(() => {
    if (!map.current) return;

    const onStyleLoad = () => {
      if (active) {
        add_image_geoserver(latest_cdi, "latest_cdi");
        add_image_geoserver(regional_admin_url, "regional_data");
      }
    };

    if (map.current.isStyleLoaded()) {
      onStyleLoad();
    } else {
      map.current.on("style.load", onStyleLoad);
    }

    return () => {
      if (map.current) {
        map.current.off("style.load", onStyleLoad);
      }
    };
  }, [active, latest_cdi, add_image_geoserver]);

  return (
    <div className="map-wrap-home">
      <div ref={mapContainer} className="map-main" />
    </div>
  );
}
