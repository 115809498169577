import "../styles/SidepanelStyle.css";
// import styled from "styled-components";
import { filter_wms_by_boundary } from "./SidePanel";

import Select from "react-select";
import axios from "axios";

import { useState, useEffect } from "react";
import { color } from "echarts";

const CountrySelection = ({
  countries_options,
  setCountries_options, //
  selected_country,
  setSelected_country,
  setCurrent_vector,
  setCurrent_spi,
  current_spi,
  get_filtered_wms_spi,
  get_filtered_wms,
  current_fapar_anomaly,
  base_url,
  current_fapar_absorbed,
  selected_region,
  setSelected_region,
  current_monthly_precipitation_gpcc,
  setCurrent_monthly_precipitation_gpcc,
  setCurrent_fapar_anomaly,
  setCurrent_fapar_absorbed,
  current_sma,
  setCurrent_sma,
  current_cdi,
  setCurrent_cdi,
  active_admin_boundaries, 
  setActive_admin_boundaries
}) => {
  const [region_options, setRegion_options] = useState([]);

  const [country_options, setCountry_options] = useState([]); // country options

  const [disable_region_selection, setDisable_region_selection] =
    useState(true);
  const [disable_country_selection, setDisable_country_selection] =
    useState(true);

  var maplibre_str =
    "bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=";

  const get_data = async (url) => {
    const res = await axios.get(url);

    const data = res.data;

    // console.log(data, 'success')
    return data;
  };

  function handle_wms_region(region_code) {
    // cdi
    if (current_cdi !== null) {
      var cdi_wms_link = filter_wms_by_boundary(
        current_cdi["cdi_geoserver"],
        region_code,
        "cell",
        0
      );
      setCurrent_cdi({ cdi_geoserver: cdi_wms_link });
    }
    // precipitation gpcc
    if (current_monthly_precipitation_gpcc !== null) {
      var gpcc_wms_link = filter_wms_by_boundary(
        current_monthly_precipitation_gpcc[
          "monthly_precipitation_gpcc_geoserver"
        ],
        region_code,
        "corr_cell",
        0
      );
      setCurrent_monthly_precipitation_gpcc({
        monthly_precipitation_gpcc_geoserver: gpcc_wms_link,
      });
    }
    // spi
    if (current_spi !== null) {
      var spi_wms_link = filter_wms_by_boundary(
        current_spi,
        region_code,
        "corr_cell",
        0
      );
      setCurrent_spi(spi_wms_link);
    }
    // fapar anomaly
    if (current_fapar_anomaly !== null) {
      var fapar_anomaly_wms_link = filter_wms_by_boundary(
        current_fapar_anomaly["fapar_anomaly_geoserver"],
        region_code,
        "cell",
        0
      );
      setCurrent_fapar_anomaly({
        fapar_anomaly_geoserver: fapar_anomaly_wms_link,
      });
    }
    // fapar absorbed
    if (current_fapar_absorbed !== null) {
      var fapar_absorbed_wms_link = filter_wms_by_boundary(
        current_fapar_absorbed["fapar_absorbed_geoserver"],
        region_code,
        "cell",
        0
      );
      setCurrent_fapar_absorbed({
        fapar_absorbed_geoserver: fapar_absorbed_wms_link,
      });
    }
    // sma
    if (current_sma !== null) {
      var sma_wms_link = filter_wms_by_boundary(
        current_sma["sma_geoserver"],
        region_code,
        "cell",
        0
      );
      setCurrent_sma({
        sma_geoserver: sma_wms_link,
      });
    }
  }

  function handle_wms_country(country_code) {
    if (current_cdi !== null) {
      var cdi_wms_link = filter_wms_by_boundary(
        current_cdi["cdi_geoserver"],
        country_code,
        "cell",
        1
      );
      setCurrent_cdi({ cdi_geoserver: cdi_wms_link });
    }

    // precipitation gpcc
    if (current_monthly_precipitation_gpcc !== null) {
      var gpcc_wms_link = filter_wms_by_boundary(
        current_monthly_precipitation_gpcc[
          "monthly_precipitation_gpcc_geoserver"
        ],
        country_code,
        "corr_cell",
        1
      );
      setCurrent_monthly_precipitation_gpcc({
        monthly_precipitation_gpcc_geoserver: gpcc_wms_link,
      });
    }
    // sma
    if (current_sma !== null) {
      var sma_wms_link = filter_wms_by_boundary(
        current_sma["sma_geoserver"],
        country_code,
        "cell",
        1
      );
      setCurrent_sma({
        sma_geoserver: sma_wms_link,
      });
    }
  }

  const onchange_region = (e) => {
    setDisable_country_selection(true);
    var region_code = countries_options["regions_info"][e.value]["region_code"];
    setSelected_region(region_code);

    handle_wms_region(region_code);
    // console.log(region_code);
    setCountry_options(
      countries_options["regions_info"][e.value]["country_code"]
    );

    var region_data = {
      region_code: countries_options["regions_info"][e.value]["region_code"],
      bounds: countries_options["regions_info"][e.value]["region_bounds"],
    };

    var current_vector_data = {
      url:
        countries_options["geoserver"]["wms"] +
        maplibre_str +
        countries_options["geoserver"]["layername"] +
        "&transparent=true&CQL_FILTER=region_code=" +
        region_data["region_code"],
      name: e.value,
      bounds: region_data["bounds"],
    };
    setCurrent_vector(current_vector_data);

    setDisable_country_selection(false);
    setSelected_country(null);
    // if (
    //   current_spi !== null &&
    //   selected_country !== null &&
    //   Object.keys(selected_spi_dates).length !== 0
    // ) {
    //   var spi_link = current_spi.split("CQL_FILTER")[0];
    //   var continet_spi = `${spi_link}CQL_FILTER=year=${selected_spi_dates["year"]}`;

    //   setCurrent_spi(continet_spi);
    // }
  };

  const onchange_country = (e) => {
    setSelected_country({ country_id: e.value["country_code"], name: e.label });
    var current_vector_data = {
      url:
        countries_options["geoserver"]["wms"] +
        maplibre_str +
        countries_options["geoserver"]["layername"] +
        "&transparent=true&CQL_FILTER=country_code=" +
        e.value["country_code"],
      name: e.label,
      bounds: e.value["country_bounds"],
    };
    var country_code = e.value["country_code"];
    if (current_spi !== null) {
      get_filtered_wms_spi(current_spi, country_code);
    }
    if (current_fapar_anomaly !== null) {
      get_filtered_wms(
        current_fapar_anomaly["fapar_anomaly_geoserver"],
        country_code
      );
    }
    if (current_fapar_absorbed !== null) {
      get_filtered_wms(
        current_fapar_absorbed["fapar_absorbed_geoserver"],
        country_code,
        "fapar_absorbed"
      );
    }
    handle_wms_country(e.value["country_code"]);

    setCurrent_vector(current_vector_data);
  };

  useEffect(() => {
    const get_countries_list_data = async () => {
      const countries_list_data = await get_data(
        `${base_url}/data_api/geojson/getgeojsoninfo?country_names=ALL`
      );
      setCountries_options(countries_list_data);
      setRegion_options(Object.keys(countries_list_data["regions_info"]));
      var africa_bounds = {
        maxx: 64,
        maxy: 38,
        minx: -26,
        miny: -45,
      };
      var current_vector_data = {
        url:
          countries_list_data["geoserver"]["wms"] +
          maplibre_str +
          countries_list_data["geoserver"]["layername"] +
          "&transparent=true",
        name: "africa",
        bounds: africa_bounds,
      };
      setCurrent_vector(current_vector_data);
      setDisable_region_selection(false);
    };

    get_countries_list_data();
  }, []);

  const customoptions = (anArray) => {
    var opt = anArray.map((item) => {
      return { value: item, label: item };
    });
    return opt;
  };

  const customoptions_countries = (anArray) => {
    var opt = anArray.map((item) => {
      return {
        value: {
          country_code: item["country_code"],
          country_bounds: item["country_bounds"],
          country_name: item["country_name"],
        },
        label: item["country_name"],
      }; //
    });
    return opt;
  };

  const oncheck_admin = () => {
    setActive_admin_boundaries(!active_admin_boundaries);
    console.log(active_admin_boundaries)
  };

  return (
    <div>
      <div className="Names">
        <p>REGION</p>
        <p>COUNTRY</p>
      </div>

     

      <Select
        className="select-region"
        classNamePrefix="select-controls"
        options={customoptions(region_options)}
        placeholder="Select Region"
        // value="Select Region"
        onChange={onchange_region}
        // onSelectResetsInput="false"
        isSearchable={true}
        isDisabled={disable_region_selection}
      ></Select>

      <Select
        className="select-country"
        classNamePrefix="select-controls"
        options={customoptions_countries(country_options)}
        placeholder={
          selected_country != null ? selected_country["name"] : "Select Country"
        }
        value={
          selected_country != null ? selected_country["name"] : "Select Country"
        }
        onChange={onchange_country}
        //   onSelectResetsInput ='false'
        isSearchable={true}
        isDisabled={disable_country_selection}
      ></Select>

      <div className="column admin-boundary-switch">
      <p className="admin-text is-size-7">Admin boundary</p>
      <label className="switch_admin">
       
            <input
              type="checkbox"
              defaultChecked={active_admin_boundaries}
              onChange={oncheck_admin}
              disabled={false}
            />
            <span className="slider round"></span>
          </label>
      </div>

          
    </div>
  );
};

export default CountrySelection;
