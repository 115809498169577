

const Feedback = () => {
  return (
    <div className="container" style={{top:'89px'}} >
       
        <section className="columns is-mobile is-centered">
        <div className="column is-half">
            <p className='column is-size-5'> Help us improve the program by participating in this survey click below!</p>
            <a className="button is-warning column" href='https://ee.kobotoolbox.org/x/FbORA6Us' target="_blank" rel="noopener noreferrer">
            Kigali ADMA Feedback
                </a>
        </div>
              </section>
    </div>
  )
}

export default Feedback
