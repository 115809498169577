import { useState } from "react";
import "./custom.css";
import { BarChart, XAxis, YAxis, Legend, Bar, Tooltip } from "recharts";

const StackedBarChart = ({ allData }) => {
  const [viewMore, setViewMore] = useState(false);
  const datafilter = allData?.overview;

  const dataToShow = viewMore ? datafilter : datafilter.slice(0, 4);

  function get_data_to_show() {
    if (allData !== undefined) {
      if (viewMore) {
        return allData.slice(0.4);
      }
    } else return allData;
  }
  const barHeight = 20; // Adjust this value
  const chartHeight = dataToShow.length * (barHeight + 10) + 50;

  return (
    <div>
      <BarChart
        width={600}
        height={chartHeight}
        data={dataToShow}
        layout="vertical"
        margin={{ top: 20, right: 3, left: 20, bottom: 2 }}
        barCategoryGap="1px"
      >
        {/* <CartesianGrid strokeDasharray="2 2" /> */}
        <XAxis type="number" tick={false} hide={true} />
        <YAxis
          type="category"
          width={150}
          // tickLine={false}
          // tick={false}
          dataKey="admin_one_name"
          // axisLine={false}
          // hide={true}
        />
        <Legend />
        <Tooltip />
        <Bar dataKey="Warning" stackId="a" fill="#FFA600" barSize={barHeight} />
        <Bar dataKey="Watch" stackId="a" fill="#955825" barSize={barHeight} />
        <Bar dataKey="Alert" stackId="a" fill="red" barSize={barHeight} />
      </BarChart>
      <button
        className="button is-info is-extra-small"
        onClick={() => setViewMore(!viewMore)}
      >
        {viewMore ? "View Less" : "View More"}
      </button>
    </div>
  );
};

export default StackedBarChart;
