

import ReactECharts from 'echarts-for-react';

const SmaChart = ({ data }) => {

  data = data.sort((a, b) => new Date(a.date) - new Date(b.date))

  var barWidth = 10;
  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['Extremely dry', 'Severely dry', 'Moderately dry', 'Near Normal Conditions', 'Moderately wet', 'Very wet', 'Extremely wet'],
        align: 'left',
        bottom: 0 // Position the legend at the bottom of the chart
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%', // Adjust the bottom grid spacing to make room for the legend
        containLabel: true,
        show: false
      },
      xAxis: {
        type: 'category',
        data: data.map(item => item.date),
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          show: true
        }
      },
      yAxis: {
        type: 'value',
        name: '% Whole Region',
        nameLocation: 'middle',
        nameGap: 15,
        nameTextStyle: {
          padding: [0, 0, 20, 0]
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: true
        },
        splitLine: {
          show: true
        }
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            pixelRatio: 2 // This can increase the resolution of the downloaded image
          }
        },
        right: 20 // You can adjust the positioning of the toolbox
      },
      series: [
        {
          name: 'Extremely dry',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth, // Adjust the bar width as needed
          data: data.map(item => item['Extremely dry']),
          itemStyle: {
            color: '#B35807'
          }
        },
        {
          name: 'Severely dry',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Severely dry']),
          itemStyle: {
            color: '#F1A440'
          }

          
        },
       
        {
          name: 'Moderately dry',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Moderately dry']),
          itemStyle: {
            color: '#FDE0B6'
          },
        },

        {
          name: 'Near Normal Conditions',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Near Normal Conditions']),
          itemStyle: {
            color: '#CCCCCC'
          },
        },

        {
          name: 'Moderately wet',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Moderately wet']),
          itemStyle: {
            color: '#D1E4F0'
          },
        },
        {
          name: 'Very wet',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Very wet']),
          itemStyle: {
            color: '#67A8CF'
          },
        },

        {
          name: 'Extremely wet',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Extremely wet']),
          itemStyle: {
            color: '#2066AC'
          },
        },
      ],
      barCategoryGap: '0%' ,// Adjust the category gap as needed (default is 20%)
      barGap: '-100%',
    };
  };

  return <ReactECharts option={getOption()} style={{ height: '300px', width: '100%' }} />;
};


export default SmaChart;
