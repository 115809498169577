// import React from 'react';
// import 'bulma/css/bulma.min.css';

// const NDVILegend = () => {
//   const palette = [
//     'ffffff', 'ce7e45', 'df923d', 'f1b555', 'fcd163', '99b718', '74a901',
//     '66a000', '529400', '3e8601', '207401', '056201', '004c00', '023b01',
//     '012e01', '011d01', '011301'
//   ];

//   return (
//     <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//       <p className="is-size-7 has-text-weight-semibold">Normalized Difference Vegetation Index</p>
//       <div style={{ display: 'flex', width: '100%', height: '20px' }}>
//         {palette.map(color => (
//           <div key={color} style={{ flex: 1, backgroundColor: `#${color}` }} />
//         ))}
//       </div>
//       <div className="is-size-7" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '5px' }}>
//         <span>No Vegetation</span>
//         <span>Moderate Vegetation</span>
//         <span>High Vegetation</span>
//       </div>
//     </div>
//   );
// };

// export default NDVILegend;

import React from 'react';
import 'bulma/css/bulma.min.css';

const NDVILegend = () => {
  // Updated palette
  const palette = ['#000000', '#a50026', '#ffffbf', '#dcf09e', '#8acc62', '#1a9641'];

  return (
    <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <p className="is-size-7 has-text-weight-small">Normalized Difference Vegetation Index</p>
      <div style={{ display: 'flex', width: '100%', height: '20px' }}>
        {palette.map(color => (
          <div key={color} style={{ flex: 1, backgroundColor: color }} />
        ))}
      </div>
      <div className="is-size-7" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '5px' , gap:'4px'}}>
        <span>Low Vegetation</span>
        <span>Moderate Vegetation</span>
        <span>High Vegetation</span>
        {/* <span>Excellent Health</span> */}
      </div>
    </div>
  );
};

export default NDVILegend;

