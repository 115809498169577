// import "bulma/css/bulma.css";

const DataTable = ({ data }) => (
  <table className="table is-striped is-fullwidth">
    <thead>
      <tr>
        <th>Category</th>
        <th>Area Km2</th>
        <th>Percentage Area</th>
      </tr>
    </thead>
    <tbody>
      {data.map((row, index) => (
        <tr key={index}>
          <td>{row.category}</td>
          <td>{row.area}</td>
          <td>{row.percentage}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default DataTable;
