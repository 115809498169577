import PropTypes from 'prop-types'
import Line from '../../images/Line.svg';
const Border = ({color, margin_top}) => {
  return (
    <div
    style={{marginTop:margin_top, color:color}}
    className='border_bottom'
    > 
    <img  src={Line} alt="line"/>
    </div>
  )
}

Border.defaultProps = {
    color: 'black'
}

Border.propTypes = {
    color: PropTypes.string,
    margin_top: PropTypes.string,
}

export default Border