
import ReactECharts from 'echarts-for-react';


const CountiesChart = ({data}) => {


    const getOption = () => {
        const overview = data.cdi.overview;
      
        // Keep the order of countries as is, so the chart starts with the first country
        const countries = overview.map(item => item.country_name);
        const watchData = overview.map(item => item.Watch);
        const warningData = overview.map(item => item.Warning);
        const alertData = overview.map(item => item.Alert);
      
        // Determine if dataZoom is needed
        const isDataZoomNeeded = countries.length > 100;
        const dataZoomEnd = isDataZoomNeeded ? (22 / countries.length) * 100 : 100;
      
        const dataZoomOption = isDataZoomNeeded ? [
          {
            type: 'slider',
            yAxisIndex: 0,
            start: 0, // Start from the top
            end: dataZoomEnd, // Show enough to cover up to 22 data points initially
            handleSize: '10%',
            handlePosition: 'top',
            height: '100%', // Fixed height for the data zoom slider
            top: 0 // Place it at the very top of the chart container
          }
        ] : [];
      
        return {
          grid: {
            left: '5%',
            right: '10%',
            bottom: '3%',
            top: isDataZoomNeeded ? '70px' : '15%', // Adjust top grid spacing if dataZoom is present
            containLabel: true
          },
          xAxis: {
            type: 'value',
            splitLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            axisLine: { show: false }
          },
          yAxis: {
            type: 'category',
            data: countries,
            inverse: true, // This ensures that the yAxis starts with the first country at the top
            axisTick: { show: false },
            axisLine: { show: false },
            axisLabel: {
              show: true,
              margin: 8
            }
          },
          series: [
            {
              name: 'Watch',
              type: 'bar',
              stack: 'total',
              data: watchData,
              itemStyle: {
                color: '#fff645' // Color for the Watch category
              }
            },
            {
              name: 'Warning',
              type: 'bar',
              stack: 'total',
              data: warningData,
              itemStyle: {
                color: '#FFA600' // Color for the Warning category
              }
            },
            {
              name: 'Alert',
              type: 'bar',
              stack: 'total',
              data: alertData,
              itemStyle: {
                color: 'red' // Color for the Alert category
              }
            }
          ],
          dataZoom: dataZoomOption,
          animationEasing: 'elasticOut',
          animationDelayUpdate: (idx) => idx * 8
        };
      };
      
    
      return <ReactECharts option={getOption()} />;
    };


export default CountiesChart;