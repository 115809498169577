const Mapbox_Key = "pk.eyJ1Ijoia29yeWtvcmlyMTIzIiwiYSI6ImNsMGdqcjdybTEzcTczanBybHU5anN6bnUifQ.rIUBT4fmSIwMuwN_vtUznw";

export const basemaps = [
    {
      id: "satelite",
      tiles: [
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${Mapbox_Key}`,
      ],
      sourceExtraParams: {
        tileSize: 256,
        attribution:
          "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
        minzoom: 0,
        maxzoom: 22,
      },
    },
    {
      id: "mapbox_light",
      tiles: [
        `https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/{z}/{x}/{y}?access_token=${Mapbox_Key}`, //satellite-streets-v12
      ],
      sourceExtraParams: {
        tileSize: 256,
        attribution:
          "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
        minzoom: 0,
        maxzoom: 22,
      },
    },
    {
      id: "mapbox_streets",
      tiles: [
        `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${Mapbox_Key}`, //satellite-streets-v12
      ],
      sourceExtraParams: {
        tileSize: 256,
        attribution:
          "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>",
        minzoom: 0,
        maxzoom: 22,
      },
    },
  ]

  

  export const prepareGpccChartData  = (data) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let seriesData = [];
  
    Object.entries(data).forEach(([year, monthsData]) => {
      Object.entries(monthsData).forEach(([key, value]) => {
        if (key.startsWith('mon_rain_') || key.startsWith('guess_rain_')) {
          const monthIndex = parseInt(key.split('_')[2], 10) - 1;
          const monthName = monthNames[monthIndex];
          seriesData.push({
            name: `${monthName} ${year}`,
            value: value,
            year: parseInt(year, 10),
            month: monthIndex + 1 // Keeping the month as a number for sorting
          });
        }
      });
    });
  
    // Sorting data by year, then by month
    seriesData.sort((a, b) => {
      if (a.year === b.year) {
        return a.month - b.month; // Sort by month if the year is the same
      }
      return a.year - b.year; // Otherwise, sort by year
    });
  
    // Converting the numeric month back to its string representation for display
    seriesData.forEach(item => {
      item.month = monthNames[item.month - 1];
      item.name = `${item.month} ${item.year}`; // Update the name to reflect the sorted order
    });
  
    return seriesData;
  };
  
  export const restructureDataForMonthNames = (spiData) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const timeSeriesData = {};
  
    // Initialize structures for each SPI
    ['01', '03', '09', '12'].forEach(period => {
      timeSeriesData[`spi_${period}`] = {};
    });
  
    Object.entries(spiData).forEach(([year, values]) => {
      Object.entries(values).forEach(([key, value]) => {
        const [, month, period] = key.match(/spi_(\d{2})(\d{2})/);
        const monthName = monthNames[parseInt(month, 10) - 1]; // Convert month number to name
        
        if (!timeSeriesData[`spi_${period}`][year]) {
          timeSeriesData[`spi_${period}`][year] = {};
        }
  
        timeSeriesData[`spi_${period}`][year][monthName] = value;
      });
    });
  
    return timeSeriesData;
  };

 export  const transformDataForPlottingWithMonths = (structuredData) => {
    const plotData = Object.entries(structuredData).map(([spi, years]) => {
      const seriesData = [];
  
      Object.entries(years).forEach(([year, months]) => {
        Object.entries(months).forEach(([monthName, value]) => {
          const date = `${monthName} ${year}`;
          seriesData.push({ name: date, value: [date, value] });
        });
      });
  
      // Assuming chronological order based on the data input; otherwise, further sorting might be needed
  
      return {
        name: spi,
        type: 'line',
        data: seriesData,
      };
    });
  
    return plotData;
  };
  