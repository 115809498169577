import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import acmadLogo from '../images/acmadLogo.svg';
import jrc_logo from '../images/jrc_logo.svg';
import norcap_logo from '../images/norcap_logo.svg';
import './styles/Header.css';

// Handle redirection for external links
export const handleHeaderClick = (url) => {
  window.location.href = url; // Redirect within the same tab
};

const Header = ({ isTranslucent }) => {
  const [isActive, setIsActive] = useState(false);
  const headerClasses = `navbar is-fixed-top ${isTranslucent ? 'translucent-header' : 'nav'}`;
  const base_url_ = 'http://ada.acmad.org:8100/main/';

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  const renderLink = (to, name, isExternal = false) => {
    if (isExternal) {
      return (
        <a href={to} target="_blank" rel="noopener noreferrer" className="navbar-item navLink">
          {name}
        </a>
      );
    } else {
      return (
        <NavLink
          to={to}
          className={({ isActive }) => (isActive ? 'navbar-item activeLink' : 'navbar-item navLink')}
        >
          {name}
        </NavLink>
      );
    }
  };

  return (
    <nav className={headerClasses} role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="advisory">
          <p className="advisory-text">
            Africa Drought <br /> Monitoring and Advisory
          </p>
        </div>
        <a role="button" className={`navbar-burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" onClick={toggleNavbar}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className="navbar-start custom-navbar-start">
          {renderLink("/home", "HOME")}
          {renderLink("/", "GEOPORTAL")}
          {renderLink(`${base_url_}indicators`, "INDICATORS", true)}
          {renderLink(`${base_url_}about`, "ABOUT", true)}
          {renderLink(`${base_url_}partners`, "PARTNERS", true)}
          {renderLink(`${base_url_}get-in-touch`, "CONTACT", true)}
        </div>

        <div className="navbar-end">
          <div className="logo">
            <img src={jrc_logo} alt="JRC Logo" />
            <img src={norcap_logo} alt="NORCAP Logo" />
            <img src={acmadLogo} alt="ACMAD Logo" />
            
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
