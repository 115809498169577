import React, { useEffect, useState } from "react";
import "./styles/Legend_style.css";
import NDVILegend from "./NDVILegend";

const Legend = ({
  spi_legend_url,
  precipitation_check,
  fapar_anomaly_check,
  fapar_anormaly_legend_url,
  fapar_absorbed_check,
  fapar_absorbed_legend_url,
  monthly_precipitation_check,
  gpcc_monthly_precipitation_legend_url,
  sma_check,
  legend_url_sma,
  cdi_check,
  ndvi_check,
  koppen_climate_clas_check,
  koppen_climate_url,
}) => {
  const fapar_url =
    "https://ada.acmad.org/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=africa_drought_datasets:grid_083dd_fapar_anomaly_0101_view&LEGEND_OPTIONS=border:true;dx:10;fontSize";

  const cdi_url =
    "https://ada.acmad.org/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=africa_drought_datasets:grid_01dd_cdi_0501_view&LEGEND_OPTIONS=border:true;dx:10;fontSize";

  const [legendItems, setLegendItems] = useState([]);

  useEffect(() => {
    const updatedLegendItems = [];

    if (cdi_url !== null) {
      updatedLegendItems.unshift({
        url: cdi_url,
        name: "Combined Drought Index (CDI)",
        check: cdi_check,
      });
    }

    if (spi_legend_url !== null) {
      updatedLegendItems.unshift({
        url: spi_legend_url,
        name: "Standard Precipitation Index (SPI)",
        check: precipitation_check,
      });
    }

    if (fapar_anormaly_legend_url !== null) {
      updatedLegendItems.unshift({
        url: fapar_url,
        name: "FAPAR Anomaly",
        check: fapar_anomaly_check,
      });
    }

    if (fapar_absorbed_legend_url !== null) {
      updatedLegendItems.unshift({
        url: fapar_absorbed_legend_url,
        name: "FAPAR Absorbed",
        check: fapar_absorbed_check,
      });
    }

    if (gpcc_monthly_precipitation_legend_url !== null) {
      updatedLegendItems.unshift({
        url: gpcc_monthly_precipitation_legend_url,
        name: "Monthly Precipitation GPCC",
        check: monthly_precipitation_check,
      });
    }

    if (legend_url_sma !== null) {
      updatedLegendItems.unshift({
        url: legend_url_sma,
        name: "Soil Moisture Anomaly",
        check: sma_check,
      });

    
    }
    if (koppen_climate_url !== null) {
      updatedLegendItems.unshift({
        url: koppen_climate_url?.leged_url,
        name: "Koppen Climate Classification",
        check: koppen_climate_clas_check,
      });
    }

    setLegendItems(updatedLegendItems);
  }, [
    spi_legend_url,
    fapar_anormaly_legend_url,
    fapar_absorbed_legend_url,
    gpcc_monthly_precipitation_legend_url,
    legend_url_sma,
    precipitation_check,
    fapar_anomaly_check,
    fapar_absorbed_check,
    monthly_precipitation_check,
    sma_check,
    cdi_check,
    koppen_climate_clas_check,
    koppen_climate_url,
  ]);

  return (
    <>
      {legendItems.map((legendItem) => {
        const { url, name, check } = legendItem;
        if (check) {
          return (
            <div key={name}>
              <h4 style={{ marginBottom: "2px" }}>{name}</h4>
              <img src={url} alt="legend" />
            </div>
          );
        }
        return null;
      })}
      {ndvi_check && <NDVILegend />}
    </>
  );
};

export default Legend;
