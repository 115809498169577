
import { useRef,useState } from 'react';
import './styleExercise.css';

 // Import functions from './Randomize.js'

import {TRACKING_ID} from '../../App';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { base_url } from '../Geoportal';
const url = `${base_url}/data_api/exercises_responses/post_responses/`;

const Exercises = ({questions , catgeory}) => {
  ReactGA.initialize(TRACKING_ID);
    // Import images

    const [answers, setAnswers] = useState({});


    const [inputError, setInputError] = useState(false);
  

  
    
   

    function Question({ question, onAnswer }) {
      const textInputRef = useRef(null); // Create a ref for the text input
      const handleMultipleChoiceAnswer = (isCorrect, answerText) => {
        onAnswer(isCorrect, answerText);
    };
    const handleTextInputSubmit = (e) => {
      e.preventDefault(); // Prevent the default form submission behavior

      const inputValue = textInputRef.current.value.trim(); // Get the current value of the input from the ref
      if (!inputValue) {
          setInputError(true); // Show error if input is empty
      } else {
          onAnswer(true, inputValue); // Use the input value for whatever needs to be done
          textInputRef.current.value = ''; // Optionally clear the input after submission
          setInputError(false); // Reset any error state
      }
  };

      return (
        <div className="box">
         
          <div className="content">
            {catgeory && (
                  <p className='is-size-7'>
                      General Questions and {catgeory}
  
                  </p>
              
            )}
              {/* {question.questionCategory && (
                  <p className='title is-6'>
                      {question.questionCategory.text}
  
                  </p>
              )
  
              } */}
              {question.questionCategoryText && (
                  <p className='is-size-7'>
                      {question.questionCategoryText}
                  </p>
  
              )}
              {/* {question.id ===10 (
                 <p className="title is-5">{question.questionText}</p>
              ) } */}
            {[9,10,11,13,14,16,17].includes(question.id) ? (
              <p className="title is-5">{question.questionText} {answers['2']['answerText']} </p>

            ): <p className="title is-5"> {question.questionText}</p>}
            
            {question.questionInstruction &&
             <p className='is-size-7'>{question.questionInstruction}</p>}
  
          {question.questionInstruction  && question.navigationLink && (
              
              <a className="button is-warning" href={question.navigationLink} target="_blank" rel="noopener noreferrer">
                  {question.navigationLinkText}
              </a>
          )
  
          }
          <br />
          <br />
          
          {question.questionImage && (
            <figure className="image">
              <img src={question.questionImage} className="responsive-image" alt="Question screenshot" />
            </figure>
          )}
            
            {question.multipileChoice && (<div className="buttons are-medium">
              {question.answerOptions.map((option, index) => (
                <button key={index} className="button is-primary" onClick={() => handleMultipleChoiceAnswer(option.isCorrect, option.answerText)}>
                  {option.answerText}
                </button>
              ))}
            </div>)}
                
               {!question.multipileChoice && (
                 <form onSubmit={handleTextInputSubmit}>
                 <div className="text-input-field">
                   <input
                   ref={textInputRef}
                     className="input"
                     type="text"
                     placeholder="Type your feedback"
                    //  value={userTextInput}
                    //  onChange={(e) => setUserTextInput(e.target.value)}
                   />
                   {inputError && <p className="help is-danger">Please enter your feedback.</p>}
                   
                    <div className='column is-small'></div>
                   <button type="submit" className="button is-primary">Submit</button>
                 </div>
               </form>
               )}
            
          </div>
        </div>
      );
    }

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);

  const spinnerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px' };
  
  const handleAnswer = (isCorrect, answerText) => {
    const questionId = questions[currentQuestionIndex].id;
    const newAnswer = { 
      questionId: questions[currentQuestionIndex].id, 
      answerText,
      isCorrect,
    };
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: newAnswer,
  }));
   
    if (isCorrect) {
      setScore(score + 1);
    }
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
        setShowScore(true); // Handle quiz completion
        const name_respondent = answers['1']['answerText'];
       
        const response_data = {name : name_respondent, response: answers}
        axios.post(url, response_data)
    .then(response => {
        console.log('Response:', response.data);
    })
    .catch(error => {
        console.error('Error:', error);
    });
       
    }
  };
  const restartQuiz = () => {
    setScore(0);
    setCurrentQuestionIndex(0);
    setShowScore(false);
  };

  return (
    <div className="container quiz">
    {questions && catgeory ? (
      <section className="section">
        {showScore ? (
          <div className="box has-text-centered">
            <p className="title">Quiz Completed!</p>
            <p className="subtitle">Your score is {score} out of {questions.length}</p>
            <p className="subtitle">Thank you {answers['1']['answerText']} for participating in the quiz.</p>
            <button className="button is-primary" onClick={restartQuiz}>Restart Quiz</button>
          </div>
        ) : (
          <Question question={questions[currentQuestionIndex]} onAnswer={handleAnswer} />
        )}
      </section>
    ) : (
      <div className="has-text-centered">
        {/* Bulma Loader */}
        <div style={spinnerStyle}><div className="spinner"></div></div>
        {/* <div className="loader"></div> */}
      </div>
    )}
  </div>
  
  )
}

export default Exercises
