
import ReactECharts from 'echarts-for-react';
import { restructureDataForMonthNames, transformDataForPlottingWithMonths } from './UtilitiesCommon';

const SPIChartComponentWithMonths = ({chartDataset_from_api}) => {
    var spi_data = chartDataset_from_api?.cell_values?.spi_data
  const structuredData = restructureDataForMonthNames(spi_data);
  const series = transformDataForPlottingWithMonths(structuredData);

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: series.map(item => item.name),
      bottom: '0', 
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
    },
    yAxis: {
      type: 'value'
    },
    series: series
  };

  return (spi_data &&<ReactECharts option={option} style={{height: '200px'}} />);
};

export default SPIChartComponentWithMonths;
