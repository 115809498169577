import "../styles/IndicatorsStyle.css";

import { useState, useEffect } from "react";
import info from "../../images/info.svg";

import { filter_wms_by_boundary, months } from "../sidepane/SidePanel";
import DroughtIndicatorModal from "./DroughtIndicatorModal";
import { get_data } from "../Geoportal";
import { get_custom_list } from "./Indicators";
export function getMonthName(monthNumber) {
  const months_list = Object.keys(months);
  // const date = new Date();
  // date.setMonth(monthNumber - 1);
  return months_list[monthNumber - 1];

  // return date.toLocaleString('en-US', { month: 'short' });
}

export function handle_wmslink(
  wms_link,
  cell_column,
  selected_country,
  selected_region
) {
  if (selected_country !== null) {
    wms_link = filter_wms_by_boundary(
      wms_link,
      selected_country["country_id"],
      cell_column,
      1
    );
    return wms_link;
  }
  if (selected_region === null && selected_country === null) {
    return wms_link;
  }
  if (selected_region !== null && selected_country === null) {
    wms_link = filter_wms_by_boundary(
      wms_link,
      selected_region,
      cell_column,
      0
    );
    return wms_link;
  }
}

// handles data selected
export function handle_selected_data(array_selection, pre_selected) {

  if (array_selection.includes(pre_selected)) {
    return pre_selected;
  } else {
    return array_selection[0];
  }
}

export function handle_legend_url(layer_name_, geoserver, not_view=false) {
  if (not_view) {
    var legend_url = `${geoserver}REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layer_name_}&LEGEND_OPTIONS=border:true;dx:10;fontSize:11;`;
    return legend_url;
  }
  var legend_url = `${geoserver}REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layer_name_}_view&LEGEND_OPTIONS=border:true;dx:10;fontSize:11;`;
  return legend_url;
}

const Precipitation = ({
  get_select_element,
  customoptions,
  monthly_precipitation_check,
  setMonthly_recipitation_check,
  rs_data,
  precipitation_check,
  setPrecipitation_check,
  setCurrent_spi,
  setSpi_legend_url,
  months,
  selected_spi_dates,
  setSelected_spi_dates,
  get_filtered_wms_spi,
  selected_country,
  get_years,
  base_url,
  setCurrent_monthly_precipitation_gpcc,
  setGpcc_monthly_precipitation_legend_url,
  selected_region,
}) => {
  const [spi_dates, setSpi_dates] = useState({
    year: [2023, 2022, 2019],
    month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    timescale: ["01", "03", "06", "09", "12", "24", "48"],
  });

  const [monthly_precipitation_dates, setMonthly_precipitation_dates] =
    useState({ year: ["2023", "2022", "2019"], month: ["Jan", "Feb"] });

  const [monthly_precipitation_api_data, setMonthly_precipitation_api_data] =
    useState(null);

  // spi

  const [spi_info, setSpi_info] = useState(null);
  const [spi_latest_dates, setSpiLatestDates] = useState(null)

  const [
    selected_precipitation_monthly_dates,
    setSelectedprecipitation_monthly_dates,
  ] = useState({
    year: "2023",
    month: "Jan",
  });
  const [years_spi, setYears_spi] = useState([]);
  var geoserver = `${base_url}/geoserver/wms?`;
  let now = new Date();
  let current_year = now.getFullYear();
  let current_month = now.getMonth() + 1;

  var maplibre_str =
    "bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=";
  var layer_name = "africa_drought_datasets:grid_1dd_spi_";
  const [layer_name_monthly_precipitation, setLayerNameMonthlyPrecipitation] =
    useState("africa_drought_datasets:grid_1dd_gpcc_mon_rain_");
  const [guess_monthly_precip_name] = useState("africa_drought_datasets:grid_1dd_gpcc_guess_rain_");

  function modify_wms_link(wms_link_spi) {
    if (selected_country !== null) {
      get_filtered_wms_spi(wms_link_spi, selected_country["country_id"]);
    } else {
      setCurrent_spi(wms_link_spi);
    }
  }




  function set_innitial_selected(data) {

    var latest_year = data?.latest_year
    var start_year = data?.start_year
    var latest_timescale = data?.latest_timescale
    var latest_month = data?.latest_month
    var years = get_custom_list(start_year, latest_year)
    var month = getMonthName(latest_month)
    var months_spi = get_custom_list(1, latest_month)
    var month_names = months_spi.map(getMonthName)

    setSelected_spi_dates({
      year: latest_year,
      month:month ,
      timescale: latest_timescale,
    });

    var spi_geosever_link = `${geoserver}${maplibre_str}${layer_name}${months[month]}${latest_timescale}_view&CQL_FILTER=year=${latest_year}`;

    setCurrent_spi(spi_geosever_link);
    var spi_layer_legend = `${layer_name}${months[month]}${latest_timescale}`;
    var legend_url = handle_legend_url(spi_layer_legend, geoserver);
    setSpi_legend_url(legend_url);

    setSpiLatestDates({
      latest_year: latest_year,
      latest_timescale: latest_timescale,
      latest_month: latest_month
    }
    )
    var time_scales_ = data[latest_year]?.timescale
    
    setSpi_dates(
      {year:years,
        month:month_names,
        timescale:time_scales_
      }
    )
    setYears_spi(years)
  }

  const oncheck_precipitation_check = () => {
    setPrecipitation_check(!precipitation_check);
  };

  const oncheck_monthly_precipitation = () => {
    setMonthly_recipitation_check(!monthly_precipitation_check);
  };

  const onchange_year_spi = (e) => {
    var spi_months = spi_dates?.month
    var spi_timescale = spi_dates?.timescale
    if (spi_latest_dates !== null){

      if (
        parseInt(e.value, 10) !== parseInt(spi_latest_dates["latest_year"],10)
        ){
        spi_months = get_custom_list(1, 12)
        spi_months = spi_months.map(getMonthName) 
      }else{
        spi_months = get_custom_list(1, spi_latest_dates["latest_month"])
        spi_months = spi_months.map(getMonthName) 
      }
       

    }
   
    var selected_month = handle_selected_data(
      spi_months,
      selected_spi_dates["month"]
    );
    var selected_time_scale = handle_selected_data(
      spi_timescale.toString(),
      selected_spi_dates["timescale"]
    );

    var spi_geosever_link = `${geoserver}${maplibre_str}${layer_name}${months[selected_month]}${selected_time_scale}_view&CQL_FILTER=year=${e.value}`;
    // setCurrent_spi(spi_geosever_link);

    // modify the wms if the country is selected
    modify_wms_link(spi_geosever_link);

    var spi_selected_dates = {
      year: e.value,
      month: selected_month,
      timescale: selected_time_scale,
    };

    setSelected_spi_dates(spi_selected_dates);

    setSpi_dates({
      year: spi_dates["year"],
      month: spi_months,
      timescale: spi_timescale,
    });
  };

  const onchange_month_spi = (e) => {
    setSelected_spi_dates({
      year: selected_spi_dates["year"],
      month: e.value,
      timescale: selected_spi_dates["timescale"],
    });
    // set current spi
    var spi_geosever_link = `${geoserver}${maplibre_str}${layer_name}${
      months[e.value]
    }${selected_spi_dates["timescale"]}_view&CQL_FILTER=year=${
      selected_spi_dates["year"]
    }`;
    modify_wms_link(spi_geosever_link);
    // setCurrent_spi(spi_geosever_link);
  };
  const onchange_time_scale_spi = (e) => {
    setSelected_spi_dates({
      year: selected_spi_dates["year"],
      month: selected_spi_dates["month"],
      timescale: e.value,
    });
    // set current spi
    var spi_geosever_link = `${geoserver}${maplibre_str}${layer_name}${
      months[selected_spi_dates["month"]]
    }${e.value}_view&CQL_FILTER=year=${selected_spi_dates["year"]}`;
    // setCurrent_spi(spi_geosever_link);
    modify_wms_link(spi_geosever_link);
  };

  const onchange_year_monthly_precipitation = (e) => {
    var months_peryear = monthly_precipitation_api_data[e.value]["months"];
    months_peryear = months_peryear.map(Number);
    months_peryear = months_peryear.map(getMonthName);
    setMonthly_precipitation_dates({
      year: monthly_precipitation_dates["year"],
      month: months_peryear,
    });
    // handle the month selected for years with no data
    var selected_month = handle_selected_data(
      months_peryear,
      selected_precipitation_monthly_dates["month"]
    );

    var selected_month = selected_month;
    var selected_year = e.value;
    var layer = `${layer_name_monthly_precipitation}${months[selected_month]}`;
    var monthly_gpcc_wms = `${geoserver}${maplibre_str}${layer}_view&CQL_FILTER=year=${selected_year}`;
    monthly_gpcc_wms = handle_wmslink(
      monthly_gpcc_wms,
      "corr_cell",
      selected_country,
      selected_region
    );
    setCurrent_monthly_precipitation_gpcc({
      monthly_precipitation_gpcc_geoserver: monthly_gpcc_wms,
    });

    setSelectedprecipitation_monthly_dates({
      year: e.value,
      month: selected_month,
    });
  };

  const onchange_month_monthly_precipitation = (e) => {
    var selected_month = e.value;
    var selected_year = selected_precipitation_monthly_dates["year"];

    if (parseInt(selected_year,10) === current_year && parseInt(months[selected_month],10) === current_month-1){
      
      
      var layer = `${guess_monthly_precip_name}${months[selected_month]}`;
      
    }else{
      layer = `${layer_name_monthly_precipitation}${months[selected_month]}`;
    }

    if (parseInt(selected_year,10) === current_year && parseInt(months[selected_month],10) === current_month-2){
      layer = `${guess_monthly_precip_name}${months[selected_month]}`;
    }

    // var layer = `${layer_name_monthly_precipitation}${months[selected_month]}`;
    var monthly_gpcc_wms = `${geoserver}${maplibre_str}${layer}_view&CQL_FILTER=year=${selected_year}`;
    monthly_gpcc_wms = handle_wmslink(
      monthly_gpcc_wms,
      "corr_cell",
      selected_country,
      selected_region
    );
   
    setCurrent_monthly_precipitation_gpcc({
      monthly_precipitation_gpcc_geoserver: monthly_gpcc_wms,
    });
    setSelectedprecipitation_monthly_dates({
      year: selected_year,
      month: e.value,
    });
  };

  const handle_monthly_precipitation = (data) => {
    var monthly_precipitation_data = data?.gpcc_monthly_precipitation;
    var start_year_m_precip_data = monthly_precipitation_data?.start_year;
    var latest_year_m_precip_data = monthly_precipitation_data?.latest_year;
    var start_month_m_precip_data = monthly_precipitation_data?.start_month;
    var latest_month_m_precip_data = monthly_precipitation_data?.latest_month;

  

    

    var years_monthly_precipitation = get_custom_list(start_year_m_precip_data, latest_year_m_precip_data);
    var initial_months = get_custom_list(start_month_m_precip_data, latest_month_m_precip_data);
    initial_months = initial_months.map(getMonthName);
    setMonthly_precipitation_dates({
      year: years_monthly_precipitation,
      month: initial_months,
    });

   
    var selected_month = getMonthName(latest_month_m_precip_data);
    setSelectedprecipitation_monthly_dates({
      year: latest_year_m_precip_data,
      month: selected_month,
    });

    if (latest_year_m_precip_data === current_year && latest_month_m_precip_data === current_month-1){
      
      
      var layer = `${guess_monthly_precip_name}${months[selected_month]}`;
      
    }else{
      layer = `${layer_name_monthly_precipitation}${months[selected_month]}`;
    }

    if (latest_year_m_precip_data === current_year && latest_month_m_precip_data === current_month-2){
      layer = `${guess_monthly_precip_name}${months[selected_month]}`;
    }

    
    var monthly_gpcc_wms = `${geoserver}${maplibre_str}${layer}_view&CQL_FILTER=year=${latest_year_m_precip_data}`;

    var legend_url = handle_legend_url(layer, geoserver);

    setGpcc_monthly_precipitation_legend_url(legend_url);

    monthly_gpcc_wms = handle_wmslink(
      monthly_gpcc_wms,
      "corr_cell",
      selected_country,
      selected_region
    );

    setCurrent_monthly_precipitation_gpcc({
      monthly_precipitation_gpcc_geoserver: monthly_gpcc_wms,
    });
  };

  useEffect(() => {
    if (rs_data !== null) {
      set_innitial_selected(rs_data["spi"]);
    }
  }, [rs_data, layer_name_monthly_precipitation]);

  useEffect(() => {
    if (rs_data !== null) {
      var monthly_precipitation_data = rs_data?.gpcc_monthly_precipitation
      handle_monthly_precipitation(rs_data);

      setMonthly_precipitation_api_data(monthly_precipitation_data);

    }
  }, [rs_data]);


const [isDroughtModalVisibleSpi, setDroughtModalVisibleSpi] = useState(false)

const [IsLoadingSpiInfo, setIsLoadingSpiInfo] = useState(false)
const [monthlyPrecipInfo, setMonthlyPrecipInfo] = useState(null)
const [isDroughtModalVisibleMonthlyPrecip, setDroughtModalVisibleMonthlyPrecip] = useState(false)
const [IsLoadingMonPrecipInfo, setIsLoadingMonPrecipInfo] = useState(false)



  const handleInfoClickSpi = () => {
    setDroughtModalVisibleSpi(!isDroughtModalVisibleSpi);
  
    if (!isDroughtModalVisibleSpi) { 
      setIsLoadingSpiInfo(true); 
      const url = `${base_url}/api/metadata/?dataset_id=spi`;
      get_data(url).then((data) => {
        setSpi_info(data[0]);
        setIsLoadingSpiInfo(false);
      }).catch(error => {
        console.error('Failed to fetch CDI info:', error);
        setIsLoadingSpiInfo(false); 
      });
    }
  };

  const handleInfoClickMonthlyPrecip = () => {
    setDroughtModalVisibleMonthlyPrecip(!isDroughtModalVisibleMonthlyPrecip);
  
    if (!isDroughtModalVisibleMonthlyPrecip) { 
      setIsLoadingMonPrecipInfo(true); 
      const url = `${base_url}/api/metadata/?dataset_id=monthly_precip`;
      get_data(url).then((data) => {
        setMonthlyPrecipInfo(data[0]);
        setIsLoadingMonPrecipInfo(false);
      }).catch(error => {
        console.error('Failed to fetch CDI info:', error);
        setIsLoadingMonPrecipInfo(false); 
      });
    }
  };

  return (
    <div className="precipitation-module">
      <p className="Indicator-names">PRECIPITATION INDICATORS</p>

      <div className="info-subsection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={precipitation_check}
            onChange={oncheck_precipitation_check}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">
          Standard Precipitation Index (SPI)
        </p>
        <img className="info-icon-spi" src={info} alt="info" onClick={handleInfoClickSpi}/>

        {isDroughtModalVisibleSpi && (
  <>
        <DroughtIndicatorModal
        dataset={spi_info}
        isActive={isDroughtModalVisibleSpi}
        toggleModal={handleInfoClickSpi}
        isLoading={IsLoadingSpiInfo}
        />

  </>
)}

      </div>
      {precipitation_check ? (
        <p
          style={{
            position: "relative",
            color: "#000000",
            fontSize: "10px",
            left: "50px",
            marginTop: "40px",
            marginBottom: "-15px",
          }}
        >
          {selected_spi_dates["year"]}{" "}
          {selected_spi_dates["month"] +
            " TimeScale : " +
            selected_spi_dates["timescale"]}{" "}
        </p>
      ) : (
        <></>
      )}

      {rs_data !== null ? (
        <div className="cdi-selection">
          {get_select_element(
            customoptions,
            years_spi,
            onchange_year_spi,
            selected_spi_dates["year"],
            precipitation_check
          )}

          {get_select_element(
            customoptions,
            spi_dates["month"],
            onchange_month_spi,
            selected_spi_dates["month"],
            precipitation_check
          )}
          {get_select_element(
            customoptions,
            spi_dates["timescale"],
            onchange_time_scale_spi,
            selected_spi_dates["timescale"],
            precipitation_check
          )}
        </div>
      ) : (
        <></>
      )}

      {/* monthly preciptation  */}

      <div className="sub-section-selection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={monthly_precipitation_check}
            onChange={oncheck_monthly_precipitation}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description" style={{ marginLeft: "80px" }}>
          {" "}
          Monthly Precipitation
        </p>
        <img
          src={info}
          alt="info"
          style={{ left: "67%", position: "absolute" }}
          onClick={handleInfoClickMonthlyPrecip}
        />

        {isDroughtModalVisibleMonthlyPrecip && (
          <>
                <DroughtIndicatorModal
                dataset={monthlyPrecipInfo}
                isActive={isDroughtModalVisibleMonthlyPrecip}
                toggleModal={handleInfoClickMonthlyPrecip}
                isLoading={IsLoadingMonPrecipInfo}
                />

          </>
        )}

        {monthly_precipitation_check ? (
          <p
            style={{
              position: "relative",
              color: "#000000",
              fontSize: "10px",
              left: "50px",
              marginTop: "4px",
              marginBottom: "-15px",
            }}
          >
            {selected_precipitation_monthly_dates["year"]}{" "}
            {selected_precipitation_monthly_dates["month"]}{" "}
          </p>
        ) : (
          <></>
        )}

      

        <div className="cdi-monthly-selection">
          {get_select_element(
            customoptions,
            monthly_precipitation_dates["year"],
            onchange_year_monthly_precipitation,
            selected_precipitation_monthly_dates["year"],
            monthly_precipitation_check
          )}

          {get_select_element(
            customoptions,
            monthly_precipitation_dates["month"],
            onchange_month_monthly_precipitation,
            selected_precipitation_monthly_dates["month"],
            monthly_precipitation_check
          )}
        </div>
      </div>
    </div>
  );
};

export default Precipitation;
