import { useState, useEffect } from "react";
import SidePanel from "./sidepane/SidePanel";

import Legend from "./Legend";
import RightBar from "./RightBar";

import Map from "./map";
// import Position from "rsuite/esm/Overlay/Position";
import axios from "axios";
import ReactGA from "react-ga4";

import { handle_legend_url } from "./sidepane/Precipitation";

export const TRACKING_ID = "G-4L6WN78NLS";

export const base_url = "https://ada.acmad.org";
export const geoserver = `${base_url}/geoserver/wms?`;
export const maplibre_str =
  "bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=";

export const get_data = async (url) => {
  const res = await axios.get(url);

  const data = res.data;

  return data;
};
ReactGA.initialize(TRACKING_ID);
const Geoportal = () => {
  const [cdi_selected_dates, setCdi_selected_dates] = useState({
    year: 2020,
    month: "Aug",
    dekad: "3rd",
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "geoportal",
    });
  }, []);

  const [current_cdi, setCurrent_cdi] = useState(null);
  const [cdi_check, setCdi_check] = useState(false);
  const [countries_options, setCountries_options] = useState({});
  const [selected_country, setSelected_country] = useState(null);
  const [selected_region, setSelected_region] = useState(null);
  const [rs_data, setRs_data] = useState(null);
  const [precipitation_check, setPrecipitation_check] = useState(true);
  const [current_vector, setCurrent_vector] = useState(null);
  const [current_spi, setCurrent_spi] = useState(null);
  const [current_fapar_anomaly, setCurrent_fapar_anomaly] = useState(null);
  const [spi_legend_url, setSpi_legend_url] = useState(null);
  const [fapar_anomaly_legend_url, setFapar_anomaly_legend_url] =
    useState(null);
  const [fapar_anomaly_check, setFapar_anomaly_check] = useState(false);
  const [fapar_absorbed_check, setFapar_absorbed_check] = useState(false);
  const [fapar_absorbed_legend_url, setFapar_absorbed_legend_url] =
    useState(null);
  const [current_fapar_absorbed, setCurrent_fapar_absorbed] = useState(null);
  // precipitation props

  const [monthly_precipitation_check, setMonthly_recipitation_check] =
    useState(false);

  const [
    gpcc_monthly_precipitation_legend_url,
    setGpcc_monthly_precipitation_legend_url,
  ] = useState(null);

  const [
    current_monthly_precipitation_gpcc,
    setCurrent_monthly_precipitation_gpcc,
  ] = useState(null);

  // soil props
  const [sma_check, setSma_check] = useState(false);
  const [current_sma, setCurrent_sma] = useState(null);
  const [legend_url_sma, setLegend_url_sma] = useState(null);
  // ndvi props
  const [request_str_modis_ndvi, setRequest_str_modis_ndvi] = useState(null);
  const [current_ndvi, setCurrent_ndvi] = useState(null);

  const [ndvi_check, setNdvi_check] = useState(false);
  const [koppen_climate_clas_check, setKoppen_climate_clas_check] =
    useState(false);

  const [active_admin_boundaries, setActive_admin_boundaries] = useState(true);

  const [current_protected_areas, setCurrent_protected_areas] = useState(null);
  const [protetect_areas_check, setProtetect_areas_check] = useState(false);

  const base_url = "https://ada.acmad.org";

  // var geoserver = `${base_url}/geoserver/wms?`;
  const layer_name_kopen_climate =
    "africa_drought_datasets:koppen_classification_compreesed";
  const koppen_classification_url = `${geoserver}${maplibre_str}${layer_name_kopen_climate}`;
  const leged_url_koppen = handle_legend_url(
    layer_name_kopen_climate,
    geoserver,
    true
  );
  const [koppen_climate_url] = useState({
    geoserver: koppen_classification_url,
    leged_url: leged_url_koppen,
  });
  // console.log(koppen_climate_url)
  // var temp_base_url = "http://localhost:8100"
  useEffect(() => {
    const get_rs_data = async () => {
      const rs_data = await get_data(
        `${base_url}/data_api/rs_data/get_available_datasets`
      );
      setRs_data(rs_data);
    };
    get_rs_data();
  }, []);

  return (
    <div className="geoportal">
      {/* <Header /> */}
      <SidePanel
        countries_options={countries_options}
        setCountries_options={setCountries_options}
        selected_country={selected_country}
        setSelected_country={setSelected_country}
        rs_data={rs_data}
        precipitation_check={precipitation_check}
        setPrecipitation_check={setPrecipitation_check}
        setCurrent_vector={setCurrent_vector}
        setCurrent_spi={setCurrent_spi}
        setSpi_legend_url={setSpi_legend_url}
        current_spi={current_spi}
        setCurrent_fapar_anomaly={setCurrent_fapar_anomaly}
        current_fapar_anomaly={current_fapar_anomaly}
        fapar_anomaly_check={fapar_anomaly_check}
        setFapar_anomaly_check={setFapar_anomaly_check}
        setFapar_anomaly_legend_url={setFapar_anomaly_legend_url}
        fapar_absorbed_check={fapar_absorbed_check}
        setFapar_absorbed_check={setFapar_absorbed_check}
        base_url={base_url}
        current_fapar_absorbed={current_fapar_absorbed}
        setCurrent_fapar_absorbed={setCurrent_fapar_absorbed}
        setFapar_absorbed_legend_url={setFapar_absorbed_legend_url}
        setCurrent_monthly_precipitation_gpcc={
          setCurrent_monthly_precipitation_gpcc
        }
        current_monthly_precipitation_gpcc={current_monthly_precipitation_gpcc}
        monthly_precipitation_check={monthly_precipitation_check}
        setMonthly_recipitation_check={setMonthly_recipitation_check}
        setGpcc_monthly_precipitation_legend_url={
          setGpcc_monthly_precipitation_legend_url
        }
        selected_region={selected_region}
        setSelected_region={setSelected_region}
        setCurrent_sma={setCurrent_sma}
        current_sma={current_sma}
        sma_check={sma_check}
        setSma_check={setSma_check}
        setLegend_url_sma={setLegend_url_sma}
        setCurrent_cdi={setCurrent_cdi}
        cdi_check={cdi_check}
        setCdi_check={setCdi_check}
        current_cdi={current_cdi}
        cdi_selected_dates={cdi_selected_dates}
        setCdi_selected_dates={setCdi_selected_dates}
        request_str_modis_ndvi={request_str_modis_ndvi}
        setRequest_str_modis_ndvi={setRequest_str_modis_ndvi}
        setCurrent_ndvi={setCurrent_ndvi}
        ndvi_check={ndvi_check}
        setNdvi_check={setNdvi_check}
        koppen_climate_clas_check={koppen_climate_clas_check}
        setKoppen_climate_clas_check={setKoppen_climate_clas_check}
        active_admin_boundaries={active_admin_boundaries}
        setActive_admin_boundaries={setActive_admin_boundaries}
        current_protected_areas={current_protected_areas}
        setCurrent_protected_areas={setCurrent_protected_areas}
        protetect_areas_check={protetect_areas_check}
        setProtetect_areas_check={setProtetect_areas_check}
      />

      <Map
        selected_country={selected_country}
        precipitation_check={precipitation_check}
        current_vector={current_vector}
        current_spi={current_spi}
        current_fapar_anomaly={current_fapar_anomaly}
        fapar_anomaly_check={fapar_anomaly_check}
        current_fapar_absorbed={current_fapar_absorbed}
        fapar_absorbed_check={fapar_absorbed_check}
        current_monthly_precipitation_gpcc={current_monthly_precipitation_gpcc}
        monthly_precipitation_check={monthly_precipitation_check}
        current_sma={current_sma}
        sma_check={sma_check}
        cdi_check={cdi_check}
        current_cdi={current_cdi}
        cdi_selected_dates={cdi_selected_dates}
        current_ndvi={current_ndvi}
        ndvi_check={ndvi_check}
        koppen_climate_clas_check={koppen_climate_clas_check}
        koppen_climate_url={koppen_climate_url}
        active_admin_boundaries={active_admin_boundaries}
        current_protected_areas={current_protected_areas}
        protetect_areas_check={protetect_areas_check}
      />

      <RightBar
        spi_legend_url={spi_legend_url}
        Legend={Legend}
        precipitation_check={precipitation_check}
        fapar_anomaly_check={fapar_anomaly_check}
        fapar_anomaly_legend_url={fapar_anomaly_legend_url}
        fapar_absorbed_check={fapar_absorbed_check}
        fapar_absorbed_legend_url={fapar_absorbed_legend_url}
        monthly_precipitation_check={monthly_precipitation_check}
        gpcc_monthly_precipitation_legend_url={
          gpcc_monthly_precipitation_legend_url
        }
        sma_check={sma_check}
        legend_url_sma={legend_url_sma}
        cdi_check={cdi_check}
        ndvi_check={ndvi_check}
        koppen_climate_clas_check={koppen_climate_clas_check}
        koppen_climate_url={koppen_climate_url}
      />
    </div>
  );
};

export default Geoportal;
