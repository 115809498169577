import CountiesChart from './CountiesChart';

const SidePanel = ({chartdata}) => {

  return (
    <div className="box"> {/* Bulma box for the panel */}
      <h3 className="title is-6">Drought Stress Levels per Country</h3> {/* Bulma title */}

      {/* Drought Indicator Legend */}
      <div className="buttons are-small"> {/* Bulma buttons */}
        <button className="button is-danger">High</button> {/* Bulma button color */}
        <button className="button button-custom-medium">Medium</button> {/* Custom color #FFA600 */}
        <button className="button button-custom-low">Low</button> {/* Custom color #fff645 */}

      </div>

      {/* Graph Section */}
      <div>
       {chartdata && <CountiesChart data={chartdata} />}

      </div>
    </div>
  );
};

export default SidePanel;
