import "../styles/IndicatorsStyle.css";

import { useState } from "react";
import info from "../../images/info.svg";

import {base_url, maplibre_str} from "../Geoportal";

import {handle_legend_url} from "../sidepane/Precipitation";

const GeographicBackground = ({
  get_select_element,
  customoptions,
  temp_class_check,
  setTemp_class_check,
  koppen_climate_clas_check, 
  setKoppen_climate_clas_check
}) => {
  const [disable_selection, setDisable_selection] = useState(true);
  const [soil_type_check, setSoil_type_check] = useState(false);
  const [landuse_check, setLanduse_check] = useState(false);




  
 

  const [soil_type_date_data, setSoil_type_date_data] = useState({
    year: [2020, 2019],
    month: ["Nov", "Oct", "Sept"],
  });
  const [selected_soil_type_dates, setSelected_soil_type_dates] = useState({
    year: soil_type_date_data["year"][0],
    month: soil_type_date_data["month"][0],
  });

  const [landuse_dates_data, setLanduse_dates_data] = useState({
    year: [2020, 2019],
    month: ["Nov", "Oct", "Sept"],
  });
  const [selected_landuse_dates, setSelected_landuse_dates] = useState({
    year: landuse_dates_data["year"][0],
    month: landuse_dates_data["month"][0],
  });

  const [temprature_dates_data, setTemprature_dates_data] = useState({
    year: [2020, 2019],
    month: ["Nov", "Oct", "Sept"],
  });
  const [selected_temprature_dates, setSelected_temprature_dates] = useState({
    year: temprature_dates_data["year"][0],
    month: temprature_dates_data["month"][0],
  });

  const oncheck_koppen_climate = () => {
    setKoppen_climate_clas_check(!koppen_climate_clas_check);
  };

  const oncheck_soil_type = () => {
    setSoil_type_check(!soil_type_check);
  };
  const oncheck_landuse = () => {
    setLanduse_check(!landuse_check);
  };

  const oncheck_temp_class = () => {
    setTemp_class_check(!temp_class_check);
  };

  const onchange_year_soil_type = (e) => {
    setSelected_soil_type_dates({
      year: e.value,
      month: selected_soil_type_dates["month"],
    });
  };
  const onchange_month_soil_type = (e) => {
    setSelected_soil_type_dates({
      year: selected_soil_type_dates["month"],
      month: e.value,
    });
  };

  const onchange_year_landuse = (e) => {
    setSelected_landuse_dates({
      year: e.value,
      month: selected_landuse_dates["month"],
    });
  };

  const onchange_month_landuse = (e) => {
    setSelected_landuse_dates({
      year: selected_landuse_dates["month"],
      month: e.value,
    });
  };

  const onchange_year_temp_cl = (e) => {
    setSelected_temprature_dates({
      year: e.value,
      month: selected_temprature_dates["month"],
    });
  };
  const onchange_month_temp_cl = (e) => {
    setSelected_temprature_dates({
      year: selected_temprature_dates["month"],
      month: e.value,
    });
  };

  const change_margin_top = (condition) => {
    var margin_top_value = "20px";
    if (condition) {
      margin_top_value = "60px";
    }
    return { marginTop: margin_top_value };
  };

  return (
    <div className="geography-bg-module">
      <p className="Indicator-names">GEOGRAPHIC BACKGROUND</p>

      <div className="info-subsection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={koppen_climate_clas_check}
            onChange={oncheck_koppen_climate}
            disabled={false}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">Koppen Climate Classification</p>
        <img
          style={{ position: "relative", left: "35%" , marginBottom: "100px"}}
          src={info}
          alt="info"
        />
      </div>


    
      {/* soil type module */}
      {/* <div className="sub-section-selection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={soil_type_check}
            onChange={oncheck_soil_type}
            disabled={disable_selection}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">Soil Type</p>
        <img
          style={{ position: "relative", left: "42%" }}
          src={info}
          alt="info"
        />

        {soil_type_check ? (
          <p
            style={{
              position: "relative",
              color: "#000000",
              fontSize: "10px",
              left: "50px",
              marginTop: "4px",
              marginBottom: "-15px",
            }}
          >
            {selected_soil_type_dates["year"]}{" "}
            {selected_soil_type_dates["month"]}
          </p>
        ) : (
          <></>
        )}
      </div>

      <div className="cdi-monthly-selection">
        {get_select_element(
          customoptions,
          soil_type_date_data["year"],
          onchange_year_soil_type,
          selected_soil_type_dates["year"],
          soil_type_check,
          disable_selection
        )}

        {get_select_element(
          customoptions,
          soil_type_date_data["month"],
          onchange_month_soil_type,
          selected_soil_type_dates["month"],
          soil_type_check
        )}
      </div> */}

      {/* land use  */}
      {/* <div
        className="landuse-sub-section-selection"
        style={change_margin_top(soil_type_check)}
      >
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={landuse_check}
            onChange={oncheck_landuse}
            disabled={disable_selection}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">Land Use</p>
        <img
          style={{ position: "relative", left: "42%" }}
          src={info}
          alt="info"
        />

        {landuse_check ? (
          <p
            style={{
              position: "relative",
              color: "#000000",
              fontSize: "10px",
              left: "50px",
              marginTop: "4px",
              marginBottom: "-15px",
            }}
          >
            {selected_landuse_dates["year"]} {selected_landuse_dates["month"]}
          </p>
        ) : (
          <></>
        )}
      </div> */}

      {/* <div className="cdi-monthly-selection">
        {get_select_element(
          customoptions,
          landuse_dates_data["year"],
          onchange_year_landuse,
          selected_landuse_dates["year"],
          landuse_check
        )}

        {get_select_element(
          customoptions,
          landuse_dates_data["month"],
          onchange_month_landuse,
          selected_landuse_dates["month"],
          landuse_check
        )}
      </div> */}

      {/* <div
        className="landuse-sub-section-selection"
        style={change_margin_top(landuse_check)}
      >
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={temp_class_check}
            onChange={oncheck_temp_class}
            disabled={disable_selection}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">Temperature Classification</p>
        <img
          style={{ position: "relative", left: "23.5%" }}
          src={info}
          alt="info"
        />

        {temp_class_check ? (
          <p
            style={{
              position: "relative",
              color: "#000000",
              fontSize: "10px",
              left: "50px",
              marginTop: "4px",
              marginBottom: "-15px",
            }}
          >
            {selected_temprature_dates["year"]}{" "}
            {selected_temprature_dates["month"]}
          </p>
        ) : (
          <></>
        )}
      </div> */}

      {/* <div className="cdi-monthly-selection">
        {get_select_element(
          customoptions,
          temprature_dates_data["year"],
          onchange_year_temp_cl,
          selected_temprature_dates["year"],
          temp_class_check
        )}

        {get_select_element(
          customoptions,
          temprature_dates_data["month"],
          onchange_month_temp_cl,
          selected_temprature_dates["month"],
          temp_class_check
        )}
      </div> */}
    </div>
  );
};

export default GeographicBackground;
