
import stressexample from './images/stressexample.png';
import Area from './images/Area.png';
import { months } from '../sidepane/SidePanel';


import {base_url, get_data} from '../Geoportal'

import {get_num_withzero} from '../sidepane/Indicators'

const months_names  = Object.keys(months)
const months_options = months_names.map((month) => {
    return { answerText: month, isCorrect: null };
    });


const cdi_available_data_url = `${base_url}/data_api/rs_data/get_available_datasets?data_name=cdi`
const cdi_reporting_statistics_url= `${base_url}/data_api/geojson/get_reporting_statistics/`
const cdi_data_country_url = `${base_url}/data_api/geojson/get_reporting_statistics/?year=`

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

export async function AlterQuestionsAnswers(questions) {
  try {
    // Await for the CDI data to be fetched before moving on.s
    const cdi_data = await get_data(cdi_available_data_url);
    const latest_dekad = get_num_withzero(cdi_data?.cdi?.latest_dekad);
    var latest_month = get_num_withzero(cdi_data?.cdi?.latest_month);
    var latest_year = get_num_withzero(cdi_data?.cdi?.latest_year);

    

    // Wait for the reporting statistics to be fetched based on the CDI data.
    const reporting_statistics = await get_data(`${cdi_reporting_statistics_url}?year=${latest_year}&month=${latest_month}&dekad=${latest_dekad}&data=cdi_overview_africa`);
    const reporting_statistics_lesotho = await get_data(`${cdi_data_country_url}${latest_year}&month=${latest_month}&dekad=${latest_dekad}&country_id=142`);
    const reporting_statistics_dr_congo = await get_data(`${cdi_data_country_url}${latest_year}&month=${latest_month}&dekad=${latest_dekad}&country_id=68`);
    const cdi_overview = reporting_statistics?.cdi?.overview;
    const lesotho_cdi_percentage_classes = reporting_statistics_lesotho?.cdi?.percentage_area;
    const dr_congo_cdi_percentage_classes= reporting_statistics_dr_congo?.cdi?.percentage_area;

    if (lesotho_cdi_percentage_classes && dr_congo_cdi_percentage_classes) {
    
      //  "Warning": 14167.5,
      // "Alert": 42944.9


      const answerOptionsLesotho = [
        { answerText: lesotho_cdi_percentage_classes?.Watch, isCorrect: false},
        { answerText: lesotho_cdi_percentage_classes?.Warning, isCorrect: false},
        { answerText: lesotho_cdi_percentage_classes?.Alert, isCorrect: true},
        
      ]

      const answerOptionsDrCongo = [
        { answerText: dr_congo_cdi_percentage_classes?.Watch, isCorrect: true},
        { answerText: dr_congo_cdi_percentage_classes?.Warning, isCorrect: false},
        { answerText: dr_congo_cdi_percentage_classes?.Alert, isCorrect: false},
      ]

      const questionIndex_lesotho = questions.findIndex(question => question.id === 5);
      const questionIndex_DrCongo = questions.findIndex(question => question.id === 23);

      if (questionIndex_lesotho !== -1) {
        questions[questionIndex_lesotho].answerOptions = shuffleArray(answerOptionsLesotho);
      }

      if (questionIndex_DrCongo !== -1) {
        questions[questionIndex_DrCongo].answerOptions = shuffleArray(answerOptionsDrCongo);
      }

    }

    if (cdi_overview) {
      const restructuredData = cdi_overview.map((item, index) => ({
        answerText: item.country_name,
        isCorrect: index === 0
      }));

      const restructuredDataLeastAffectedCountry = cdi_overview.map((item, index, array) => ({
        answerText: item.country_name,
        isCorrect: index === array.length - 1 // Set true for the last item
      }));

      const questionIndex_countries = questions.findIndex(question => question.id === 3);
      const questionIndex_countries_least_affected = questions.findIndex(question => question.id === 4);

      if (questionIndex_countries_least_affected!== -1) {
        questions[questionIndex_countries_least_affected].answerOptions = shuffleArray(restructuredDataLeastAffectedCountry);
      }

      



      // Check if the question exists
      if (questionIndex_countries !== -1) {
        // Directly update the answerOptions of the found question
        questions[questionIndex_countries].answerOptions = shuffleArray(restructuredData);

      } 
      
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
  return questions;
}





const cities = [
    { id: 1, townName: 'Leribe' , country: 'Lesotho'},
    { id: 2, townName: 'Bhisho' , country: 'South Africa'},
    { id: 3, townName: 'Muchinga' , country: 'Zambia'},
    { id: 4, townName: 'Benue State',  country: 'Nigeria'},
    { id: 5, townName: 'Marrakech-safi', country: 'Morocco'},];

  
 
  
export const  questions = [
  {
    id: 1,
    questionText: 'Please Enter Your Name',
    multipileChoice: false,
  },
  {
    id: 2,
    questionText: 'What is the name of your town?',
    multipileChoice: true,
    answerOptions: cities.map((city) => {
      return { answerText: `${city.townName}, ${city.country}`, isCorrect: true };
    }),

  },
 
    {
        id: 3,
        questionText: 'Which country has the highest Drought stress level?',
        questionInstruction: 'Navigate to the Home page at `https://ada.acmad.org/home` check for this example image from the webiste',
        questionImage : stressexample,
        navigationLinkText: "ADMA Geoportal",
        navigationLink: "https://ada.acmad.org/",
        multipileChoice: true,
     
        answerOptions: [
          { answerText: 'Lesotho', isCorrect: true },
          { answerText: 'Zimbabwe', isCorrect: false },
          { answerText: 'Morocco', isCorrect: false },
          { answerText: 'Namibia', isCorrect: false },
          { answerText: 'Congo', isCorrect: false },
          { answerText: 'Togo', isCorrect: false }
          // other options...
        ],
      },

      {
        id: 4,
        questionText: 'Which country has the least Drought stress level?',
        questionInstruction: 'Navigate to the Home page at `https://ada.acmad.org/home` check for this example image from the webiste be sure to scroll down to see the least drought stress level',
        questionImage : stressexample,
        navigationLinkText: "ADMA Geoportal",
        navigationLink: "https://ada.acmad.org/",
        multipileChoice: true,
     
        answerOptions: [
          { answerText: 'Seirra Leon', isCorrect: false },
          { answerText: 'Morroco', isCorrect: false },
          { answerText: 'Somalia', isCorrect: true },
          { answerText: 'Sudan', isCorrect: false }
          // other options...
        ],
      },
  {
    id: 5,
    questionText: 'What is the area in square kilometers for the Alert class?',
    questionInstruction: 'Navigate to the Geoportal page at `https://ada.acmad.org/` click on the map on Lesotho and click on the generate report on the report page what is the area in square kilometers for the watch class?',
    multipileChoice: true,
    navigationLinkText: "ADMA Geoportal",
    navigationLink: "https://ada.acmad.org/",
    questionImage : Area,
    navigationLinkText: "ADMA Geoportal",
    answerOptions: [
      { answerText: '112768.6', isCorrect: false },
      { answerText: '-9999', isCorrect: true },
      // other options...
    ],
  },
  {
    id: 6,
    questionText: 'What is the fuction of the combined drought index?',
    questionInstruction: 'Navigate to the Geoportal page at `https://ada.acmad.org/` click on the info Icon to the right of the combined drought index to see the fuction of the combined drought index',
    navigationLinkText: "ADMA Geoportal",
    navigationLink: "https://ada.acmad.org/",
    multipileChoice: true,
    answerOptions: [
      { answerText: 'Identify areas affected by agricultural drought, and areas with the potential to be affected.', isCorrect: true },
      { answerText: 'Identification of soil moisture.', isCorrect: false },
      { answerText: 'Identification of agricultural ingridients.', isCorrect: false }
      // other options...
    ],
  },

    { id: 7,
        questionText: "what is the spatial resolution of Standardized Precipitation Index (SPI) data?",
        questionInstruction: 'Navigate to the Geoportal page at `https://ada.acmad.org/` click on the info Icon to the right of the Standardized Precipitation Index (SPI) to see the spatial resolution of the data',
        multipileChoice: true,
        navigationLinkText: "ADMA Geoportal",
        navigationLink: "https://ada.acmad.org/",
        answerOptions: [
            { answerText: '1 decimal degrees', isCorrect: true },
            { answerText: '0.5 decimal degrees', isCorrect: false }
            
            ],
    },
    { id: 8,
        questionText: "What is the temporal resolution of Standardized Precipitation Index (SPI) data?",
        questionInstruction: 'Navigate to the Home page at `https://ada.acmad.org/` click on the info Icon to the right of the Standardized Precipitation Index (SPI) to see the temporal resolution of the data',
        multipileChoice: true,
        answerOptions: [
            { answerText: 'Monthly', isCorrect: true },
            { answerText: 'Yearly', isCorrect: false }
        ]
    },

    {id:9,
     questionCategory: {id : 0, text:'First exercise round: Getting to know the system'},
     questionText : "How much rain fell in February 2024 in ",
     questionCategoryText : 'Define your town'

    },
    {id:10,
        questionCategory: {id : 0, text:'First exercise round: Getting to know the system'},
        questionText : "How much rain usually falls in February in ",
        questionCategoryText : 'Define your town'
   
       },
    
    {id:11,
        questionCategory: {id : 0, text:'First exercise round: Getting to know the system'},
        questionText : "According to the system, in which month the rainy season start in ",
        questionCategoryText : 'Define your town',
        multipileChoice: true,
        answerOptions: months_options
   
       },

       {id:12,
        questionCategory: {id : 0, text:'First exercise round: Getting to know the system'},
        questionText : "When does it end?",
        questionCategoryText : 'Define your town',
        multipileChoice: true,
        answerOptions: months_options
   
       },
    

    {id:13,
        questionCategory: {id : 0, text:'First exercise round: Getting to know the system'},
        questionText : "What is the 1-month SPI in July 2022 for",
        questionCategoryText : 'Define your town',
   
       },
         
       {id:14,
          questionCategory: {id : 0, text:'First exercise round: Getting to know the system'},
          questionText : "What is the 3-month SPI for July 2022 for",
          questionCategoryText : 'Define your town',
    
         },

           
         {id:15,
            questionCategory: {id : 0, text:'First exercise round: Getting to know the system'},
            questionText : "Explain the difference",
            questionCategoryText : 'Define your town',
      
           },

           {id:16,
            questionCategory: {id : 1, text:'Second set, understanding impacts via fAPAR and using time-selection'},
            questionText : "What is the lowest fAPAR anomaly ever recorded in ",
            questionCategoryText : 'Define your town',
      
           },
              
           {id:17,
                questionCategory: {id : 1, text:'Second set, understanding impacts via fAPAR and using time-selection'},
                questionText : "What is the highest fAPAR anomaly ever recorded in ",
                questionCategoryText : 'Define your town',
        
              },

              {id:18,
                questionCategory: {id : 1, text:'Second set, understanding impacts via fAPAR and using time-selection'},
                questionText : "How much forested land was damaged during this ever-lowest fAPAR anomaly moment in your province or county",
                questionCategoryText : 'Define your town',
        
              },

        
              {id:19,
                questionCategory: {id : 2, text:'Third set, drought events using Soil Moisture Anomaly (SMA)'},
                questionText : "Which region in Africa is currently most affected by soil moisture anomalies?",
                questionCategoryText : 'Regional analysis',
        
              },

                {id:20,
                    questionCategory: {id : 2, text:'Third set, drought events using Soil Moisture Anomaly (SMA)'},
                    questionText : "List the affected countries",
                    questionCategoryText : 'Regional analysis',
            
                  },

                  {id:21,
                    questionCategory: {id : 2, text:'Third set, drought events using Soil Moisture Anomaly (SMA)'},
                    questionText : "When did this drought start?",
                    questionCategoryText : 'Regional analysis',
            
                  },

                  {id:22,
                    questionCategory: {id : 2, text:'Third set, drought events using Soil Moisture Anomaly (SMA)'},
                    questionText : "Which type of Climate Zones is affected by this drought?",
                    questionCategoryText : 'Regional analysis',
            
                  },

                  {id:23,
                    questionCategory: {id : 2, text:'Fourth set, Combined Drought Index'},
                    questionText : "What is the percentage watch in DR Congo at this moment?",
                    questionCategoryText : 'Country analysis',
                    questionImage : Area,
                    multipileChoice: true,
            
                  },

                  {id:24,
                    questionCategory: {id : 2, text:'Fourth set, Combined Drought Index'},
                    questionText : "On which parameter(s) is this based?",
                    questionCategoryText : 'Country analysis',
            
                  },

                  {id:25,
                    questionCategory: {id : 2, text:'Fourth set, Combined Drought Index'},
                    questionText : "Which region in Zambia is most affected by drought?",
                    questionCategoryText : 'Country analysis',
            
                  },

                  {id:26,
                    questionCategory: {id : 2, text:'Fourth set, Combined Drought Index'},
                    questionText : "Which parameter is determining this drought, fAPAR or Soilmoisture?",
                    questionCategoryText : 'Country analysis',
            
                  },

                  {id:27,
                    questionCategory: {id : 2, text:'Fourth set, Combined Drought Index'},
                    questionText : "What is the reason?",
                    questionCategoryText : 'Country analysis',
            
                  },



                    
             

                  


];