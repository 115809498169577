import IndicatorsStyle from "../styles/IndicatorsStyle.css";
// import { useState } from "react";
import { useEffect } from "react";
import info from "../../images/info.svg";

import { base_url, get_data } from "../Geoportal";

const ProtectedAreas = ({
  get_select_element,
  customoptions,
  protetect_areas_check,
  setProtetect_areas_check,
  current_protected_areas,
  setCurrent_protected_areas

}) => {
  const oncheck_protected_areas = () => {
    setProtetect_areas_check(!protetect_areas_check);
  };

  const protected_gee_wms = async (url)=>{
    var protected_layer = await get_data(url)

    setCurrent_protected_areas(protected_layer['image_url'])
    // console.log(protected_layer, "ndvi['image_url']");
  }


  useEffect(() => {
    if (protetect_areas_check) {
      var protected_layer_url = `${base_url}/data_api/rs_data/get_protected_areas?region=continent`
      protected_gee_wms(protected_layer_url)
    } else {
      setCurrent_protected_areas(null)
    }
  }
    , [protetect_areas_check]);

  return (
    <div className="protected-areas-module">
      <p className="Indicator-names">SOCIAL ECONOMIC LAYERS</p>

      <div className="info-subsection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={protetect_areas_check}
            onChange={oncheck_protected_areas}
            disabled={false}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">Protected Areas</p>
        <img
          style={{ position: "relative", left: "88%" }}
          src={info}
          alt="info"
        />
      </div>
    </div>
  );
};

export default ProtectedAreas;
