
import ReactECharts from 'echarts-for-react';
import {prepareGpccChartData } from './UtilitiesCommon';
import { barWidth } from './MonthlyBarChart';
const RainfallChartWithYearAndPattern = ({chartDataset_from_api}) => {

    var gpcc_data = chartDataset_from_api?.cell_values?.gpcc_data;
    console.log(gpcc_data);
   
    var seriesData = prepareGpccChartData(gpcc_data);
  
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      
      legend: {
        data: seriesData.map(item => item.name),
        bottom: '0', 
      },
      xAxis: {
        type: 'category',
        data: seriesData.map(item => item.name),
        // axisLabel: {
        //   interval: 0,
        //   rotate: 45
        // }
      },
      yAxis: {
        type: 'value',
        name: 'Rainfall (mm)',

      },
      series: [
        {
          data: seriesData.map(item => item.value),
          type: 'bar',
          barWidth: barWidth,
          itemStyle: {
            color: '#67C9FB', // This sets the color of the bars
          },
          showBackground: true,
          backgroundStyle: {
            color: 'rgb(103,201,251, 0.2)',
          },
        },
      ],
    };
  
    return (gpcc_data &&<ReactECharts option={option} style={{ height: '200px' }} />);
  };
  
  export default RainfallChartWithYearAndPattern;