import "../styles/SidepanelStyle.css";

import CountrySelection from "./CountrySelection";
import Indicators from "./Indicators";
import { useState } from "react";

export function filter_wms_by_boundary(
  current_wms_link,
  region_code,
  cell_column,
  level
) {
  var wmslink = current_wms_link.split("CQL_FILTER")[0];
  var year_selected = current_wms_link.split("year=")[1];
  if (level === 0) {
    var column_code = "region_code";
    var filtered_wms = `${wmslink}CQL_FILTER=INTERSECTS(${cell_column}, collectGeometries(queryCollection('data_api_adminlevelzeroregion', 'geometry', '${column_code} = ''${region_code}'''))) AND year=${year_selected}`;
  }
  if (level === 1) {
    column_code = "country_code";
    filtered_wms = `${wmslink}CQL_FILTER=INTERSECTS(${cell_column}, collectGeometries(queryCollection('data_api_adminlevelzero', 'geometry', '${column_code} = ''${region_code}'''))) AND year=${year_selected}`;
  }

  return filtered_wms;
}

export const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

const SidePanel = ({
  countries_options,
  setCountries_options,
  setSelected_country,
  selected_country,
  rs_data,
  precipitation_check,
  setPrecipitation_check,
  setCurrent_vector,
  setCurrent_spi,
  setSpi_legend_url,
  current_spi,
  setCurrent_fapar_anomaly,
  current_fapar_anomaly,
  fapar_anomaly_check,
  setFapar_anomaly_check,
  setFapar_anomaly_legend_url,
  fapar_absorbed_check,
  setFapar_absorbed_check,
  base_url,
  current_fapar_absorbed,
  setCurrent_fapar_absorbed,
  setFapar_absorbed_legend_url,
  current_monthly_precipitation_gpcc,
  setCurrent_monthly_precipitation_gpcc,
  monthly_precipitation_check,
  setMonthly_recipitation_check,
  setGpcc_monthly_precipitation_legend_url,
  selected_region,
  setSelected_region,
  setCurrent_sma,
  sma_check,
  setSma_check,
  current_sma,
  setLegend_url_sma,
  setCurrent_cdi,
  cdi_check,
  setCdi_check,
  current_cdi,
  cdi_selected_dates,
  setCdi_selected_dates,
  request_str_modis_ndvi, 
  setRequest_str_modis_ndvi,
 setCurrent_ndvi,
  ndvi_check,
 setNdvi_check,
 koppen_climate_clas_check, 
 setKoppen_climate_clas_check,
 active_admin_boundaries, 
 setActive_admin_boundaries,
 current_protected_areas, setCurrent_protected_areas,
 protetect_areas_check, setProtetect_areas_check,
  
}) => {
  function get_years(object) {
    var years = [];
    object.map((item) => {
      years.push(Object.keys(item)[0]);
    });
    years = years.sort(function (a, b) {
      return a + b;
    });

    return years;
  }

  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  const [selected_spi_dates, setSelected_spi_dates] = useState({
    // year: spi_dates["year"][0],
    // month: spi_dates["month"][0],
    // timescale: spi_dates["timescale"][0],
  });
  // set wms to filter to country
  function get_filtered_wms_spi(spi_current_link, county_code) {
    var spi_link = spi_current_link.split("CQL_FILTER")[0];
    var year_selected = spi_current_link.split("year=")[1];
    var filtered_spi_country = `${spi_link}CQL_FILTER=INTERSECTS(corr_cell, collectGeometries(queryCollection('data_api_adminlevelzero', 'geometry', 'country_code = ''${county_code}'''))) AND year=${year_selected}`;

    setCurrent_spi(filtered_spi_country);
  }

  function get_filtered_wms(
    current_wms_link,
    county_code,
    indicator = "fapar_anomaly"
  ) {
    var wmslink = current_wms_link.split("CQL_FILTER")[0];
    var year_selected = current_wms_link.split("year=")[1];
    var filtered_wms_country = `${wmslink}CQL_FILTER=INTERSECTS(cell, collectGeometries(queryCollection('data_api_adminlevelzero', 'geometry', 'country_code = ''${county_code}'''))) AND year=${year_selected}`;
    if (indicator === "fapar_anomaly") {
      setCurrent_fapar_anomaly({
        fapar_anomaly_geoserver: filtered_wms_country,
      });
    }
    if (indicator === "fapar_absorbed") {
      setCurrent_fapar_absorbed({
        fapar_absorbed_geoserver: filtered_wms_country,
      });
    }
  }

  return (
    <div className="side-panel">
      <CountrySelection
        countries_options={countries_options}
        setCountries_options={setCountries_options}
        setSelected_country={setSelected_country}
        selected_country={selected_country}
        setCurrent_vector={setCurrent_vector}
        setCurrent_spi={setCurrent_spi}
        current_spi={current_spi}
        get_filtered_wms_spi={get_filtered_wms_spi}
        get_filtered_wms={get_filtered_wms}
        current_fapar_anomaly={current_fapar_anomaly}
        base_url={base_url}
        current_fapar_absorbed={current_fapar_absorbed}
        selected_region={selected_region}
        setSelected_region={setSelected_region}
        current_monthly_precipitation_gpcc={current_monthly_precipitation_gpcc}
        setCurrent_monthly_precipitation_gpcc={
          setCurrent_monthly_precipitation_gpcc
        }
        setCurrent_fapar_anomaly={setCurrent_fapar_anomaly}
        setCurrent_fapar_absorbed={setCurrent_fapar_absorbed}
        current_sma={current_sma}
        setCurrent_sma={setCurrent_sma}
        current_cdi={current_cdi}
        setCurrent_cdi={setCurrent_cdi}
        active_admin_boundaries={active_admin_boundaries}
        setActive_admin_boundaries={setActive_admin_boundaries}
      />
      <Indicators
        rs_data={rs_data}
        precipitation_check={precipitation_check}
        setPrecipitation_check={setPrecipitation_check}
        setCurrent_spi={setCurrent_spi}
        setSpi_legend_url={setSpi_legend_url}
        months={months}
        selected_spi_dates={selected_spi_dates}
        setSelected_spi_dates={setSelected_spi_dates}
        get_filtered_wms_spi={get_filtered_wms_spi}
        selected_country={selected_country}
        get_years={get_years}
        setCurrent_fapar_anomaly={setCurrent_fapar_anomaly}
        current_fapar_anomaly={current_fapar_anomaly}
        fapar_anomaly_check={fapar_anomaly_check}
        setFapar_anomaly_check={setFapar_anomaly_check}
        get_filtered_wms={get_filtered_wms}
        setFapar_anomaly_legend_url={setFapar_anomaly_legend_url}
        fapar_absorbed_check={fapar_absorbed_check}
        setFapar_absorbed_check={setFapar_absorbed_check}
        base_url={base_url}
        current_fapar_absorbed={current_fapar_absorbed}
        setCurrent_fapar_absorbed={setCurrent_fapar_absorbed}
        setFapar_absorbed_legend_url={setFapar_absorbed_legend_url}
        monthly_precipitation_check={monthly_precipitation_check}
        setMonthly_recipitation_check={setMonthly_recipitation_check}
        setCurrent_monthly_precipitation_gpcc={
          setCurrent_monthly_precipitation_gpcc
        }
        setGpcc_monthly_precipitation_legend_url={
          setGpcc_monthly_precipitation_legend_url
        }
        selected_region={selected_region}
        setCurrent_sma={setCurrent_sma}
        sma_check={sma_check}
        setSma_check={setSma_check}
        setLegend_url_sma={setLegend_url_sma}
        setCurrent_cdi={setCurrent_cdi}
        cdi_check={cdi_check}
        setCdi_check={setCdi_check}
        cdi_selected_dates={cdi_selected_dates}
        setCdi_selected_dates={setCdi_selected_dates}
        request_str_modis_ndvi={request_str_modis_ndvi}
        setRequest_str_modis_ndvi={setRequest_str_modis_ndvi}
        setCurrent_ndvi={setCurrent_ndvi}
        ndvi_check={ndvi_check}
        setNdvi_check={setNdvi_check}
        koppen_climate_clas_check={koppen_climate_clas_check} 
        setKoppen_climate_clas_check={setKoppen_climate_clas_check}
        current_protected_areas={current_protected_areas}
        setCurrent_protected_areas={setCurrent_protected_areas}
        protetect_areas_check={protetect_areas_check}
        setProtetect_areas_check={setProtetect_areas_check}
      />
    </div>
  );
};

export default SidePanel;
