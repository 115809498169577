import "../styles/IndicatorsStyle.css";

import { useState, useEffect } from "react";
import info from "../../images/info.svg";
import { filter_wms_by_boundary } from "./SidePanel";
import {get_custom_list ,get_num_withzero} from "./Indicators"
import {get_data} from "../Geoportal"
import DroughtIndicatorModal from "./DroughtIndicatorModal"

export function get_latest_dekads(latest_dekad) {
  var list_dekads = [];
  var increment = 10;
  for (var i = 1; i <= latest_dekad; i += increment) {
    list_dekads.push(i);
  }
  const dekadListWithLeadingZeros = list_dekads.map((num) => {
    return num.toString().padStart(2, "0");
  });

  return dekadListWithLeadingZeros;
}

const Vegetation = ({
  get_select_element,
  customoptions,
  ndvi_check,
  setNdvi_check,
  rs_data,
  get_years,
  months,
  setCurrent_fapar_anomaly,
  fapar_anomaly_check,
  setFapar_anomaly_check,
  selected_country,
  get_filtered_wms,
  setFapar_anomaly_legend_url,
  fapar_absorbed_check,
  setFapar_absorbed_check,
  base_url,
  setCurrent_fapar_absorbed,
  setFapar_absorbed_legend_url,
  selected_region,
  request_str_modis_ndvi,
  setRequest_str_modis_ndvi,
  setCurrent_ndvi
  

}) => {
  const [fapar_anomaly_dates, setFapar_anomaly_dates] = useState({
    year: [2020, 2019],
    month: ["Nov", "Oct", "Sept"],
    dekad: ["1st", "2nd", "3rd"],
  });
  const [fapar_anormaly_latest_dates, setFaparAnormalyLatestDates] = useState(null);
  const [fapar_absorbedl_atest_dates, setFaparAbsorbedLatest] = useState(null);
  const [ndvi_dates, setNdvi_dates] = useState({
    year: [2023, 2022, 2021],
    month: ["Oct", "Sept"],
  });
  const vegetation_data = {
    year: [2020, 2019],
    month: ["Nov", "Oct", "Sept"],
    dekad: ["1st", "2nd", "3rd"],
  };
  const [fapar_anomaly_selected_dates, setFapar_anomaly_selected_dates] =
    useState({
      year: vegetation_data["year"][0],
      month: vegetation_data["month"][0],
      dekad: vegetation_data["dekad"][0],
    });

  /// fapar absorbed ///
  const [fapar_absorbed_dates, setFapar_absorbed_dates] = useState({
    year: [2020, 2019],
    month: ["Nov", "Oct", "Sept"],
    dekad: ["1st", "2nd", "3rd"],
  });

  const [fapar_absorbed_selected_dates, setFapar_absorbed_selected_dates] =
    useState({
      year: fapar_absorbed_dates["year"][0],
      month: fapar_absorbed_dates["month"][0],
      dekad: fapar_absorbed_dates["dekad"][0],
    });

  // utilities
  var months_list = Object.keys(months);

  var dekads_names = { "01": "1st", 11: "2nd", 21: "3rd" };

  var list_dekads = { "1st": "01", "2nd": "11", "3rd": "21" };

  var geoserver = `${base_url}/geoserver/wms?`;
  var maplibre_str =
    "bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=";
  var layer_name_fapar_anomaly =
    "africa_drought_datasets:grid_083dd_fapar_anomaly_";
  var layer_name_fapar_absorbed =
    "africa_drought_datasets:grid_083dd_fapar_absorbed_";

  function getMonthName(monthNumber) {
    return months_list[monthNumber - 1];
  }


  const ndvi_gee_wms = async (url)=>{
    var ndvi = await get_data(url)

    setCurrent_ndvi(ndvi['image_url'])
    // console.log(ndvi, "ndvi['image_url']");
  }

  function handle_selected_data(array_selection, pre_selected) {
    if (array_selection.includes(pre_selected)) {
      return pre_selected;
    } else {
      return array_selection[0];
    }
  }

  // filter wms by country or region
  function handle_wmslink(wms_link, cell_column) {
    if (selected_country !== null) {
      wms_link = filter_wms_by_boundary(
        wms_link,
        selected_country["country_id"],
        cell_column,
        1
      );
      return wms_link;
    }
    if (selected_region === null && selected_country === null) {
      return wms_link;
    }
    if (selected_region !== null && selected_country === null) {
      wms_link = filter_wms_by_boundary(
        wms_link,
        selected_region,
        cell_column,
        0
      );
      return wms_link;
    }
  }

  // filter region request str modis
  function get_modis_ndvi_requestStr(){
    if (selected_country !== null) {
     var request_str = `${base_url}/data_api/rs_data/get_rs_data?region=country&code=${selected_country["country_id"]}`
     return request_str
    }
    if (selected_region === null && selected_country === null) {
      return `${base_url}/data_api/rs_data/get_rs_data?region=continent&code=0}`
    }
    if (selected_region !== null && selected_country === null) {
      return `${base_url}/data_api/rs_data/get_rs_data?region=region&code=${selected_region}`;
    }
  
  }

  function get_dekad_name(dekad) {
    return dekads_names[dekad];
  }

  function set_innitial_selected(data) {
    // === fapar anamaly
    function set_fapar_anom_initial(fapar_data) {
      var start_year_fpar_anom = fapar_data?.start_year
      var latest_year_fpar_anom = fapar_data?.latest_year
      var latest_month_fpar_anom = fapar_data?.latest_month
      var latest_dekad_fpar_anom = fapar_data?.latest_dekad
      var years_ = get_custom_list(start_year_fpar_anom, latest_year_fpar_anom);

      latest_dekad_fpar_anom = get_num_withzero(latest_dekad_fpar_anom)

      var months_fpar_anom = get_custom_list(1, latest_month_fpar_anom);
      var dekads_fpar_anom = get_custom_list(1, latest_dekad_fpar_anom, 10);
      var months_fpar_anom_names = months_fpar_anom.map(getMonthName);
      var dekads_fpar_anom_names = dekads_fpar_anom.map(get_dekad_name);
      var month_fpar_anom_ = getMonthName(latest_month_fpar_anom);
      var dekad_fpar_anom_ = get_dekad_name(latest_dekad_fpar_anom);
      setFapar_anomaly_selected_dates({
        year: latest_year_fpar_anom.toString(),
        month: month_fpar_anom_,
        dekad: get_dekad_name(latest_dekad_fpar_anom),
      });

      setFaparAnormalyLatestDates({
        year: latest_year_fpar_anom,
        month: latest_month_fpar_anom,
        dekad: fapar_data?.latest_dekad,
      });

      setFapar_anomaly_dates({
        year: years_,
        month: months_fpar_anom_names,
        dekad: dekads_fpar_anom_names,
      });

      var fapar_anomaly_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_anomaly}${months[month_fpar_anom_]}${list_dekads[dekad_fpar_anom_]}_view&CQL_FILTER=year=${latest_year_fpar_anom}`;

      var legend_url = `${geoserver}REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layer_name_fapar_anomaly}${months[month_fpar_anom_]}${list_dekads[dekad_fpar_anom_]}_view&LEGEND_OPTIONS=border:true;dx:10;fontSize:11;`;

      setFapar_anomaly_legend_url(legend_url);
      fapar_anomaly_geoserver = handle_wmslink(
        fapar_anomaly_geoserver,
        "cell"
      );
      setCurrent_fapar_anomaly({
        fapar_anomaly_geoserver: fapar_anomaly_geoserver,
      });    

    
    }

    function set_fapar_absorb_initial(fapar_data) {

      var start_year_fpar_absorbed = fapar_data?.start_year
      var latest_year_fpar_absorbed = fapar_data?.latest_year
      var latest_month_fpar_absorbed = fapar_data?.latest_month
      var latest_dekad_fpar_absorbed = fapar_data?.latest_dekad
      var years_ = get_custom_list(start_year_fpar_absorbed, latest_year_fpar_absorbed);

      var months_fpar_absorbed = get_custom_list(1, latest_month_fpar_absorbed);
      var dekads_fpar_absorbed = get_custom_list(1, latest_dekad_fpar_absorbed, 10);
      var months_fpar_absorbed_names = months_fpar_absorbed.map(getMonthName);
      var dekads_fpar_absorbed_names = dekads_fpar_absorbed.map(get_dekad_name);
      var month_fpar_absorbed_ = getMonthName(latest_month_fpar_absorbed);
      // var dekad_fpar_absorbed_ = get_dekad_name(latest_dekad_fpar_absorbed);
      latest_dekad_fpar_absorbed = get_num_withzero(latest_dekad_fpar_absorbed)
      latest_dekad_fpar_absorbed = get_dekad_name(latest_dekad_fpar_absorbed)
      setFapar_absorbed_selected_dates({
        year: latest_year_fpar_absorbed.toString(),
        month: month_fpar_absorbed_,
        dekad: latest_dekad_fpar_absorbed,
      });

      setFapar_absorbed_dates({
        year: years_,
        month: months_fpar_absorbed_names,
        dekad: dekads_fpar_absorbed_names,  
      });

      setFaparAbsorbedLatest({
        year: latest_year_fpar_absorbed,
        month: latest_month_fpar_absorbed,
        dekad: fapar_data?.latest_dekad,
      });

      var fapar_absorbed_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_absorbed}${months[month_fpar_absorbed_]}${list_dekads[latest_dekad_fpar_absorbed]}_view&CQL_FILTER=year=${latest_year_fpar_absorbed}`;

      var legend_url = `${geoserver}REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${layer_name_fapar_absorbed}${months[month_fpar_absorbed_]}${list_dekads[latest_dekad_fpar_absorbed]}_view&LEGEND_OPTIONS=border:true;dx:10;fontSize:11;`;

      setFapar_absorbed_legend_url(legend_url);
      fapar_absorbed_geoserver = handle_wmslink(
        fapar_absorbed_geoserver,
        "cell"
      );
      setCurrent_fapar_absorbed({
        fapar_absorbed_geoserver: fapar_absorbed_geoserver,
      });
    
    }

    set_fapar_anom_initial(data?.fapar_anomaly);
    set_fapar_absorb_initial(data?.fapar_absorbed);
  }

  // NDVI //
  const [NDVISelectedDates, setNDVISelectedDates] = useState({
    year: vegetation_data["year"][0],
    month: vegetation_data["month"][0],
  });

  function getLastDayOfMonth(year, month) {
    // Convert month to zero-index by subtracting 1 (since in the input, 01 = January, 02 = February, etc.)
    month = parseInt(month, 10) - 1;
    
    // Create a date object for the next month's first day, then subtract one to get the last day of the given month
    return new Date(year, month + 1, 0).getDate();
  }

  function set_monthly_ndvi(data) {
    var start_year = data["start_year"];
    var end_year = data["end_year"];
    var start_month = data["start_month"];
    var latest_month = data["latest_month"];
    var end_month = data["end_month"];
    var years = get_custom_list(start_year, end_year);
    var months = get_custom_list(start_month, latest_month);
    var _full_months = get_custom_list(start_month, end_month);
    var selected_month = getMonthName(latest_month);
    // var ndvi_request_str_ = get_modis_ndvi_requestStr()

     months = months.map(getMonthName);
    _full_months = _full_months.map(getMonthName);
    setNdvi_dates({
      year: years,
      month: months,
      current_year: end_year,
      full_months: _full_months,
      current_year_months: months,
    });
    setNDVISelectedDates({
      year: end_year,
      month: selected_month,
    });

    if (request_str_modis_ndvi !==null){
      var ndvi_request_str_ = request_str_modis_ndvi;
      ndvi_request_str_ = `${ndvi_request_str_}&year=${end_year}&month=${get_num_withzero(latest_month)}`
  
      ndvi_gee_wms(ndvi_request_str_)
    }

 
  }

  
  

  useEffect(() => {
    if (rs_data !== null) {
      // var fapar_anomaly_years = get_years(rs_data["fapar_anomaly"]);
      // var fapar_absorbed_years = get_years(rs_data["fapar_absorbed"]);

      setRequest_str_modis_ndvi(get_modis_ndvi_requestStr())

      // setFapar_anormaly_years(fapar_anomaly_years);
      // setFapar_absorbed_years(fapar_absorbed_years);
      set_innitial_selected(rs_data);
      const modis_data = rs_data["modis"];
    
     
      set_monthly_ndvi(modis_data);
    }
  }, [rs_data]);

  useEffect(() => {}, [fapar_anomaly_selected_dates, fapar_anomaly_dates]);

  useEffect(() => {
    if (request_str_modis_ndvi !== null) {

       var ndvi_str = get_modis_ndvi_requestStr()
       setRequest_str_modis_ndvi(ndvi_str)
       var selected_year_ = NDVISelectedDates["year"]
       var selected_month_ = months[NDVISelectedDates["month"]]
        var last_day = getLastDayOfMonth(selected_year_, selected_month_)
       var start_date = `${NDVISelectedDates["year"]}-${months[NDVISelectedDates["month"]]}-01`
       var end_date = `${NDVISelectedDates["year"]}-${months[NDVISelectedDates["month"]]}-${last_day}`
     var modified_request_str = `${ndvi_str}&start_date=${start_date}&end_date=${end_date}`
      // console.log(modified_request_str, "modified_request_str");
      ndvi_gee_wms(modified_request_str)
      
    }
  }, [selected_country, selected_region, NDVISelectedDates]);

  // onchenge functions //

  const oncheck_fapar_anomaly_check = () => {
    setFapar_anomaly_check(!fapar_anomaly_check);
  };

  const oncheck_fapar_absorbed = () => {
    setFapar_absorbed_check(!fapar_absorbed_check);
  };

  const oncheck_ndvi = () => {
    setNdvi_check(!ndvi_check);
  };

  function get_available_months_dekads(year_selected, data) {
    var available_months = [];
    var available_dekads = [];

    var latest_dekad = data?.latest_dekad;
    var latest_month = data?.latest_month;
    var latest_year = data?.latest_year;

    if (parseInt(year_selected) === parseInt(latest_year)) {
      available_months = get_custom_list(1, latest_month).map(getMonthName);
      available_dekads = get_custom_list(1,latest_dekad, 10).map(get_dekad_name);
    }else{
      available_months = get_custom_list(1, 12).map(getMonthName);
      available_dekads = get_custom_list(1,21,10).map(get_dekad_name);
    }

   

    return { months: available_months, dekads: available_dekads };
  }

  const onchange_year_fapar = (e) => {
    var available_months_dekads = get_available_months_dekads(
      e.value,
      rs_data["fapar_anomaly"]
    );

    // available_months_dekads["months"]

    setFapar_anomaly_dates({
      year: fapar_anomaly_dates["year"],
      month: available_months_dekads["months"],
      dekad: available_months_dekads["dekads"],
    });

    var dekad_selected = handle_selected_data(
      available_months_dekads["dekads"],
      fapar_anomaly_selected_dates["dekad"]
    );
    var month_selected = handle_selected_data(
      available_months_dekads["months"],
      fapar_anomaly_selected_dates["month"]
    );
    setFapar_anomaly_selected_dates({
      year: e.value,
      month: month_selected,
      dekad: dekad_selected,
    });
    var fapar_anomaly_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_anomaly}${months[month_selected]}${list_dekads[dekad_selected]}_view&CQL_FILTER=year=${e.value}`;
    // modify_wms_link(fapar_anomaly_geoserver);
    fapar_anomaly_geoserver = handle_wmslink(fapar_anomaly_geoserver, "cell");
    setCurrent_fapar_anomaly({
      fapar_anomaly_geoserver: fapar_anomaly_geoserver,
    });
  };
  const onchange_month_fapar = (e) => {
    setFapar_anomaly_selected_dates({
      year: fapar_anomaly_selected_dates["year"],
      month: e.value,
      dekad: fapar_anomaly_selected_dates["dekad"],
    });

    var fapar_anomaly_dekads = fapar_anomaly_dates["dekad"];

    if (fapar_anormaly_latest_dates !== null){
      

      if (parseInt(fapar_anormaly_latest_dates["month"]) !== parseInt(months[e.value], 10)){
        // console.log("fapar_anomaly_latest_dates", fapar_anormaly_latest_dates, e.value, "e.value")
        fapar_anomaly_dekads = get_custom_list(1, 21, 10);
       fapar_anomaly_dekads = fapar_anomaly_dekads.map(get_dekad_name);
    }else {
      fapar_anomaly_dekads = get_custom_list(1, fapar_anormaly_latest_dates["dekad"], 10);
      fapar_anomaly_dekads = fapar_anomaly_dekads.map(get_dekad_name);
    }
  }

    setFapar_anomaly_dates((prevState) => ({
      ...prevState,
      dekad: fapar_anomaly_dekads,
    }));


    var fapar_anomaly_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_anomaly}${
      months[e.value]
    }${
      list_dekads[fapar_anomaly_selected_dates["dekad"]]
    }_view&CQL_FILTER=year=${fapar_anomaly_selected_dates["year"]}`;

    // modify_wms_link(fapar_anomaly_geoserver);
    fapar_anomaly_geoserver = handle_wmslink(fapar_anomaly_geoserver, "cell");
    setCurrent_fapar_anomaly({
      fapar_anomaly_geoserver: fapar_anomaly_geoserver,
    });
  };

  const onchange_dekad_fapar = (e) => {
    setFapar_anomaly_selected_dates({
      year: fapar_anomaly_selected_dates["year"],
      month: fapar_anomaly_selected_dates["month"],
      dekad: e.value,
    });

    var fapar_anomaly_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_anomaly}${
      months[fapar_anomaly_selected_dates["month"]]
    }${list_dekads[e.value]}_view&CQL_FILTER=year=${
      fapar_anomaly_selected_dates["year"]
    }`;
    //
    fapar_anomaly_geoserver = handle_wmslink(fapar_anomaly_geoserver, "cell");
    setCurrent_fapar_anomaly({
      fapar_anomaly_geoserver: fapar_anomaly_geoserver,
    });
  };

  const onchange_year_fapar_absorbed = (e) => {
    // update avaible months and dekads for the year
    var available_months_dekads = get_available_months_dekads(
      e.value,
      rs_data["fapar_absorbed"]
    );


    setFapar_absorbed_dates({
      year: fapar_absorbed_dates["year"],
      month: available_months_dekads["months"],
      dekad: available_months_dekads["dekads"],
    });

    var dekad_selected = handle_selected_data(
      available_months_dekads["dekads"],
      fapar_absorbed_selected_dates["dekad"]
    );
    var month_selected = handle_selected_data(
      available_months_dekads["months"],
      fapar_absorbed_selected_dates["month"]
    );

    setFapar_absorbed_selected_dates({
      year: e.value,
      month: month_selected,
      dekad: dekad_selected,
    });

    var fapar_absorbed_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_absorbed}${months[month_selected]}${list_dekads[dekad_selected]}_view&CQL_FILTER=year=${e.value}`;
    fapar_absorbed_geoserver = handle_wmslink(fapar_absorbed_geoserver, "cell");

    setCurrent_fapar_absorbed({
      fapar_absorbed_geoserver: fapar_absorbed_geoserver,
    });
  };

  const onchange_month_fapar_absorbed = (e) => {
    setFapar_absorbed_selected_dates({
      year: fapar_absorbed_selected_dates["year"],
      month: e.value,
      dekad: fapar_absorbed_selected_dates["dekad"],
    });
    var fapar_absorbed_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_absorbed}${
      months[e.value]
    }${
      list_dekads[fapar_absorbed_selected_dates["dekad"]]
    }_view&CQL_FILTER=year=${fapar_absorbed_selected_dates["year"]}`;

    fapar_absorbed_geoserver = handle_wmslink(fapar_absorbed_geoserver, "cell");
      var fapar_absorbed_dekads = fapar_absorbed_dates?.dekad;
    if (fapar_absorbedl_atest_dates !== null){
      if (parseInt(fapar_absorbedl_atest_dates?.month) !== parseInt(months[e.value], 10)){

        fapar_absorbed_dekads = get_custom_list(1, 21, 10);
        fapar_absorbed_dekads = fapar_absorbed_dekads.map(get_dekad_name);
      }else {
        fapar_absorbed_dekads = get_custom_list(1, fapar_absorbedl_atest_dates?.dekad, 10);
        fapar_absorbed_dekads = fapar_absorbed_dekads.map(get_dekad_name);
      }
    }

    setFapar_absorbed_dates((prevState) => ({
      ...prevState,
      dekad: fapar_absorbed_dekads,
    }));

    setCurrent_fapar_absorbed({
      fapar_absorbed_geoserver: fapar_absorbed_geoserver,
    });
  };
  const onchange_dekad_fapar_absorbed = (e) => {
    setFapar_absorbed_selected_dates({
      year: fapar_absorbed_selected_dates["year"],
      month: fapar_absorbed_selected_dates["month"],
      dekad: e.value,
    });
    var fapar_absorbed_geoserver = `${geoserver}${maplibre_str}${layer_name_fapar_absorbed}${
      months[fapar_absorbed_selected_dates["month"]]
    }${list_dekads[e.value]}_view&CQL_FILTER=year=${
      fapar_absorbed_selected_dates["year"]
    }`;

    fapar_absorbed_geoserver = handle_wmslink(fapar_absorbed_geoserver, "cell");

    setCurrent_fapar_absorbed({
      fapar_absorbed_geoserver: fapar_absorbed_geoserver,
    });
  };
  const onchange_year_ndvi = (e) => {

    var current_year = ndvi_dates["current_year"];

    if (e.value !== current_year) {
      var months_ = ndvi_dates["full_months"];
    setNdvi_dates((prevState) => ({
        ...prevState,
        month: months_,

    }));
  }else{

    var months_ = ndvi_dates["current_year_months"];
    setNdvi_dates((prevState) => ({
        ...prevState,
        month: months_,

    }));
  }
    
    setNDVISelectedDates({
      year: e.value,
      month: NDVISelectedDates["month"],
    });
  };

  const onchange_month_ndvi = (e) => {
    setNDVISelectedDates({
      year: NDVISelectedDates["year"],
      month: e.value,
    });
  };

  const [faparANomalyInfo, setFaparANomalyInfo] = useState(null)
  const [isDroughtModalVisibleFaparAnom, setDroughtModalVisibleFaparAnom] = useState(false);
  const [IsLoadingFaparAnomalyInfo, setIsLoadingFaparAnomalyInfo] = useState(false);

  const handleInfoClickFaparAnomaly = () => {
    setDroughtModalVisibleFaparAnom(!isDroughtModalVisibleFaparAnom);
  
    if (!isDroughtModalVisibleFaparAnom) { 
      setIsLoadingFaparAnomalyInfo(true); 
      const url = `${base_url}/api/metadata/?dataset_id=fparan`;
      get_data(url).then((data) => {
        setFaparANomalyInfo(data[0]);
        setIsLoadingFaparAnomalyInfo(false);
      }).catch(error => {
        console.error('Failed to fetch fapar anomaly info:', error);
        setIsLoadingFaparAnomalyInfo(false); 
      });
    }
  };

  // fapar absobed info
  const [faparAbsorbedInfo, setFaparAbsorbedInfo] = useState(null)
  const [isDroughtModalVisibleFaparAbs, setDroughtModalVisibleFaparAbs] = useState(false);
  const [IsLoadingFaparAbsorbedInfo, setIsLoadingFaparAbsorbedInfo] = useState(false);

  const handleInfoClickFaparAbsorbed = () => {
    setDroughtModalVisibleFaparAbs(!isDroughtModalVisibleFaparAbs);
  
    if (!isDroughtModalVisibleFaparAbs) { 
      setIsLoadingFaparAbsorbedInfo(true); 
      const url = `${base_url}/api/metadata/?dataset_id=fparabs`;
      get_data(url).then((data) => {
        setFaparAbsorbedInfo(data[0]);
        setIsLoadingFaparAbsorbedInfo(false);
      }).catch(error => {
        console.error('Failed to fetch fapar absorbed info:', error);
        setIsLoadingFaparAbsorbedInfo(false); 
      });
    }
  }

  // ndvi info
  const [ndviInfo, setNdviInfo] = useState(null)
  const [isDroughtModalVisibleNdvi, setDroughtModalVisibleNdvi] = useState(false);
  const [IsLoadingNdviInfo, setIsLoadingNdviInfo] = useState(false);

  const handleInfoClickNdvi = () => {
    setDroughtModalVisibleNdvi(!isDroughtModalVisibleNdvi);
  
    if (!isDroughtModalVisibleNdvi) { 
      setIsLoadingNdviInfo(true); 
      const url = `${base_url}/api/metadata/?dataset_id=ndvi`;
      get_data(url).then((data) => {
        setNdviInfo(data[0]);
        setIsLoadingNdviInfo(false);
      }).catch(error => {
        console.error('Failed to fetch ndvi info:', error);
        setIsLoadingNdviInfo(false); 
      });
    }
  }



  return (
    <div className="vegetation-module">
      <p className="Indicator-names">VEGETATION INDICATORS</p>

      <div className="info-subsection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={fapar_anomaly_check}
            onChange={oncheck_fapar_anomaly_check}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">fAPAR Anormaly</p>
        <img className="info-icon-fapar-a" src={info} alt="info" onClick={handleInfoClickFaparAnomaly}/>

        {isDroughtModalVisibleFaparAnom && (
  <>
        <DroughtIndicatorModal
        dataset={faparANomalyInfo}
        isActive={isDroughtModalVisibleFaparAnom}
        toggleModal={handleInfoClickFaparAnomaly}
        isLoading={IsLoadingFaparAnomalyInfo}
        />

  </>
)}
      </div>

      {fapar_anomaly_check ? (
        <p
          style={{
            position: "relative",
            color: "#000000",
            fontSize: "10px",
            left: "50px",
            marginTop: "40px",
            marginBottom: "-15px",
          }}
        >
          {fapar_anomaly_selected_dates["year"]}{" "}
          {fapar_anomaly_selected_dates["month"] +
            " Dekad : " +
            fapar_anomaly_selected_dates["dekad"]}{" "}
        </p>
      ) : (
        <></>
      )}

      <div className="cdi-selection">
        {get_select_element(
          customoptions,
          fapar_anomaly_dates?.year,
          onchange_year_fapar,
          fapar_anomaly_selected_dates?.year,
          fapar_anomaly_check
        )}
        {get_select_element(
          customoptions,
          fapar_anomaly_dates?.month,
          onchange_month_fapar,
          fapar_anomaly_selected_dates?.month,
          fapar_anomaly_check
        )}

        {get_select_element(
          customoptions,
          fapar_anomaly_dates?.dekad,
          onchange_dekad_fapar,
          fapar_anomaly_selected_dates?.dekad,
          fapar_anomaly_check
        )}
      </div>

      {/* fapar absorbed ========================== */}
      <div className="monthly-cdi">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={fapar_absorbed_check}
            onChange={oncheck_fapar_absorbed}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description" style={{ marginLeft: "80px" }}>
          fAPAR Absorbed
        </p>
        <img
          src={info}
          alt="info"
          style={{ left: "32%", position: "relative" }}
          // className="info-icon-fapar-a"
          onClick={handleInfoClickFaparAbsorbed}
        />

        {isDroughtModalVisibleFaparAbs && (
  <>
        <DroughtIndicatorModal

        dataset={faparAbsorbedInfo}
        isActive={isDroughtModalVisibleFaparAbs}
        toggleModal={handleInfoClickFaparAbsorbed}
        isLoading={IsLoadingFaparAbsorbedInfo}
        />

  </>
)}

        {fapar_absorbed_check ? (
          <p
            style={{
              position: "relative",
              color: "#000000",
              fontSize: "10px",
              left: "50px",
              marginTop: "4px",
              marginBottom: "-15px",
            }}
          >
            {fapar_absorbed_selected_dates?.year}{" "}
            {fapar_absorbed_selected_dates?.month +
              " Dekad : " +
              fapar_absorbed_selected_dates["dekad"]}
          </p>
        ) : (
          <></>
        )}
        {/* year */}
        <div className="cdi-monthly-selection">
          {get_select_element(
            customoptions,
            fapar_absorbed_dates["year"],
            onchange_year_fapar_absorbed,
            fapar_absorbed_selected_dates["year"],
            fapar_absorbed_check
          )}
          {/* month */}
          {get_select_element(
            customoptions,
            fapar_absorbed_dates["month"],
            onchange_month_fapar_absorbed,
            fapar_absorbed_selected_dates["month"],
            fapar_absorbed_check
          )}

          {/* dekad */}

          {get_select_element(
            customoptions,
            fapar_absorbed_dates["dekad"],
            onchange_dekad_fapar_absorbed,
            fapar_absorbed_selected_dates["dekad"],
            fapar_absorbed_check
          )}
        </div>
      </div>

      <div className="sub-section-selection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={ndvi_check}
            onChange={oncheck_ndvi}
            disabled={false}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description" style={{ marginLeft: "80px" }}>
          Normalized Difference Vegetation Index
        </p>
        <img className="info-icon-monthly-ndvi" src={info} alt="info"
        onClick={handleInfoClickNdvi} 
         />

        {isDroughtModalVisibleNdvi && (
  <>
        <DroughtIndicatorModal

        dataset={ndviInfo}
        isActive={isDroughtModalVisibleNdvi}
        toggleModal={handleInfoClickNdvi}
        isLoading={IsLoadingNdviInfo}
        />
        </>
)}


        {ndvi_check ? (
          <p
            style={{
              position: "relative",
              color: "#000000",
              fontSize: "10px",
              left: "50px",
              marginTop: "4px",
              marginBottom: "-15px",
            }}
          >
            {NDVISelectedDates["year"]}{" "}
            {NDVISelectedDates["month"]}
          </p>
        ) : (
          <></>
        )}

        <div className="cdi-monthly-selection">
          {get_select_element(
            customoptions,
            ndvi_dates["year"],
            onchange_year_ndvi,
            NDVISelectedDates["year"],
            ndvi_check
          )}

          {get_select_element(
            customoptions,
            ndvi_dates["month"],
            onchange_month_ndvi,
            NDVISelectedDates["month"],
            ndvi_check
          )}

       
        </div>
      </div>
    </div>
  );
};

export default Vegetation;
