import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";

import { Chart } from "react-chartjs-2";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: true,
  plugins: {
    title: {
      display: true,
      // text: "Pixel time series",
    },
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 20,
        boxHeight: 20,
      },
      font: {
        size: 8, // Adjust as needed
      },
    },
  },
  scales: {
    y: {
      type: "linear",
      display: false,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
        display: false,
      },
      x: {
        ticks: {
          font: {
            size: 10, // adjust the font size as needed
          },
        },
      },
    },
  },
};
const MixedChart = ({ ref, data }) => {
  return (
    <div className="charts-card">
      {data && <Chart type="bar" ref={ref} data={data} options={options} />}
    </div>
  );
};
export default MixedChart;
