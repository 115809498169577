import "../styles/IndicatorsStyle.css";
import { useState, useEffect } from "react";
import Select from "react-select";

import info from "../../images/info.svg";

import {
  handle_wmslink,
  handle_selected_data,
  getMonthName,
} from "../sidepane/Precipitation";
import Border from "./Border";
import Precipitation from "./Precipitation";
import Vegetation from "./Vegetation";
import Soil from "./Soil";
import GeographicBackground from "./GeographicBackground";
import ProtectedAreas from "./ProtectedAreas";
import DroughtIndicatorModal from "./DroughtIndicatorModal";

import {get_data} from "../Geoportal"

export function get_custom_list(first_value, last_value, increment = 1) {
  var custom_list = [];
  for (var i = first_value; i <= last_value; i += increment) {
    custom_list.push(i);
  }
  const ListWithLeadingZeros = custom_list.map((num) => {
    return num.toString().padStart(2, "0");
  });

  return ListWithLeadingZeros;
}

export function get_num_withzero(num) {
  return num.toString().padStart(2, "0");
}

const Indicators = ({
  rs_data,
  precipitation_check,
  setPrecipitation_check,
  setCurrent_vector,
  setCurrent_spi,
  setSpi_legend_url,
  months,
  selected_spi_dates,
  setSelected_spi_dates,
  get_filtered_wms_spi,
  selected_country,
  get_years,
  setCurrent_fapar_anomaly,
  current_fapar_anomaly,
  fapar_anomaly_check,
  setFapar_anomaly_check,
  get_filtered_wms,
  setFapar_anomaly_legend_url,
  fapar_absorbed_check,
  setFapar_absorbed_check,
  base_url,
  current_fapar_absorbed,
  setCurrent_fapar_absorbed,
  setFapar_absorbed_legend_url,
  monthly_precipitation_check,
  setMonthly_recipitation_check,
  setCurrent_monthly_precipitation_gpcc,
  setGpcc_monthly_precipitation_legend_url,
  selected_region,
  setCurrent_sma,
  sma_check,
  setSma_check,
  setLegend_url_sma,
  setCurrent_cdi,
  cdi_check,
  setCdi_check,
  cdi_selected_dates,
  setCdi_selected_dates,
  request_str_modis_ndvi,
  setRequest_str_modis_ndvi,
   setCurrent_ndvi,
    ndvi_check,
    setNdvi_check,
    koppen_climate_clas_check, 
    setKoppen_climate_clas_check,
    current_protected_areas,
    setCurrent_protected_areas,
    protetect_areas_check, setProtetect_areas_check
}) => {
  const [cdi_dates, setCdi_dates] = useState({
    year: [2020, 2023],
    month: ["Nov", "Oct", "Sept"],
    dekad: ["1st", "2nd", "3rd"],
  });


  const [isDroughtModalVisible, setDroughtModalVisible] = useState(false);

  const [cdi_latest_dates, setCdi_latest_dates] = useState(null);
  const [cdi_info, setCdi_info] = useState(null);
  const [isLoadingCdiInfo, setIsLoadingCdiInfo] = useState(false);


  // vegetation props


  // geo bg props
  const [temp_class_check, setTemp_class_check] = useState(false);
  

  // utilities
  var months_list = Object.keys(months);
  var months_obj = months;

  var dekads_names = { "01": "1st", 11: "2nd", 21: "3rd" };

  var list_dekads = { "1st": "01", "2nd": "11", "3rd": "21" };

  var geoserver = `${base_url}/geoserver/wms?`;
  var maplibre_str =
    "bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=";
  var layer_name_cdi = "africa_drought_datasets:grid_01dd_cdi_";

  

  function get_dekad_name(dekad) {
    return dekads_names[dekad];
  }

  const handleInfoClick = () => {
    setDroughtModalVisible(!isDroughtModalVisible);
  
    if (!isDroughtModalVisible) { // Only fetch data if the modal is not already visible
      setIsLoadingCdiInfo(true); // Set loading state to true before fetching
      const url = `${base_url}/api/metadata/?dataset_id=cdi`;
      get_data(url).then((data) => {
        setCdi_info(data[0]);
        setIsLoadingCdiInfo(false); // Set loading state to false after fetching
      }).catch(error => {
        console.error('Failed to fetch CDI info:', error);
        setIsLoadingCdiInfo(false); // Ensure loading state is set to false even if there's an error
      });
    }
  };
  
  

  function set_initial_data(rs_data) {
    if (rs_data.hasOwnProperty("cdi")) {
      var cdi_data_from_api = rs_data.cdi;
      var latest_year = cdi_data_from_api.latest_year;
      var start_year = cdi_data_from_api.start_year;
      var latest_month = cdi_data_from_api.latest_month;
      var latest_dekad = cdi_data_from_api['latest_dekad'];

      var years = get_custom_list(start_year, latest_year);
      var months = get_custom_list(1, latest_month);
      var dekads = get_custom_list(1, latest_dekad, 10);
      var month_names = months.map(getMonthName);
      var dekad_names = dekads.map(get_dekad_name);

      var _month = get_num_withzero(latest_month);
      var _dekad = get_num_withzero(latest_dekad);

      var cdi_wms_url = `${geoserver}${maplibre_str}${layer_name_cdi}${_month}${_dekad}_view&CQL_FILTER=year=${latest_year}`;

      var cdi_wms_handled = handle_wmslink(
        cdi_wms_url,
        "cell",
        selected_country,
        selected_region
      );

      setCurrent_cdi({ cdi_geoserver: cdi_wms_handled });
      latest_dekad = get_num_withzero(latest_dekad)

      setCdi_selected_dates({
        year: latest_year,
        month: getMonthName(latest_month),
        dekad: get_dekad_name(latest_dekad),
      });

      setCdi_latest_dates({
        latest_year: latest_year,
        latest_month: latest_month,
        latest_dekad: latest_dekad,
      });

      setCdi_dates({
        year: years,
        month: month_names,
        dekad: dekad_names,
      });
    }
  }

  useEffect(() => {
    // setCurrent_cdi();
    if (rs_data !== null) {
      set_initial_data(rs_data);
    }
  }, [rs_data]);

  //    Onchange functions  ==========
  const oncheck_cdi = () => {
    setCdi_check(!cdi_check);
  };

  const onchange_year_cdi = (e) => {
    var months = cdi_dates["month"];
    var dekads = cdi_dates["dekad"];

    if (cdi_latest_dates !== null) {
      if (
        parseInt(e.value, 10) !== parseInt(cdi_latest_dates["latest_year"], 10)
      ) {
        months = get_custom_list(1, 12);

        dekads = get_custom_list(1, 21, 10);

        months = months.map(getMonthName);
        dekads = dekads.map(get_dekad_name);
      } else {
        months = get_custom_list(1, cdi_latest_dates["latest_month"]);

        dekads = get_custom_list(1, cdi_latest_dates["latest_dekad"], 10);

        months = months.map(getMonthName);
        dekads = dekads.map(get_dekad_name);
      }
    }

    var selected_month = handle_selected_data(
      months,
      cdi_selected_dates["month"]
    );
    var selected_dekad = handle_selected_data(
      dekads,
      cdi_selected_dates["dekad"]
    );

    var cdi_geoserver_wms = `${geoserver}${maplibre_str}${layer_name_cdi}${months_obj[selected_month]}${list_dekads[selected_dekad]}_view&CQL_FILTER=year=${e.value}`;

    cdi_geoserver_wms = handle_wmslink(
      cdi_geoserver_wms,
      "cell",
      selected_country,
      selected_region
    );
    setCurrent_cdi({ cdi_geoserver: cdi_geoserver_wms });

    setCdi_selected_dates({
      year: e.value,
      month: selected_month,
      dekad: selected_dekad,
    });

    setCdi_dates((prevState) => ({
      ...prevState,
      month: months,
      dekad: dekads,
    }));
  };

  const onchange_month_cdi = (e) => {
    var cdi_geoserver_wms = `${geoserver}${maplibre_str}${layer_name_cdi}${
      months_obj[e.value]
    }${list_dekads[cdi_selected_dates["dekad"]]}_view&CQL_FILTER=year=${
      cdi_selected_dates["year"]
    }`;

    cdi_geoserver_wms = handle_wmslink(
      cdi_geoserver_wms,
      "cell",
      selected_country,
      selected_region
    );
    var dekads = cdi_dates["dekad"];
    
    if (cdi_latest_dates !== null) {
      if (
        parseInt(e.value, 10) !== parseInt(cdi_latest_dates["latest_month"], 10)
      ) {

        dekads = get_custom_list(1, 21, 10);

       
        dekads = dekads.map(get_dekad_name);
      } else {
     

        dekads = get_custom_list(1, cdi_latest_dates["latest_dekad"], 10);

  
        dekads = dekads.map(get_dekad_name);
      }
    }

    var selected_dekad = handle_selected_data(
      dekads,
      cdi_selected_dates["dekad"]
    );


    setCurrent_cdi({ cdi_geoserver: cdi_geoserver_wms });
    setCdi_dates((prevState) => ({
      ...prevState,
      dekad: dekads,
    }));

    setCdi_selected_dates({
      year: cdi_selected_dates["year"],
      month: e.value,
      dekad: selected_dekad,
    });
  };
  const onchange_dekad_cdi = (e) => {
    var cdi_geoserver_wms = `${geoserver}${maplibre_str}${layer_name_cdi}${
      months_obj[cdi_selected_dates["month"]]
    }${list_dekads[e.value]}_view&CQL_FILTER=year=${
      cdi_selected_dates["year"]
    }`;

    cdi_geoserver_wms = handle_wmslink(
      cdi_geoserver_wms,
      "cell",
      selected_country,
      selected_region
    );

    setCurrent_cdi({ cdi_geoserver: cdi_geoserver_wms });

    setCdi_selected_dates({
      year: cdi_selected_dates["year"],
      month: cdi_selected_dates["month"],
      dekad: e.value,
    });
  };

  const customoptions = (anArray) => {
    // returns the options for selection object
    var opt = anArray.map((item) => {
      return { value: item, label: item };
    });
    return opt;
  };

  // set wms to filter to country

  const get_select_element = (
    customoptions,
    array_options,
    onChange,
    placeholder,
    state = false,
    disable = false
  ) => {
    if (state !== false) {
      return (
        <Select
          className="select-region"
          classNamePrefix="select-controls-indicators"
          options={customoptions(array_options)}
          placeholder={placeholder}
          value={placeholder}
          //   value = 'Select Country'
          onChange={onChange}
          // onSelectResetsInput={true}
          isSearchable={true}
          isDisabled={disable}
        ></Select>
      );
    }
  };

  useEffect(() => {}, [
    cdi_check,
    cdi_selected_dates,
    monthly_precipitation_check,
    cdi_info,
  ]);

 const download_params = {
  product: "cdi",
  year: cdi_selected_dates["year"],
  month: cdi_selected_dates["month"],
  dekad: cdi_selected_dates["dekad"],
 }

  return (
    <div>
      <div className="combined-drought-indicator">
        <p className="Indicator-names">COMBINED DROUGHT INDICATOR (CDI)</p>

        <div className="info-subsection">
          <label className="switch">
            <input
              type="checkbox"
              defaultChecked={cdi_check}
              onChange={oncheck_cdi}
              disabled={false}
            />
            <span className="slider round"></span>
          </label>

          <p className="indicator-description">10-day CDI</p>
          <img className="info-icon" src={info} alt="info" onClick={handleInfoClick} />
          {isDroughtModalVisible && (
  <>
        <DroughtIndicatorModal
        dataset={cdi_info}
        isActive={isDroughtModalVisible}
        toggleModal={handleInfoClick}
        isLoading={isLoadingCdiInfo}
        download={true}
        downloadParams={download_params}
        />

  </>
)}



        </div>

        {cdi_check ? (
          <p
            style={{
              position: "relative",
              color: "#000000",
              fontSize: "10px",
              left: "50px",
              marginTop: "40px",
              marginBottom: "-15px",
            }}
          >
            {cdi_selected_dates["year"]}{" "}
            {cdi_selected_dates["month"] +
              " Dekad : " +
              cdi_selected_dates["dekad"]}{" "}
          </p>
        ) : (
          <></>
        )}

        <div className="cdi-selection">
          {get_select_element(
            customoptions,
            cdi_dates["year"],
            onchange_year_cdi,
            cdi_selected_dates["year"],
            cdi_check
          )}
          {get_select_element(
            customoptions,
            cdi_dates["month"],
            onchange_month_cdi,
            cdi_selected_dates["month"],
            cdi_check
          )}
          {get_select_element(
            customoptions,
            cdi_dates["dekad"],
            onchange_dekad_cdi,
            cdi_selected_dates["dekad"],
            cdi_check
          )}
        </div>

        <Border
          // color={cdi_check ==true ? 'red' : 'black'}
          margin_top={cdi_check ? "3vh" : "1.5vh"}
        />
        <Precipitation
          get_select_element={get_select_element}
          customoptions={customoptions}
          monthly_precipitation_check={monthly_precipitation_check}
          setMonthly_recipitation_check={setMonthly_recipitation_check}
          rs_data={rs_data}
          precipitation_check={precipitation_check}
          setPrecipitation_check={setPrecipitation_check}
          setCurrent_vector={setCurrent_vector}
          setCurrent_spi={setCurrent_spi}
          setSpi_legend_url={setSpi_legend_url}
          months={months}
          selected_spi_dates={selected_spi_dates}
          setSelected_spi_dates={setSelected_spi_dates}
          get_filtered_wms_spi={get_filtered_wms_spi}
          selected_country={selected_country}
          get_years={get_years}
          base_url={base_url}
          setCurrent_monthly_precipitation_gpcc={
            setCurrent_monthly_precipitation_gpcc
          }
          setGpcc_monthly_precipitation_legend_url={
            setGpcc_monthly_precipitation_legend_url
          }
          selected_region={selected_region}
        />
        <Border
          // color={cdi_check ==true ? 'red' : 'black'}
          margin_top={monthly_precipitation_check ? "55px" : "20px"}
        />

        <Vegetation
          get_select_element={get_select_element}
          customoptions={customoptions}
          ndvi_check={ndvi_check}
          setNdvi_check={setNdvi_check}
          rs_data={rs_data}
          get_years={get_years}
          months={months}
          setCurrent_fapar_anomaly={setCurrent_fapar_anomaly}
          fapar_anomaly_check={fapar_anomaly_check}
          setFapar_anomaly_check={setFapar_anomaly_check}
          selected_country={selected_country}
          get_filtered_wms={get_filtered_wms}
          setFapar_anomaly_legend_url={setFapar_anomaly_legend_url}
          fapar_absorbed_check={fapar_absorbed_check}
          setFapar_absorbed_check={setFapar_absorbed_check}
          base_url={base_url}
          setCurrent_fapar_absorbed={setCurrent_fapar_absorbed}
          setFapar_absorbed_legend_url={setFapar_absorbed_legend_url}
          selected_region={selected_region}
          request_str_modis_ndvi={request_str_modis_ndvi}
          setRequest_str_modis_ndvi={setRequest_str_modis_ndvi}
          setCurrent_ndvi={setCurrent_ndvi}
        />

        <Border
          // color={cdi_check ==true ? 'red' : 'black'}
          margin_top={ndvi_check ? "50px" : "10px"}
        />

        <Soil
          get_select_element={get_select_element}
          customoptions={customoptions}
          sma_check={sma_check}
          setSma_check={setSma_check}
          rs_data={rs_data}
          setCurrent_sma={setCurrent_sma}
          selected_region={selected_region}
          selected_country={selected_country}
          setLegend_url_sma={setLegend_url_sma}
        />

        <Border
          // color={cdi_check ==true ? 'red' : 'black'}
          margin_top={sma_check ? "7vh" : "5vh"}
        />

        <GeographicBackground
          get_select_element={get_select_element}
          customoptions={customoptions}
          temp_class_check={temp_class_check}
          setTemp_class_check={setTemp_class_check}
          base_url={base_url}
          koppen_climate_clas_check={koppen_climate_clas_check}
          setKoppen_climate_clas_check={setKoppen_climate_clas_check}
        />

        <Border
          // color={cdi_check ==true ? 'red' : 'black'}
          margin_top={koppen_climate_clas_check ? "6vh" : "4vh"}
        />

        <ProtectedAreas
          get_select_element={get_select_element}
          customoptions={customoptions}
          protetect_areas_check={protetect_areas_check}
          setProtetect_areas_check={setProtetect_areas_check}
          base_url={base_url}
          current_protected_areas={current_protected_areas}
          setCurrent_protected_areas={setCurrent_protected_areas}
        />

        <Border
          // color={cdi_check ==true ? 'red' : 'black'}
          margin_top={protetect_areas_check ? "6vh" : "4vh"}
        />
      </div>
    </div>
  );
};

export default Indicators;
