import ReactECharts from 'echarts-for-react';

const CustomChart = ({ data }) => {
  var barWidth = 10;
  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['Warning', 'Watch', 'Alert', 'No drought'],
        align: 'left',
        bottom: 0 // Position the legend at the bottom of the chart
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%', // Adjust the bottom grid spacing to make room for the legend
        containLabel: true,
        show: false
      },
      xAxis: {
        type: 'category',
        data: data.map(item => item.date),
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          show: true
        }
      },
      yAxis: {
        type: 'value',
        name: '% Whole Region',
        nameLocation: 'middle',
        nameGap: 20,
        nameTextStyle: {
          padding: [0, 0, 20, 0]
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: true
        },
        splitLine: {
          show: true
        }
      
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            pixelRatio: 2 // This can increase the resolution of the downloaded image
          }
        },
        right: 20 // You can adjust the positioning of the toolbox
      },
      series: [
        {
          name: 'Warning',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth, // Adjust the bar width as needed
          data: data.map(item => item['Warning']),
          itemStyle: {
            color: '#FFA600'
          }
        },
        {
          name: 'Watch',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Watch']),
          itemStyle: {
            color: '#fff645'
          }

          
        },
       
        {
          name: 'Alert',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Alert']),
          itemStyle: {
            color: 'red'
          },
        },

        {
          name: 'No drought',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['No drought']),
          itemStyle: {
            color: '#CCCCCC'
          },
        },
      
      ],
      barCategoryGap: '0%' ,// Adjust the category gap as needed (default is 20%)
      barGap: '-100%',
    };
  };
  
    return (<ReactECharts option={getOption()} style={{ height: '300px', width: '100%' }} />);
};

export default CustomChart;
