import { useLocation } from "react-router-dom";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { DataContext } from "../DataContext";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import "../styles/Reportingstyle.css";
import ColoredBar from "./ColoredBar";

import DataTable from "./Table";

import Map from "./map";

import StackedBarChart from "./Chart";

import CustomChart from "./CustomChart";
import SpiChart from "./SpiChart";
import SmaChart from "./SMAChart";
import FaparChart from "./FaparChart";

import { get_data, base_url, geoserver, maplibre_str } from "../Geoportal";
import { months, filter_wms_by_boundary } from "../sidepane/SidePanel";

import { TRACKING_ID } from "../Geoportal";

ReactGA.initialize(TRACKING_ID);

const Reporting = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "reporting",
    });
  }, []);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const year = searchParams.get("year");
  const month = searchParams.get("month");
  const dekad = searchParams.get("dekad");
  const country_id = searchParams.get("adminZeroCode");
  const month_int = months[month];
  const { shareddata, setSharedData } = useContext(DataContext);

  const [chart_data, setChart_data] = useState(null);
  const [category_data, setCategory_data] = useState(null);
  const [cdi_summarry, setCdi_summarry] = useState(null);
  const [spi_summary, setSpi_summary] = useState(null);
  const [sma_sumary, setSma_sumary] = useState(null);
  const [fparan_sumary, setFparan_sumary] = useState(null);
  const [product_metadata, setProduct_metadata] = useState(null);
  const [cdi_analytics, setCdi_analytics] = useState(null);

  useEffect(() => {
    const data = localStorage.getItem("sharedData");
    if (data) {
      const parsedData = JSON.parse(data);

      setSharedData(parsedData);
      // const country_id = parsedData.admin_info.admin_zero_info.country_code;
      const chart_data_from_api = async () => {
        const chart_data_ = await get_data(
          `${base_url}/data_api/geojson/get_reporting_statistics?year=${year}&month=${month_int}&dekad=${dekad}&country_id=${country_id}`
        );
        setChart_data(chart_data_);
        var total_area = chart_data_?.cdi?.total_area_per_per_class;
        var percentage_area = chart_data_?.cdi?.percentage_area;
        if (total_area && percentage_area) {
          const data_crunt = [
            {
              category: "Watch",
              area: total_area.Watch,
              percentage: percentage_area.Watch,
            },
            {
              category: "Warning",
              area: total_area.Warning,
              percentage: percentage_area.Warning,
            },
            {
              category: "Alert",
              area: total_area.Alert,
              percentage: percentage_area.Alert,
            },
          ];
          setCategory_data(data_crunt);
        }
        const product_metadata = await get_data(`${base_url}/api/metadata/`);

        processDatasets(product_metadata)

      };
      chart_data_from_api();
     
    }
  }, []);

  function processDatasets(datasets) {
    let result = {};
    datasets.forEach(dataset => {
        result[dataset.dataset_id] = dataset.overview;
    });
    setProduct_metadata(result)
    return result;
}

  useEffect(() => {
    const data = localStorage.getItem("sharedData");
    if (data) {
      const parsedData = JSON.parse(data);

      setSharedData(parsedData);
      // const country_id = parsedData.admin_info.admin_zero_info.country_code;

      const get_cdi_summary = async () => {
        const cdi_summary_ = await get_data(
          `${base_url}/data_api/geojson/get_reporting_statistics?year=${year}&month=${month_int}&dekad=${dekad}&country_id=${country_id}&data=cdi`
        );
        setCdi_summarry(cdi_summary_["cdi_summary"]);

        const cdi_analytics_ = await get_data(`${base_url}/data_api/geojson/get_reporting_statistics?year=${year}&month=${month_int}&dekad=${dekad}&country_id=${country_id}&data=cdi&mlanalytics=true`);
        setCdi_analytics(cdi_analytics_);

        const spi_summary = await get_data(
          `${base_url}/data_api/geojson/get_reporting_statistics?year=${year}&month=${month_int}&dekad=${dekad}&country_id=${country_id}&data=spi`
        );
        setSpi_summary(spi_summary["spi_03_summary"]);
        const sma_summary_ = await get_data(
          `${base_url}/data_api/geojson/get_reporting_statistics?year=${year}&month=${month_int}&dekad=${dekad}&country_id=${country_id}&data=sma`
        );
        setSma_sumary(sma_summary_["sma"]);
        const fparan_summary_ = await get_data(
          `${base_url}/data_api/geojson/get_reporting_statistics?year=${year}&month=${month_int}&dekad=${dekad}&country_id=${country_id}&data=fapar_anomaly`
        );
        setFparan_sumary(fparan_summary_["fapar_anomaly_summary"]);
      };

      get_cdi_summary();
    }
  }, [chart_data]);
  useEffect(() => {}, [cdi_summarry, spi_summary, sma_sumary, fparan_sumary,]);

  const country_data = shareddata?.admin_info?.admin_zero_info;

  const country_name = country_data?.country_name;
  const country_code = country_data?.country_code;
  const bounds = country_data?.country_bounds;

  const layername_cdi = "africa_drought_datasets:grid_01dd_cdi_";
  const layername_spi = "africa_drought_datasets:grid_1dd_spi_";
  const layername_sma = "africa_drought_datasets:grid_01dd_sma_anomaly_";
  const layername_fparan = "africa_drought_datasets:grid_083dd_fapar_anomaly_";
  const layer_name_admin_zero =
    "africa_drought_datasets:data_api_adminlevelzero";

  const cdi_image_url = `${geoserver}${maplibre_str}${layername_cdi}${month_int}${dekad}_view&CQL_FILTER=year=${year}`;

  const admin_zero_image_url = `${geoserver}${maplibre_str}${layer_name_admin_zero}&CQL_FILTER=country_code=${country_code}`;

  const spi_03_image_url = `${geoserver}${maplibre_str}${layername_spi}${month_int}06_view&CQL_FILTER=year=${year}`;

  const sma_image_url = `${geoserver}${maplibre_str}${layername_sma}${month_int}${dekad}_view&CQL_FILTER=year=${year}`;

  const fparan_image_url = `${geoserver}${maplibre_str}${layername_fparan}${month_int}${dekad}_view&CQL_FILTER=year=${year}`;

  function map_view(image_url, bounding_box, admin_zero_image_url, text, custom=false) {
    if (!custom) {
      text = `${text}:${year}-${month_int}-${dekad}`
    }

    return (
      <div className="columns is-centered is-vcentered">
        <div className="column is-narrow">
          {" "}
          <Map
            image_url={image_url}
            active={true}
            bounds={bounding_box}
            admin_zero_image_url={admin_zero_image_url}
          />
          <p className="is-size-7">
            {text}
          </p>
        </div>
      </div>
    );
  }

  function OverviewBar(title = "Admin Level One Overview", color = "#AB45B8") {
    return (
      <div
        className="columns is-gapless is-vcentered is-mobile"
        style={{
          height: "20px",
          marginTop: "10px",
          marginLeft: "15px",
          marginRight: "15px",
          backgroundColor: "#D9D9D9",
        }}
      >
        <div
          className="column is-narrow"
          style={{ width: "5px", height: "100%", backgroundColor: color }}
        ></div>
        <p
          className="title is-7"
          style={{
            padding: "0",
            margin: "3px",
            marginRight: "20px",
          }}
        >
          {title}
        </p>
      </div>
    );
  }

  const downloadPdfDocument = () => {
    const input = document.body; // or a specific element
  
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
        });
  
        const imgWidth = 208; // A4 width
        const imgHeight = canvas.height * imgWidth / canvas.width;
  
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('download.pdf');
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  };
  
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="reporting">
      {/* Reporting - Parameter: {parameter} */}
      <div
        className="columns is-gapless is-vcentered is-mobile"
        style={{
          height: "10px",
          marginTop: "10px",
          marginLeft: "15px",
          marginRight: "15px",
        }}
      >
        <div
          className="column"
          style={{ height: "100%", backgroundColor: "#FFB107" }}
        ></div>{" "}
        {/* Ensure each column fills the container height */}
        <div
          className="column is-narrow"
          style={{ width: "5px", height: "100%" }}
        ></div>
        <div
          className="column"
          style={{ height: "100%", backgroundColor: "#3563CC" }}
        ></div>
        <div
          className="column is-narrow"
          style={{ width: "5px", height: "100%" }}
        ></div>
        <div
          className="column"
          style={{ height: "100%", backgroundColor: "#AB45B8" }}
        ></div>
      </div>

      <div className="content-container">
        <div className="box">
          <div className="column custom-column has-text-centered">
            <p className="title is-6">
              Drought Situation Overview Report For {country_name}
              <br></br> Reference Date :{year}-{month_int}-{dekad}
            </p>
          </div>
        </div>
        <ColoredBar
          productName={"Combined Drought Index (CDI)"}
          description={product_metadata?.cdi ||
            "The Combined Drought Indicator is based on SPI, Soil moisture and fAPAR, to identify areas with potential to suffer agricultural drought, areas where the vegetation is already affected by drought conditions, and areas in recovery process to normal conditions after a drought episode."
          }
        />
        {map_view(
          cdi_image_url,
          bounds,
          admin_zero_image_url,
          "Map showing drought situation for the period"
        )}
        {OverviewBar("Administation level one overview")}
        {chart_data?.cdi ? (
          <div className="columns"> {/* This div will create a flex container row */}
            <div className="column"> {/* This div will take up the remaining space */}
              <StackedBarChart allData={chart_data["cdi"]} />
            </div>

            {cdi_analytics && (<div className="column is-half"> 
              <p className="title is-size-7 typing-animation">Experimental AI-powered Analytics:</p>
              <div className="box">
      
            <p className="small is-size-7 reporting-analytics">{cdi_analytics?.report}</p>
            </div>
            </div>)}
          </div>
        ) : (
          <p className="is-size-7"> No Significant Observed Drought</p>
        )}



        {/* {map_view(filtered_cdi, bounds)} */}
        {OverviewBar("Area Exposed Km2")}

        {category_data && (
          <div className="">
            <DataTable data={category_data} />
          </div>
        )}

        {cdi_summarry && (
          <div className="column">
            <p className="is-size-7">Ten Day CDI Time series</p>
            <CustomChart data={cdi_summarry} />
          </div>
        )}
      </div>
      <div className="column spi">
        <ColoredBar
          productName={"Standard Precipitation Index (SPI"}
          description={product_metadata?.spi ||
            "SPI is a statistical index comparing the total precipitation received at a particular location during a period of n months with the long-term precipitation distribution for the same period of time at that location (reference period is 1981-2010). Monthly accumulation periods (n) are 1, 3, 6, 9, 12, 24 or 48 months"
          }
        />
        <div className="column ">
          {map_view(
            spi_03_image_url,
            bounds,
            admin_zero_image_url,
            `Map showing  SPI for the period Year ${year}, Month ${month_int}, 6 months Accumulation`,
            true
          )}
        </div>

        {spi_summary && (
          <div className="column">
            <p className="is-size-7">SPI 03 Time series</p>
            <SpiChart data={spi_summary} />
          </div>
        )}
      </div>

      <div className="column sma">
        <ColoredBar
          productName={"Soil Moisture Anomaly (SMA)"}
          description={product_metadata?.sma ||
            "The Soil Moisture Anomaly is used in determining the start and duration of agricultural drought conditions, which arise when soil moisture availability to plants drops to such a level that it adversely affects crop yield, and hence, agricultural production"
          }
        />
        <div className="column">
          {map_view(
            sma_image_url,
            bounds,
            admin_zero_image_url,
            "Map showing  SMA for the period"
          )}
        </div>
        {sma_sumary && (
          <div className="column">
            <p className="is-size-7">SMA Time series</p>
            <SmaChart data={sma_sumary} />
          </div>
        )}
      </div>
        
      <div className="column fapar">
        <ColoredBar
          productName={"fAPAR Anomaly"}
          description={product_metadata?.fparan ||
            "The fAPAR Anomaly is used in determining the start and duration of agricultural drought conditions, which arise when soil moisture availability to plants drops to such a level that it adversely affects crop yield, and hence, agricultural production"
          }
        />
        <div className="column">
          {map_view(
            fparan_image_url,
            bounds,
            admin_zero_image_url,
            "Map showing  fAPAR Anomaly for the period"
          )}
        </div>
        {fparan_sumary && (
          <div className="column">
            <p className="is-size-7">fAPAR  Anomaly Time series</p>
            <FaparChart data={fparan_sumary} />
          </div>
        )}
      </div>

      <button onClick={handlePrint}>Download as PDF</button>
    </div>
  );
};

export default Reporting;
