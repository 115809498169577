import { useEffect , useState} from 'react';
//   import { questions } from './QuestionsFile';
// import { use } from 'echarts';
 
//   const selectedCategoryId = 0 // 




export   function getFilteredQuestions(questions, selectedCategoryId) {
     var questionCategoryStr = null;
    var filtered_q = [];
    questions.map((question) => {
      if(question.hasOwnProperty('questionCategory')) {
        var categoryId = question?.questionCategory?.id
        
       
        if (categoryId=== selectedCategoryId) {
          filtered_q.push(question);
          questionCategoryStr = question?.questionCategory?.text;
        }
        
      }else{
        filtered_q.push(question);
      }
    });

    if (questionCategoryStr !== null){
      return {questions :filtered_q, category:questionCategoryStr}
    }

    return filtered_q;

  }

export function getRandomItem(list) {
    const randomIndex = Math.floor(Math.random() * list.length);
    return list[randomIndex];
  }
  





// export const finalQuestions = getFilteredQuestions(questions, selectedCategoryId);