import React from 'react';
import axios from 'axios';

import { months } from './SidePanel';

import "../styles/DroughtModal.css";
// Function to handle the download

var list_dekads = { "1st": "01", "2nd": "11", "3rd": "21" };
const downloadData = async (product, year, month, dekad) => {
  month = months[month]
  dekad = list_dekads[dekad]
  const downloadUrl = 'https://ada.acmad.org/data_api/download_data/';
  const formattedUrl = `${downloadUrl}?data_name=${product}&year=${year}&month=${month}&dekad=${dekad}`;
  console.log('Download URL:', formattedUrl);


  try {
    const response = await axios.get(formattedUrl, {
      responseType: 'blob', // Important for files
      // auth: {
      //   username: 'usr-drought',
      //   password: 'drought2023'
      // }
    });
    console.log('Download response:', );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


const DroughtIndicatorModal = ({ dataset, isActive, toggleModal, isLoading, error, download, downloadParams }) => {
  const textStyle = { fontSize: '0.85rem' };
  const rowStyles = ['has-background-light', 'has-background-white'];
  const modalStyle = { width: '80%', maxWidth: '800px' };
  const modalBodyStyle = { maxHeight: '70vh', overflowY: 'auto' };
  const spinnerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px' };

  const titleCase = str => str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const dataRows = dataset ? Object.entries(dataset)
    .filter(([key, value]) => value && key !== 'id' && key !== 'dataset_id' && key !== 'data_values')
    .map(([key, value]) => ({ label: titleCase(key), value: value })) : [];

    const handleDownload = async () => {
      if (downloadParams) {
        const { product, year, month, dekad } = downloadParams;
        try {
          const data = await downloadData(product, year, month, dekad);
          const url = window.URL.createObjectURL(new Blob([data], { type: 'image/tiff' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${product}_${year}_${month}_${dekad}.tif`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url); // Free up memory
          
          // Display a success message to the user
          displaySuccessMessage(`Download for ${product}_${year}_${month}_${dekad} initiated. Please check your downloads folder.`);
        } catch (error) {
          console.error('Download error:', error);
          // Optionally, display an error message to the user
          displayErrorMessage('Failed to download file. Please try again later.');
        }
      }
    };
    
 // Function to display a centered success message using Bulma's notification
function displaySuccessMessage(message) {
  const container = document.createElement('div');
  container.className = 'centered-notification-container';

  const messageContainer = document.createElement('div');
  messageContainer.className = 'notification is-success centered-notification';
  messageContainer.innerHTML = `
    <button class="delete"></button>
    ${message}
  `;

  container.appendChild(messageContainer);
  document.body.appendChild(container);

  messageContainer.querySelector('.delete').addEventListener('click', function() {
    container.parentNode.removeChild(container);
  });

  setTimeout(() => {
    if (container.parentNode) {
      container.parentNode.removeChild(container);
    }
  }, 5000); // Adjust time as needed
}

// Function to display a centered error message using Bulma's notification
function displayErrorMessage(message) {
  const container = document.createElement('div');
  container.className = 'centered-notification-container';

  const messageContainer = document.createElement('div');
  messageContainer.className = 'notification is-danger centered-notification';
  messageContainer.innerHTML = `
    <button class="delete"></button>
    ${message}
  `;

  container.appendChild(messageContainer);
  document.body.appendChild(container);

  messageContainer.querySelector('.delete').addEventListener('click', function() {
    container.parentNode.removeChild(container);
  });

  setTimeout(() => {
    if (container.parentNode) {
      container.parentNode.removeChild(container);
    }
  }, 5000); // Adjust time as needed
}

    

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={toggleModal}></div>
      <div className="modal-card" style={modalStyle}>
        <header className="modal-card-head">
          <p className="modal-card-title">{dataset ? dataset.dataset_name : 'Loading...'}</p>
          <button className="delete" aria-label="close" onClick={toggleModal}></button>
        </header>
        <section className="modal-card-body" style={modalBodyStyle}>
          {isLoading ? (
            <div style={spinnerStyle}><div className="spinner"></div></div>
          ) : error ? (
            <div>Error loading data.</div>
          ) : (
            <>
              {dataRows.map((row, index) => (
                <div key={row.label} className={`columns is-mobile ${rowStyles[index % rowStyles.length]}`}>
                  <div className="column is-half has-text-weight-semibold" style={textStyle}>{row.label}</div>
                  <div className="column is-half" style={textStyle}>{row.value}</div>
                </div>
              ))}
              {download && (
                <button className="button is-primary" onClick={handleDownload}>
                  Download Data
                </button>
              )}
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default DroughtIndicatorModal;
