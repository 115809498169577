

import { handleHeaderClick } from "../Header";
import {
  portal_description,
  physical_address,
  postal_address,
  telephone,
  email,
  twitter,
  web,
} from "./utlities";

import {getMonthName} from "../sidepane/Precipitation"
import sideimage from "../../images/sideimage.svg";

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import 'bulma/css/bulma.min.css';
import '../styles/HomeStyle.css';

import DroughtImageNRC from "../../images/DroughtImageNRC.svg";
import Map from "./map";
import SidePanel from "./SidePanel";
import ReactGA from "react-ga4";
import { TRACKING_ID } from "../Geoportal";

ReactGA.initialize(TRACKING_ID);

const Home = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "home",
    });
  }, []);



  const base_url = "https://ada.acmad.org"

  const [cdi_date, setCdiDate] = useState("3rd Ten Days of May 2023");
  const [active, setActive] = useState(false);
  const cdi_image_url = "https://ada.acmad.org/geoserver/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=africa_drought_datasets:grid_01dd_cdi_0521_view&CQL_FILTER=year=2023"; // Truncated for brevity
  const [latest_cdi, setLatestCdi] = useState(null);
  const legend_url = `${base_url}/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=africa_drought_datasets:grid_01dd_cdi_0521_view&LEGEND_OPTIONS=border:true;dx:1;fontSize`; 

  const [cdi_overview_data, setCdi_overview_data] = useState(null);
  const [analytics_data, setAnalyticsData] = useState(null);
function get_num_withzero(num) {
  return num.toString().padStart(2, "0");
}

var dekads_names = { "01": "1st", 11: "2nd", 21: "3rd" };

useEffect(() => {
  const api_url = `${base_url}/data_api/rs_data/get_available_datasets?data_name=cdi`
  fetch(api_url)
    .then(res => res.json())
    .then(data => {
     var latest_cdi_year = data?.cdi?.latest_year;
     var latest_cdi_month = data?.cdi?.latest_month;
     var latest_cdi_dekad = data?.cdi?.latest_dekad;

     latest_cdi_month = get_num_withzero(latest_cdi_month);
      latest_cdi_dekad = get_num_withzero(latest_cdi_dekad);

    var latest_cdi_url = `${base_url}/geoserver/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=africa_drought_datasets:grid_01dd_cdi_${latest_cdi_month}${latest_cdi_dekad}_view&CQL_FILTER=year=${latest_cdi_year}`;
      setLatestCdi(latest_cdi_url);
    var monthname = getMonthName(latest_cdi_month);
    var dekad_name = dekads_names[latest_cdi_dekad];

    var cdi_date_ = `${dekad_name} Dekad of ${monthname} ${latest_cdi_year}`;
      setCdiDate(cdi_date_);

      setActive(true);
      const cdi_continent_overview = `${base_url}/data_api/geojson/get_reporting_statistics/?year=${latest_cdi_year}&month=${latest_cdi_month}&dekad=${latest_cdi_dekad}&data=cdi_overview_africa`
      fetch(cdi_continent_overview)
      .then(res => res.json())
      .then(cdi_data_ => {
        // setCdidata(blob);
        setCdi_overview_data(cdi_data_);
      })

      const analytics_url = `${base_url}/data_api/geojson/get_reporting_statistics/?year=${latest_cdi_year}&month=${latest_cdi_month}&dekad=${latest_cdi_dekad}&data=cdi_overview_africa&mlanalytics=true`
      fetch(analytics_url)
      .then(res => res.json())
      .then(analytics_data_ => {
        setAnalyticsData(analytics_data_);
      })

    })
    .catch(err => {
      console.log(err);
    });

 
   
 
}
, []);

useEffect(() => {
}, [active, latest_cdi]);

  return (
    <div>
      {/* Hero Image and Title */}
      <section className="hero is-medium" style={{ backgroundImage: `url(${DroughtImageNRC})` }}> 
        <div className="hero-body">
          <p className="title home-description">
            Drought Situation in Africa
          </p>
        </div>
      </section>

      {/* Main Content Area */}
      
      <div className="container">
        <div className="columns">
          {/* Map Column */}
          <div className="column is-8-desktop is-12-mobile">
            <div className="map-header">
              <p className="title is-size-6">Overview of Latest Drought Indications : {cdi_date}</p>
            </div>
            <div className="map-container">
            {latest_cdi && <Map latest_cdi={latest_cdi} active={active} />}
            </div>

     {/* Legend Image and Visit Geoportal Button */}
      <div className="legend-and-button">
                <figure className="image">
                  <img src={legend_url} alt="legend" style={{ width: '400px', height: 'auto' }} />
                </figure>
                <Link to="/" className="button is-rounded geoportal-button">
                  Visit Geoportal
                </Link>

              </div>


          </div>

          {/* Side Panel Column */}
          <div className="column is-4-desktop is-12-mobile">
            <SidePanel chartdata={cdi_overview_data}
            />
          </div>
        </div>
      </div>

      <section className="section">
  <div className="container">
    <div className="columns is-vcentered">
      
      {/* Text Column */}
      <div className="column is-half">
        <div className="box custom-text-box">
          <p className="small is-size-7">{portal_description}</p>
        </div>
      </div>

      {/* Image Column */}
      <div className="column is-half">
        <figure className="column image">
          <img src={sideimage} alt="Descriptive Alt Text" />
        </figure>
      </div>

      {analytics_data && (<div className="column is-half ">
      <p className="small is-size-6 header-analytics typing-animation"> Experimental AI-powered Analytics:</p>
      <div className="box analytics-box">
      
          <p className="small is-size-7 ">{analytics_data?.report}</p>
        </div>
        
         </div>)}

    </div>
  </div>
  </section>

      {/* Footer Section */}
     
      <footer className="footer has-background-light">
  <div className="container">
    <div className="columns">

      {/* Contact Information Column */}
      <div className="column is-6-desktop is-full-mobile">
        <p className="title is-7 footer-titles">Africa Centre of Metrological Applications for Development</p>
        <p>Address: {physical_address}</p>
        <p>Tel: {telephone}</p>
        <p>Email: {email}</p>
        <p>X: {twitter}</p>
        <p>Web: {web}</p>
      </div>

      {/* Useful Links Column */}
      <div className="column is-4-desktop is-full-mobile">
        <p className="title is-7 footer-titles">Useful Links</p>
        <div className="links">
          <Link to="/home" className="footer-link">Home</Link>
          <Link to="/" className="footer-link">Geoportal</Link>
          <Link to="#" className="footer-link"
            onClick={() =>
              handleHeaderClick("http://ada.acmad.org:8070/main/indicators/")
            }
          >Indicators</Link>
          <Link to="#" className="footer-link"
            onClick={() =>
              handleHeaderClick("http://ada.acmad.org:8070/main/about/")
            }
          >About</Link>

          <Link to="#" className="footer-link"
           onClick={() =>
            handleHeaderClick("http://ada.acmad.org:8070/main/patners/")
          }
          >Partners
         
          </Link>
        </div>
      </div>

      {/* Feedback Button Column */}
      <div className="column is-2-desktop is-full-mobile has-text-right-desktop">
        <div className="feedback-button">
          <a href={'http://ada.acmad.org:8100/main/get-in-touch/'} className="button is-success is-rounded">
          Give Us Feedback
        </a>
        </div>
      </div>
      
    </div>
  </div>
</footer>

    </div>
  );
};

export default Home;
