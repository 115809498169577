import "./styles/UnavailablePage.css";
const UnavailablePage = () => {
  return (
    <div className="unavailable-page">
      <h1>Site Unavailable</h1>
      <p>We apologize, but the requested page is under construction .</p>
    </div>
  );
};

export default UnavailablePage;
