import "../styles/IndicatorsStyle.css";

import { useEffect, useState } from "react";
import info from "../../images/info.svg";

import { months, filter_wms_by_boundary } from "../sidepane/SidePanel";

import { base_url, geoserver, maplibre_str, get_data } from "../Geoportal";
import DroughtIndicatorModal from "./DroughtIndicatorModal";

import {
  getMonthName,
  handle_wmslink,
  handle_legend_url,
  handle_selected_data,
} from "../sidepane/Precipitation";
import { get_custom_list, get_num_withzero } from "./Indicators";

const dekads = { "01": "1st", 11: "2nd", 21: "3rd" };

const inverse_dekads = { "1st": "01", "2nd": "11", "3rd": "21" };

export function handle_dekad(dekad) {
  return dekads[dekad];
}

// handle year change

export function handle_year_change(year, data, years_list) {
  var months = data[year]["months"];
  var dekads = data[year]["dekads"];
  dekads = dekads.map(get_num_withzero);
  dekads = dekads.map(handle_dekad);
  console.log(dekads);
  months = months.map(Number);
  months = months.map(getMonthName);
  return { year: years_list, month: months, dekad: dekads };
}

const Soil = ({
  get_select_element,
  customoptions,
  sma_check,
  setSma_check,
  rs_data,
  setCurrent_sma,
  selected_region,
  selected_country,
  setLegend_url_sma,
}) => {
  const [sma_dates_data, setSma_dates_data] = useState({
    year: [2023],
    month: ["Jan", "Feb"],
    dekad: ["1st", "2nd", "3rd"],
  });

  const [sma_data_from_api, setSma_data_from_api] = useState(null);

  const [latest_dates, setLatest_dates] = useState(null);

  const [sma_selected_dates, setSma_selected_dates] = useState({
    year: sma_dates_data["year"][0],
    month: sma_dates_data["month"][0],
    dekad: sma_dates_data["dekad"][0],
  });

  

  const layer_name_sma = "africa_drought_datasets:grid_01dd_sma_anomaly_";

  // handle wms link
  function get_wms(month_dekad, year) {
    return `${geoserver}${maplibre_str}${layer_name_sma}${month_dekad}_view&CQL_FILTER=year=${year}`;
  }

  // handle data from the api

  const handle_data_from_api = (data) => {
    var sma_data = data.hasOwnProperty("sma") ? data["sma"] : null;
    if (sma_data) {
      var start_year = sma_data?.start_year;
      var latest_year = sma_data?.latest_year;
      var latest_dekad = sma_data?.latest_dekad;
      var latest_month = sma_data?.latest_month;
      var years_sma = get_custom_list(start_year, latest_year);
      var initial_months = get_custom_list(1, latest_month).map(getMonthName);
      var intial_dekads = get_custom_list(1, latest_dekad, 10).map(handle_dekad);

      setSma_dates_data({
        year: years_sma,
        month: initial_months,
        dekad: intial_dekads,
      });
      var selected_month = getMonthName(latest_month);
        var selected_dekad_ = get_num_withzero(latest_dekad);
       
      var selected_dekad = handle_dekad(selected_dekad_);
      
      setSma_selected_dates({
        year: latest_year,
        month: selected_month,
        dekad: selected_dekad,
      });
      var month_dekad_str = `${months[selected_month]}${inverse_dekads[selected_dekad]}`;
      var sma_wms = get_wms(month_dekad_str, latest_year);
      sma_wms = handle_wmslink(
        sma_wms,
        "cell",
        selected_country,
        selected_region
      );
      var legend_url_sma = handle_legend_url(
        layer_name_sma + month_dekad_str,
        geoserver
      );

      setCurrent_sma({ sma_geoserver: sma_wms });
      setLegend_url_sma(legend_url_sma);
    }
  };

  useEffect(() => {
    if (rs_data !== null) {
      handle_data_from_api(rs_data);
      if (rs_data.hasOwnProperty("sma")) {
        setSma_data_from_api(rs_data["sma"]);
      }
    }
  }, [rs_data]);

  const oncheck_sma = () => {
    setSma_check(!sma_check);
  };
  const onchange_year_sma = (e) => {
    var current_data_avalable = sma_dates_data;
    if (sma_data_from_api !== null) {
      current_data_avalable = handle_year_change(
        e.value,
        sma_data_from_api,
        sma_dates_data["year"]
      );

      setSma_dates_data(current_data_avalable);
    }
    var sma_selected_month = handle_selected_data(
      current_data_avalable["month"],
      sma_selected_dates["month"]
    );
    var sma_selected_dekad = handle_selected_data(
      current_data_avalable["dekad"],
      sma_selected_dates["dekad"]
    );
    var month_dekad_str = `${months[sma_selected_month]}${inverse_dekads[sma_selected_dekad]}`;
    var sma_wms = get_wms(month_dekad_str, e.value);
    sma_wms = handle_wmslink(
      sma_wms,
      "cell",
      selected_country,
      selected_region
    );
    setCurrent_sma({ sma_geoserver: sma_wms });
    setSma_selected_dates({
      year: e.value,
      month: sma_selected_month,
      dekad: sma_selected_dekad,
    });
  };

  const onchange_month_sma = (e) => {
    var selected_year = sma_selected_dates["year"];
    var selected_dekad = sma_selected_dates["dekad"];
    var month_dekad_str = `${months[e.value]}${inverse_dekads[selected_dekad]}`;

    var dekads = sma_dates_data["dekad"];

    if ( sma_data_from_api!== null) {
      // console.log(sma_data_from_api)
      var latest_month = sma_data_from_api?.latest_month
      var month_value = months[e.value]
      var latest_dekad_ = sma_data_from_api?.latest_dekad

      if ( parseInt(month_value, 10) !== latest_month)
      {
        dekads = get_custom_list(1, 21, 10);
        dekads = dekads.map(handle_dekad);
      }else {
        dekads = get_custom_list(1, latest_dekad_, 10);
        dekads = dekads.map(handle_dekad);
      }

      // console.log(latest_month, months[e.value])

       
    }
    setSma_dates_data((prevState)=> ({
      ...prevState,
      dekad: dekads,
    })

    )

    var sma_wms = get_wms(month_dekad_str, selected_year);
    sma_wms = handle_wmslink(
      sma_wms,
      "cell",
      selected_country,
      selected_region
    );
    setCurrent_sma({ sma_geoserver: sma_wms });

    setSma_selected_dates({
      year: sma_selected_dates["year"],
      month: e.value,
      dekad: sma_selected_dates["dekad"],
    });
  };

  const onchange_dekad_sma = (e) => {
    var selected_year = sma_selected_dates["year"];
    var selected_month = sma_selected_dates["month"];

    var month_dekad_str = `${months[selected_month]}${inverse_dekads[e.value]}`;

    var sma_wms = get_wms(month_dekad_str, selected_year);
    sma_wms = handle_wmslink(
      sma_wms,
      "cell",
      selected_country,
      selected_region
    );
    setCurrent_sma({ sma_geoserver: sma_wms });
    setSma_selected_dates({
      year: sma_selected_dates["year"],
      month: sma_selected_dates["month"],
      dekad: e.value,
    });
  };

  const [soilInfo, setSoilInfo] = useState(null);
  const [isDroughtModalVisibleSoil, setIsDroughtModalVisibleSoil] = useState(false)
  const [IsLoading, setIsLoading] =useState(false)

  


  const handleSoilInfo = () => {
    setIsDroughtModalVisibleSoil(!isDroughtModalVisibleSoil);
  
    if (!isDroughtModalVisibleSoil) { 
      setIsLoading(true); 
      const url = `${base_url}/api/metadata/?dataset_id=sma`;
      get_data(url).then((data) => {
        setSoilInfo(data[0]);
        setIsLoading(false);
      }).catch(error => {
        console.error('Failed to fetch CDI info:', error);
        setIsLoading(false); 
      });
    }
  };

  return (
    <div className="soil-module">
      <p className="Indicator-names">SOIL INDICATORS</p>

      <div className="info-subsection">
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={sma_check}
            onChange={oncheck_sma}
          />
          <span className="slider round"></span>
        </label>
        <p className="indicator-description">Soil Mositure Anormaly (SMA)</p>
        <img
          style={{ position: "relative", left: "34%" }}
          src={info}
          alt="info"
          onClick={handleSoilInfo}
        />

    {isDroughtModalVisibleSoil && (
      <>
            <DroughtIndicatorModal
            dataset={soilInfo}
            isActive={isDroughtModalVisibleSoil}
            toggleModal={handleSoilInfo}
            isLoading={IsLoading}
            />

      </>
    )}
            
      </div>

      {sma_check ? (
        <p
          style={{
            position: "relative",
            color: "#000000",
            fontSize: "10px",
            left: "50px",
            marginTop: "40px",
            marginBottom: "-15px",
          }}
        >
          {sma_selected_dates["year"]}{" "}
          {sma_selected_dates["month"] +
            " Dekad : " +
            sma_selected_dates["dekad"]}{" "}
        </p>
      ) : (
        <></>
      )}

      <div className="cdi-selection">
        {get_select_element(
          customoptions,
          sma_dates_data["year"],
          onchange_year_sma,
          sma_selected_dates["year"],
          sma_check
        )}
        {get_select_element(
          customoptions,
          sma_dates_data["month"],
          onchange_month_sma,
          sma_selected_dates["month"],
          sma_check
        )}

        {get_select_element(
          customoptions,
          sma_dates_data["dekad"],
          onchange_dekad_sma,
          sma_selected_dates["dekad"],
          sma_check
        )}
      </div>
    </div>
  );
};

export default Soil;
