// VclURJDhIR5FyUXegLsy ,34.3015

import React, { useRef, useEffect, useState, useCallback } from "react";
import maplibregl from "maplibre-gl";
import { GeocodingControl } from "@maptiler/geocoding-control/react";
import { createMapLibreGlMapController } from "@maptiler/geocoding-control/maplibregl-controller";
import "@maptiler/geocoding-control/style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import BasemapsControl from "maplibre-gl-basemaps";
import "maplibre-gl-basemaps/lib/basemaps.css";
import Draggable from "react-draggable";
import { basemaps } from "./UtilitiesCommon";
import {
  MaplibreExportControl,
  Size,
  PageOrientation,
  Format,
  DPI,
} from "@watergis/maplibre-gl-export";

import "./styles/MapStyle.css";
import TimeSeriesChart from "./ChatTimeSeries";

export default function Map({
  // selected_country,
  // spi_product,
  precipitation_check,
  current_vector,
  current_spi,
  current_fapar_anomaly,
  fapar_anomaly_check,
  current_fapar_absorbed,
  fapar_absorbed_check,
  monthly_precipitation_check,
  current_monthly_precipitation_gpcc,
  current_sma,
  sma_check,
  cdi_check,
  current_cdi,
  cdi_selected_dates,
  current_ndvi,
  ndvi_check,
  koppen_climate_clas_check,
  koppen_climate_url,
  active_admin_boundaries,
  current_protected_areas,
  protetect_areas_check,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const exportControlAdded = useRef(false);
  const [lng] = useState(12.7322); // Easting
  const [lat] = useState(0.4542); // Nothing
  const [zoom] = useState(2.5);
  const [API_KEY] = useState("VclURJDhIR5FyUXegLsy");
  const [Mapbox_Key] = useState(
    "pk.eyJ1Ijoia29yeWtvcmlyMTIzIiwiYSI6ImNsMGdqcjdybTEzcTczanBybHU5anN6bnUifQ.rIUBT4fmSIwMuwN_vtUznw"
  );
  const [currentZoom, setCurrentZoom] = useState(zoom);

  const [pixelDataFetched, setPixelDataFetched] = useState(false);

  const [clicked_latlong, setClicked_latlong] = useState(null);

  const [mapController, setMapController] = useState();

  useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      // style: `https://api.maptiler.com/maps/${current_basemap}/style.json?key=${API_KEY}`,
      style: { version: 8, sources: {}, layers: [] },
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("moveend", () => {
      setCurrentZoom(map.current.getZoom());
    });

    setMapController(createMapLibreGlMapController(map.current, maplibregl));
    map.current.addControl(
      new maplibregl.AttributionControl({ compact: true }),
      "bottom-right"
    );
    map.current.addControl(
      new BasemapsControl({
        basemaps: basemaps,
        initialBasemap: "mapbox_streets",
        expandDirection: "top",
      }),
      "bottom-left"
    );
    map.current.addControl(new maplibregl.NavigationControl(), "bottom-left");
  });

  useEffect(() => {
    if (map.current && !exportControlAdded.current) {
      // Only add the control if it hasn't been added before
      const exportControl = new MaplibreExportControl({
        PageSize: Size.A4,
        PageOrientation: PageOrientation.Landscape,
        Format: Format.PNG,
        DPI: DPI[300],
        Local: "en",
        PrintableArea: true,
        Crosshair: true,
      });
      // map.current.addControl(exportControl, "top-left");
      exportControlAdded.current = true; // Mark the control as added
    }
  }, [map.current]);

  const add_vector = (url, name, bounds, type = "raster") => {
    if (url !== null && map.current) {
      const [minLng, minLat, maxLng, maxLat] = [
        bounds["minx"],
        bounds["miny"],
        bounds["maxx"],
        bounds["maxy"],
      ];

      // Fit the map to the bounds regardless of the active_admin_boundaries status
      map.current.fitBounds([
        [minLng, minLat],
        [maxLng, maxLat],
      ]);

      // Check if a source with the same name already exists
      const sourceExists = map.current.getSource(name);

      if (active_admin_boundaries) {
        if (sourceExists) {
          // If the layer exists, remove it before adding a new one
          map.current.removeLayer(name);
          map.current.removeSource(name);
        }

        // Add new source and layer
        map.current.addSource(name, {
          type: type,
          tiles: [url],
        });

        const layer = {
          id: name,
          type: type,
          source: name,
          tileSize: 256,
        };
        map.current.addLayer(layer);
      } else if (sourceExists) {
        // If active_admin_boundaries is false and the layer exists, remove it
        map.current.removeLayer(name);
        map.current.removeSource(name);
      }
    }
  };

  const add_image_geoserver = useCallback((url, name, state_check = false) => {
    if (state_check == false) {
      if (map.current && map.current.getSource(name)) {
        map.current.removeLayer(name);
        map.current.removeSource(name);
      }
    }

    const layer = {
      id: name,
      type: "raster",
      source: name,
      crs: "EPSG:3857",
      tileSize: 256,
      params: {
        LAYERS: name,
        CRS: "EPSG:3857",
        TILED: true,
      },
    };

    if (map.current && map.current.getSource(name)) {
      map.current.removeLayer(name);
      map.current.removeSource(name);
      if (state_check) {
        map.current.addSource(name, {
          type: "raster",
          tiles: [url],
        });
        map.current.addLayer(layer);
      } else {
        map.current.removeLayer(name);
        map.current.removeSource(name);
      }
    } else {
      map.current.addSource(name, {
        type: "raster",
        tiles: [url],
        crs: "ESPG:4326",
      });
      map.current.addLayer(layer);
      if (state_check) {
        map.current.addSource(name, {
          type: "raster",
          tiles: [url],
        });
        map.current.addLayer(layer);
      } else {
        map.current.removeLayer(name);
        map.current.removeSource(name);
      }
    }
  }, []);
  const dekad_obj = { "1st": "01", "2end": "11", "3rd": "21" };

  useEffect(() => {
    if (map.current) {
      map.current.on("click", (e) => {
        setPixelDataFetched(false);
        var lon_lat = e.lngLat;

        if (!pixelDataFetched) {
          setClicked_latlong({ lat: lon_lat["lat"], lon: lon_lat["lng"] });
        }
        setPixelDataFetched(true);
      });
    }
  }, [cdi_selected_dates]);

  var admn_1 =
    "http://ada.acmad.org:8080/geoserver/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&transparent=true&layers=africa_drought_datasets:data_api_adminlevelone";

  var style = {
    nodata: -99999,
    min: 1,
    max: 3,
    clamp: false,
    dtype: "uint8",
    // scheme: "discrete",
    palette: ["#66b3ff", "#ffffff", "#FF6666"],
  };
  var styleEncoded = encodeURIComponent(JSON.stringify(style));
  var tes_large_image = `http://localhost/api/raster/10/tiles/{z}/{x}/{y}.png?projection=EPSG:3857&style=${styleEncoded}`;

  useEffect(() => {
    setTimeout(function () {
      if (currentZoom > 5.2) {
        add_image_geoserver(admn_1, "admn_1", false);
      } else {
        add_image_geoserver(admn_1, "admn_1", false);
      }
    }, 10);
  }, [currentZoom]);

  useEffect(() => {
    const get_wms = async () => {
      setTimeout(function () {
        add_image_geoserver(tes_large_image, "mtest", true);
        if (current_protected_areas !== null) {
          add_image_geoserver(
            current_protected_areas,
            "protected_areas",
            protetect_areas_check
          );
          console.log("current_protected_areas", current_protected_areas);
        }
        // spi
        if (current_spi !== null) {
          add_image_geoserver(current_spi, "spi", precipitation_check);
        }

        // cdi
        if (current_cdi !== null) {
          add_image_geoserver(current_cdi["cdi_geoserver"], "cdi", cdi_check);
        }

        // fapar_anormaly

        if (current_fapar_anomaly !== null) {
          add_image_geoserver(
            current_fapar_anomaly["fapar_anomaly_geoserver"],
            "faparAnom",
            fapar_anomaly_check
          );
        }

        // fapar_absorbed
        if (current_fapar_absorbed !== null) {
          // console.log()
          add_image_geoserver(
            current_fapar_absorbed["fapar_absorbed_geoserver"],
            "faparAbsorbed",
            fapar_absorbed_check
          );
        }
        // monthly_precipitation_gpcc
        if (current_monthly_precipitation_gpcc !== null) {
          add_image_geoserver(
            current_monthly_precipitation_gpcc[
              "monthly_precipitation_gpcc_geoserver"
            ],
            "monthlyPrecipitation",
            monthly_precipitation_check
          );
        }
        // sma

        if (current_sma !== null) {
          add_image_geoserver(current_sma["sma_geoserver"], "sma", sma_check);
        }
        // modis_ndvi

        if (current_ndvi !== null) {
          add_image_geoserver(current_ndvi, "ndvi", ndvi_check);
        }

        // koppen_climate
        if (koppen_climate_url !== null) {
          add_image_geoserver(
            koppen_climate_url?.geoserver,
            "koppen_climate",
            koppen_climate_clas_check
          );
        }
      }, 0);
    };

    get_wms().then(() => {
      setTimeout(function () {
        if (current_vector !== null) {
          var vector_url = current_vector["url"];
          var bounds = current_vector["bounds"];
          // add_image_geoserver(test_clip, "spi_ke", true);
          add_vector(vector_url, "current_vector", bounds);
          // }
        }
      }, 100);
    });
  }, [
    // selected_country,
    current_spi,
    precipitation_check,
    current_vector,
    current_fapar_anomaly,
    fapar_anomaly_check,
    fapar_absorbed_check,
    current_fapar_absorbed,
    monthly_precipitation_check,
    current_monthly_precipitation_gpcc,
    current_sma,
    sma_check,
    cdi_check,
    current_cdi,
    current_ndvi,
    ndvi_check,
    koppen_climate_clas_check,
    protetect_areas_check,
    current_protected_areas,
    // current_vector,
    // active_admin_boundaries,
  ]);

  useEffect(() => {
    setTimeout(function () {
      // if (active_admin_boundaries){
      if (current_vector !== null) {
        var vector_url = current_vector["url"];
        var bounds = current_vector["bounds"];
        // add_image_geoserver(test_clip, "spi_ke", true);
        add_vector(vector_url, "current_vector", bounds);
        // }
      }
      // console.log("active_admin_boundaries", active_admin_boundaries);
    }, 100);
  }, [active_admin_boundaries, current_vector]);

  return (
    <div className="map-wrap">
      <div className="geocoding">
        <GeocodingControl apiKey={API_KEY} mapController={mapController} />
      </div>
      <div ref={mapContainer} className="map" />
      {pixelDataFetched && (
        <Draggable>
          <div className="map-chart" id="draggableChart">
            <TimeSeriesChart
              dates={cdi_selected_dates}
              clicked_latlong={clicked_latlong}
            />
          </div>
        </Draggable>
      )}
    </div>
  );
}
