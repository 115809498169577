import React, { useEffect, useRef, useState } from "react";

// import { Link } from "react-router-dom";
import { useContext } from "react";
import { get_data, base_url } from "./Geoportal";
import { DataContext } from "./DataContext";
import RainfallChartWithYearAndPattern from "./RainfallChartWithYearAndPattern";
import SPIChartComponentWithMonths from "./SPIChartComponentWithMonths";
import MonthlyBarChart from "./MonthlyBarChart";

import MixedChart from "./MixedChart";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";

import {prepareGpccChartData} from "./UtilitiesCommon";

import { months } from "./sidepane/SidePanel";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarController,
  Title,
  Tooltip,
  Legend
);
// ChartJS.register(ChartJS.controllers.bar);
export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: true,
  plugins: {
    title: {
      display: true,
      text: "Pixel time series",
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const dekad_obj = { "1st": "01", "2nd": "11", "3rd": "21" };
const TimeSeriesChart = ({ dates, clicked_latlong }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Destroy the previous chart instance when the component unmounts
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  const [chartData, setChartData] = useState(null);
  const [chartDataset_from_api, setChartDatafrom_api] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const { shareddata, setSharedData } = useContext(DataContext);
  const [show_generate_report, setShow_generate_report] = useState(false);
  const [admin_details, setAdmin_details] = useState({});
  function generateYearRange(years, subtractValue, currentYear = 2023) {
    // Convert the strings to numbers
    const numbers = years.map((year) => parseInt(year));
    var maxNumber = Math.max(...numbers);
    maxNumber = currentYear;

    // Subtract the specified value from the maximum number to get the new minimum year
    const newMinNumber = currentYear - subtractValue;

    // Create a list of strings representing the years within the specified range
    const result = [];

    for (let i = newMinNumber; i <= maxNumber; i++) {
      result.push(i.toString());
    }

    return result;
  }

  function extractDate(str) {
    // Find the position of underscore in the string
    const underscoreIndex = str.indexOf("_");

    // Handle case where underscore is not present or there aren't enough digits after it
    if (underscoreIndex === -1 || underscoreIndex >= str.length - 4) {
      throw new Error("Invalid format");
    }

    // Extract the substring after underscore
    const dateSubstring = str.substring(underscoreIndex + 1);

    // Validate the extracted substring
    if (!/^\d{4}$/.test(dateSubstring)) {
      throw new Error("Invalid date number");
    }

    // Extract and pad the month and day with leading zeros
    const month = dateSubstring.substring(0, 2);
    const day = dateSubstring.substring(2, 4);

    // Return the month and day as strings
    return { month, day };
  }

  useEffect(() => {
    // console.log(clicked_latlong, "clicked_latlong");
    if (clicked_latlong !== null) {
      var lon_lat = clicked_latlong;
      setShowChart(true);
      const inspect_url = `${base_url}/data_api/geojson/getcellvalues?lat=${
        lon_lat["lat"]
      }&lon=${lon_lat["lon"]}&year=${dates["year"]}&month=${parseInt(
        months[dates["month"]]
      )}&dekad=${dekad_obj[dates["dekad"]]}`;

      async function get_data_() {
        const data = await get_data(inspect_url);
        // console.log(data, "data");

        setSharedData(data);
        localStorage.setItem("sharedData", JSON.stringify(data));
        return data;
      }
      get_data_().then((data) => {
        if (data) {
          setChartDatafrom_api(data);
        } else {
          console.error("Data fetched is undefined or null");
        }
      });
    }
  }, [clicked_latlong, dates]);

  useEffect(() => {
    if (chartDataset_from_api !== null && dates !== null) {
      if (chartDataset_from_api.hasOwnProperty("cell_values")) {
        if (Object.keys(chartDataset_from_api["cell_values"]).length !== 0) {
          var cdi_data = chartDataset_from_api["cell_values"]["cdi_data"];

          var spi_data = chartDataset_from_api["cell_values"]["spi_data"];
          var fapar_anomaly_data =
            chartDataset_from_api["cell_values"]["fapar_anomaly_data"];
          var sma_data = chartDataset_from_api["cell_values"]["sma_data"];

          

          // var gpcc_data_formated = prepareGpccChartData(gpcc_data);
          // console.log(gpcc_data_formated);

          function getLabel_list() {
            if (cdi_data != null && cdi_data !== undefined) {
              var data_dates = [];
              var list_years = Object.keys(cdi_data);
              list_years.map((year) => {
                var data_point = cdi_data[year];
                Object.keys(data_point).map((item) => {
                  var date_str = extractDate(item);
                  data_dates.push(
                    `${year}-${date_str["month"]}-${date_str["day"]}`
                  );
                });
              });
              return data_dates;
            } else {
              return [];
            }
            // console.log(cdi_data);
          }
          const list_ = getLabel_list();

          const getFormattedDataSpi = (
            data,
            label,
            keySuffix,
            borderColor,
            backgroundColor,
            type = "line"
          ) => {
            if (data !== null && data !== undefined) {
              var datasets_ = [];

              Object.keys(data).forEach((year) => {
                Object.keys(data[year]).forEach((key) => {
                  // console.log(year);
                  if (key.endsWith(keySuffix)) {
                    datasets_.push(data[year][key]);
                  }
                });
              });

              var order = 0;
              if (type === "bar") {
                order = 1;
              }

              return {
                label: label,
                data: datasets_,
                fill: false,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                pointHoverRadius: 20,
                pointHoverBorderWidth: 5,
                order: order,
                type: type,
              };
            } else {
              // Handle the case where data is null or undefined
              console.error("Data is null or undefined");
              return null;
            }
          };

          const get_formted_dataget_form = (
            data,
            label,
            borderColor,
            backgroundColor,
            type = "line"
          ) => {
            if (data !== null && data !== undefined) {
              var datasets_ = [];
              Object.keys(data).map((year) => {
                Object.values(data[year]).map((val) => {
                  datasets_.push(val);
                  if (val >14){
                    console.log(val);
                  }

                });
              });

              var order = 0;
              if (type == "bar") {
                order = 1;
              }

              var formated_data = {
                label: label,
                data: datasets_,
                fill: false,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                pointHoverRadius: 20,
                pointHoverBorderWidth: 5,

                order: order,
                type: type,
              };

              if (!datasets_ || datasets_.length === 0) {
                // Check if datasets_ is null, undefined, or empty
                console.error("Invalid datasets_:", datasets_);
                return null; // or return a default object or however you wish to handle this
              }
              return formated_data;
            }
          };

          const cdi_formated = get_formted_dataget_form(
            cdi_data,
            "cdi",
            "rgb(255,166,0)",
            "rgba(255,166,0, 1)",
            "bar"
          );
          const sma_fromated = get_formted_dataget_form(
            sma_data,
            "sma",
            "rgba(179,88,7)",
            "rgba(178,88,7 ,1)",
            "line"
          );

          const fpar_formated = get_formted_dataget_form(
            fapar_anomaly_data,
            "fapar anormaly",
            "rgb(174,237,81)",
            "rgb(174,237,81,1)",
            "line"
          );
          const spi_formated = getFormattedDataSpi(
            spi_data,
            "spi_01",
            "01",
            "red",
            "blue",
            "line"
          );

          // console.log(spi_formated);
          // console.log(fpar_formated);

          if (
            !cdi_formated ||
            !sma_fromated ||
            !fpar_formated ||
            !spi_formated /*...other checks...*/
          ) {
            console.error("One of the formatted datasets is null or undefined");
            return; // Exit from the useEffect if the data is not valid
          }
          setChartData({
            labels: list_,
            datasets: [
              //cdi
              cdi_formated,
              // // sma
              sma_fromated,
              // // fapar anaomaly
              fpar_formated,
              //spi 1
              // spi_formated,
            ],
          });
        }
      }
    } else {
      console.error(
        "datasets or dates is null or does not have the expected structure"
      );
    }
  }, [chartDataset_from_api, dates, clicked_latlong]);

  useEffect(() => {
    if (chartDataset_from_api !== null) {
      if (chartDataset_from_api.admin_info) {
        var country_info = chartDataset_from_api?.admin_info?.admin_zero_info;
        var admin_one_info = chartDataset_from_api?.admin_info?.admin_one_info;
        var county_name = country_info?.country_name;
        var country_code = country_info?.country_code;

        var admin_one_name = admin_one_info?.admin_one_name;
        setShow_generate_report(true);
        setAdmin_details({ country: county_name, region: admin_one_name ,country_code:country_code});
      } else {
        setShow_generate_report(false);
      }
    }
  }, [chartDataset_from_api]);

  return (
    <>
        { showChart && chartData && show_generate_report && (
            <div className="columns is-centered">
              <div className="column is-half">
                {show_generate_report && (
                  <div>
                    <p className="admin-text">
                      Country: {admin_details.country} Region:{" "}
                      {admin_details.region}
                    </p>
                    <a
                      href={`/reporting?year=${dates["year"]}&month=${
                        dates["month"]
                      }&dekad=${dekad_obj[dates["dekad"]]}&adminZeroCode=${admin_details?.country_code}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button is-success is-extra-small"
                      style={{ marginBottom: "2px" }}
                    >
                      Generate Report
                    </a>{" "}
                  </div>
                )}
              </div>
            </div>
          )}
   {show_generate_report && showChart && chartDataset_from_api &&(<div className="charts-wrapper">
   <div className="line-graph">
          <div className="close-button" onClick={() => setShowChart(false)}>
            X
          </div ></div>
     {showChart && chartDataset_from_api?.cell_values?.gpcc_data && (
          <div className="chart-container mb-0"> 
         
          <RainfallChartWithYearAndPattern
            chartDataset_from_api={chartDataset_from_api}
          />
        </div>
      )}
      {showChart && chartDataset_from_api?.cell_values?.gpcc_avg_data && (
        <div className="chart-container mb-0">
          <MonthlyBarChart chartDataset_from_api={chartDataset_from_api} />
        </div>
      )}
      {showChart && chartDataset_from_api?.cell_values?.spi_data && (
        <div className="chart-container">
          <SPIChartComponentWithMonths chartDataset_from_api={chartDataset_from_api} />
        </div>
      )}
      {show_generate_report && showChart && chartData && (
        <div className="line-graph">
          <div className="close-button" onClick={() => setShowChart(false)}>
            X
          </div >
          {chartData && showChart && (
            <div className="chart-container">
              <MixedChart ref={chartRef} data={chartData} />
            </div>
          )}
          
        </div>
        
      )}

     
    </div>)}

    </>
  );
};

export default TimeSeriesChart;
