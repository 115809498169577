

import ReactECharts from 'echarts-for-react';

const FaparChart = ({ data }) => {
  data = data.sort((a, b) => new Date(a.date) - new Date(b.date))
  var barWidth = 14;
  const getOption = () => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['Extremely low', 'Severely low', 'Moderately low', 'Near Normal Conditions', 'Moderately high', 'Very high', 'Extremely high'],
        align: 'left',
        bottom: 0 // Position the legend at the bottom of the chart
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%', // Adjust the bottom grid spacing to make room for the legend
        containLabel: true,
        show: false
      },
      xAxis: {
        type: 'category',
        data: data.map(item => item.date),
        axisTick: {
          alignWithLabel: true
        },
        axisLine: {
          show: true
        },
        axisLabel: {
          show: true
        }
      },
      yAxis: {
        type: 'value',
        name: '% Whole Region',
        nameLocation: 'middle',
        nameGap: 25,
        nameTextStyle: {
          padding: [0, 0, 20, 0]
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: true
        },
        splitLine: {
          show: true
        }
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            title: 'Download',
            pixelRatio: 2 // This can increase the resolution of the downloaded image
          }
        },
        right: 20 // You can adjust the positioning of the toolbox
      },
      series: [
        {
          name: 'Extremely low',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth, // Adjust the bar width as needed
          data: data.map(item => item['Extremely low']),
          itemStyle: {
            color: '#D73062'
          }
        },
        {
          name: 'Severely low',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Severely low']),
          itemStyle: {
            color: '#FC8D95'
          }

          
        },
       
        {
          name: 'Moderately low',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Moderately low']),
          itemStyle: {
            color: '#FEE0C7'
          },
        },

        {
          name: 'Near Normal Conditions',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Near Normal Conditions']),
          itemStyle: {
            color: '#CCCCCC'
          },
        },

        {
          name: 'Moderately high',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Moderately high']),
          itemStyle: {
            color: '#AFEE51'
          },
        },
        {
          name: 'Very high',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Very high']),
          itemStyle: {
            color: '#4CA602'
          },
        },

        {
          name: 'Extremely wet',
          type: 'bar',
          stack: 'total',
          barWidth: barWidth,
          data: data.map(item => item['Extremely high']),
          itemStyle: {
            color: '#126E00'
          },
        },
      ],
      barCategoryGap: '0%' ,// Adjust the category gap as needed (default is 20%)
      barGap: '-100%',
    };
  };

  return <ReactECharts option={getOption()} style={{ height: '300px', width: '100%' }} />;
};


export default FaparChart;
