import "./styles/RightBarStyle.css";

import { useState } from "react";

const RightBar = ({
  spi_legend_url,
  Legend,
  precipitation_check,
  fapar_anomaly_check,
  fapar_anomaly_legend_url,
  fapar_absorbed_check,
  fapar_absorbed_legend_url,
  monthly_precipitation_check,
  gpcc_monthly_precipitation_legend_url,
  sma_check,
  legend_url_sma,
  cdi_check,
  ndvi_check,
  koppen_climate_clas_check,
  koppen_climate_url,
}) => {
  const [active_tab, setActive_tab] = useState("Legend");

  const getContent = () => {
    switch (active_tab) {
      case "Legend":
        return (
          <Legend
            spi_legend_url={spi_legend_url}
            precipitation_check={precipitation_check}
            fapar_anomaly_check={fapar_anomaly_check}
            fapar_anomaly_legend_url={fapar_anomaly_legend_url}
            fapar_absorbed_check={fapar_absorbed_check}
            fapar_absorbed_legend_url={fapar_absorbed_legend_url}
            monthly_precipitation_check={monthly_precipitation_check}
            gpcc_monthly_precipitation_legend_url={
              gpcc_monthly_precipitation_legend_url
            }
            sma_check={sma_check}
            legend_url_sma={legend_url_sma}
            cdi_check={cdi_check}
            ndvi_check={ndvi_check}
            koppen_climate_clas_check={koppen_climate_clas_check}
            koppen_climate_url={koppen_climate_url}
          />
        );
      case "Analysis":
        return <p> Click on the map</p>;
      default:
        return <p>No tab selected.</p>;
    }
  };

  return (
    <div className="RightBarContainer">
      <div id="tabContainer">
        <div
          onClick={() => setActive_tab("Legend")}
          style={{
            cursor: "pointer",
            backgroundColor: active_tab === "Legend" ? "lightgray" : "",
          }}
        >
          LEGEND
        </div>
        <div
          onClick={() => setActive_tab("Analysis")}
          style={{
            cursor: "pointer",
            backgroundColor: active_tab === "Analysis" ? "lightgray" : "",
          }}
        >
          ANALYSIS
        </div>
      </div>
      <div id="contentContainer">{getContent()}</div>
    </div>
  );
};

export default RightBar;
