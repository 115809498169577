import React from 'react';
import ReactECharts from 'echarts-for-react';
export const barWidth = 15;

const MonthlyBarChart = ({chartDataset_from_api}) => {
  // Data preparation
//   const dataset = {
//     "Apr": 161.79111035664877,
//     "Aug": 49.01999987765319,
//     "Dec": 186.31277677747937,
//     "Feb": 147.52889124552408,
//     "Jan": 128.75499979654947,
//     "Jul": 15.043333301113712,
//     "Jun": 19.40944461690055,
//     "Mar": 139.03000089857312,
//     "May": 106.37555588616266,
//     "Nov": 259.06444295247394,
//     "Oct": 190.44166734483508,
//     "Sept": 142.4883334901598
//   };
  var dataset = chartDataset_from_api?.cell_values?.gpcc_avg_data;
  const months = Object.keys(dataset);
  const dataValues = months.map(month => dataset[month]);

  // ECharts option
  const option = {
    grid: {
        left: '10%', // Adjust these values as needed
        right: '10%',
        top: '20%',
        bottom: '20%',
      },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
        data: ['Average Rainfall (mm)'],
        bottom: '0', 
      },

    xAxis: {
      type: 'category',
      data: months
    },
    yAxis: {
      type: 'value',
      name: 'Avg Rainfall (mm)',
    },
    series: [{
      data: dataValues,
      barWidth: barWidth,
      type: 'bar'
    }]
  };

  return (dataset &&<ReactECharts option={option} style={{ height: '180px' ,width:'100%', marginBottom:'1px'}} />);
};

export default MonthlyBarChart;
