import  { useState } from 'react';

const ColoredBar = ({ productName, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = 300; // Maximum number of characters to display before truncation

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    if (description.length <= MAX_LENGTH || isExpanded) {
      return description;
    }
    return `${description.substring(0, MAX_LENGTH)}...`;
  };

  return (
    <div className="columns is-gapless is-vcentered is-mobile colored-bar">
      <div
        className="column is-narrow decorator"
        style={{ height: "100%", backgroundColor: "#FFB107", width: "5px" }}
      ></div>
      <div className="column is-narrow" style={{ whiteSpace: "nowrap" }}>
        <p
          className="title is-7"
          style={{
            padding: "0",
            margin: "3px",
            marginBlockEnd: "10px",
            marginRight: "20px",
          }}
        >
          {productName}
        </p>
      </div>
      <div
        className="column is-narrow decorator"
        style={{ height: "100%", backgroundColor: "#3563CC", width: "2px" }}
      ></div>
      <div className="column" style={{ padding: "0", margin: "3px" }}>
        <p className="is-size-7">
          {renderDescription()}
          {description.length > MAX_LENGTH && (
            <button
              onClick={handleToggleExpand}
              className="button is-text is-small"
              style={{ marginLeft: '10px', padding: '0', border: 'none', background: 'none', textDecoration: 'underline', fontSize: '0.75rem' }}
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </button>
          )}
        </p>
      </div>
    </div>
  );
};

export default ColoredBar;
